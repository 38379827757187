import * as React from 'react';

import { Col, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import { StyledButton, StyledDiv } from '../../constants/styledComponents';
import { forEach, groupBy, remove } from 'lodash';

import AudioPlayer from './audioPlayer';
import FontAwesome from 'react-fontawesome';
import PropTypes from 'prop-types';
import constants from '../../constants/constants';
import icon_hl_teal from '../../images/icon_hl_teal.png';
import icon_pen from '../../images/icon_pen.png';
import { viewerModes } from '../../constants/viewerModes';
import { viewerDrawerTypeEnum } from '../../models-enums';
class Drawer extends React.Component {
    static defaultProps = {
        showNotesButton: true,
        showHighlightsButton: true
    };
    constructor(props) {
        super(props);

        this.shorten = this.shorten.bind(this);
        this.getNotesHtml = this.getNotesHtml.bind(this);
        this.getHLHtml = this.getHLHtml.bind(this);
        this.parseSerializedHighlight = this.parseSerializedHighlight.bind(
            this
        );
        this.theme = constants.themeProvider.activeTheme;
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.book.notes !== prevProps.book.notes ||
            this.props.book.highlights !== prevProps.book.highlights
        ) {
            this.forceUpdate();
        }
    }

    shorten(text) {
        if (!text) {
            return '';
        }
        let ret = text;
        let maxLength = 95;
        if (ret.length > maxLength) {
            ret = ret.substr(0, maxLength - 3) + '...';
        }
        return ret;
    }

    getNotesHtml(bookItems) {
        let borderForNote = 'drawer-item';
        // group the book items by their Page attribute
        const bis = groupBy(bookItems, 'Page');
        // build jsx array to return for print out
        let buffer = [];
        forEach(bis, (biArr, key) => {
            buffer.push(<p key={key}>Page {key}</p>);
            buffer = buffer.concat(
                biArr.map((bi) => {
                    if (bi.Type === 4) {
                        borderForNote = 'drawer-item teacher-student-note';
                        // popoverNote = 'teacher-student-popover-bookItems';
                    } else if (bi.Type === 5) {
                        borderForNote = 'drawer-item class-note';
                        // popoverNote = 'teacher-class-popover-bis';
                    } else if (bi.Type === 6) {
                        borderForNote = 'drawer-item group-note';
                        // popoverNote = 'teacher-group-popover-bis';
                    } else if (bi.Type === 3) {
                        borderForNote = 'drawer-item student-note';
                        // popoverNote = 'student-popover-note';
                    }
                    return (
                        <ListGroupItem
                            className={borderForNote}
                            key={bi.ID}
                            onClick={() => {
                                this.props.goTo(bi);
                            }}
                        >
                            <p>{this.shorten(bi.Content.value)}</p>
                        </ListGroupItem>
                    );
                })
            );
        });
        return buffer;
    }

    getHLHtml(bookItem) {
        var bookItems = this.parseSerializedHighlight(bookItem.Content);
        // group the hl items by their page attribute
        const bis = groupBy(bookItems, 'page');
        // build jsx array to return for print out
        let buffer = [];
        forEach(bis, (biArr, key) => {
            buffer.push(<p key={key}>Page {key}</p>);
            buffer = buffer.concat(
                biArr.map((bi) => {
                    return (
                        <ListGroupItem
                            className={`drawer-item ${bi.hlClass}`}
                            key={bi.ID}
                            onClick={() => {
                                this.props.goToPage(bi.Page);
                            }}
                        >
                            <p>{this.shorten(bi.hlText)}</p>
                        </ListGroupItem>
                    );
                })
            );
        });
        return buffer;
    }

    parseSerializedHighlight(serializedPages) {
        /*
      type:TextRange|165$192$3$hl-strikethrough$page6$t cover by First Avenue Edi
    */
        let hls = [];
        forEach(serializedPages, (serialized) => {
            var serializedHighlights = serialized.split('|');
            // remove the first node: type:TextRange
            serializedHighlights.shift();
            // console.log(serializedHighlights);
            // loop over the rest and extraxt the page, style and text
            hls = hls.concat(
                serializedHighlights.map((sh) => {
                    const parts = sh.split('$');
                    if (parts.length < 6) {
                        return null;
                    }
                    // console.log(parts);
                    var id = (Math.random() * 10000 + '').replace('.', '');
                    var page = parseInt(parts[4].substr(4), 10);
                    var hlClass = parts[3];
                    var hlText = parts[5];
                    return { id, page, hlClass, hlText };
                })
            );
        });
        return remove(hls, null);
    }

    render() {
        if (this.props.hideDrawer) {
            return null;
        }
        let drawerClassName,
            drawerButtonsContainerClassName,
            drawerButtonsClassName,
            drawerButtonCSS;
        if (this.props.show) {
            drawerClassName = 'drawer shown';
            drawerButtonsClassName = 'item icon-btn shown';
            drawerButtonsContainerClassName = 'drawer-buttons-container shown';
        } else {
            drawerButtonsClassName = 'item icon-btn btn-arrow-right';
            drawerClassName = 'drawer';
            drawerButtonsContainerClassName = 'drawer-buttons-container';
            drawerButtonCSS = {
                '&:hover:after': {
                    'border-left': `15px solid ${this.theme.primaryBackground}`
                },
                '&:active:after': {
                    'border-left': `15px solid ${this.theme.primaryDark}`
                },
                '&:after': {
                    'border-left': `15px solid ${this.theme.primaryBackground}`
                }
            };
        }

        const title = viewerDrawerTypeEnum[this.props.type];

        return (
            <div>
                <div className="audio-tools">
                    <AudioPlayer blmMode={this.props.blmMode} />
                </div>
                <StyledDiv
                    className={drawerClassName}
                    style={{ height: window.innerHeight - 80 }}
                    css={{ 'border-right-color': this.theme.primaryBackground }}
                >
                    <div className={drawerButtonsContainerClassName}>
                        <div>
                            <div className={`iconTools item`}>
                                <StyledButton
                                    css={{
                                        ...this.theme.primaryButtonStyle,
                                        ...drawerButtonCSS,
                                        display: this.props.showNotesButton
                                            ? 'block'
                                            : 'none'
                                    }}
                                    className={drawerButtonsClassName}
                                    onClick={() => {
                                        this.props.openDrawer(0);
                                    }}
                                    role="button"
                                >
                                    <img alt="" src={icon_pen} />
                                </StyledButton>
                            </div>
                            <div className={`iconTools item`}>
                                <StyledButton
                                    css={{
                                        ...drawerButtonCSS,
                                        ...this.theme.primaryButtonStyle,
                                        display: this.props.showHighlightsButton
                                            ? ''
                                            : 'none'
                                    }}
                                    className={drawerButtonsClassName}
                                    onClick={() => {
                                        this.props.openDrawer(1);
                                    }}
                                    role="button"
                                >
                                    <img alt="" src={icon_hl_teal} />
                                </StyledButton>
                            </div>
                        </div>
                    </div>
                    <Row>
                        <Col xs={6}>
                            <h1>{title}</h1>
                        </Col>
                        <Col xs={6}>
                            <StyledButton
                                css={this.theme.primaryButtonStyle}
                                className="pull-right"
                                onClick={this.props.close}
                            >
                                <FontAwesome name="close" />
                            </StyledButton>
                        </Col>
                    </Row>
                    <div style={{ overflow: 'hidden' }}>
                        <div
                            className="drawer-list"
                            style={{ height: window.innerHeight - 80 - 52 }}
                        >
                            {this.props.type === 0 &&
                                !!this.props.book &&
                                !!this.props.book.notes && (
                                    <ListGroup className="">
                                        {this.getNotesHtml(
                                            this.props.book.notes
                                        )}
                                    </ListGroup>
                                )}
                            {this.props.type === 1 &&
                                !!this.props.book &&
                                !!this.props.book.highlights && (
                                    <ListGroup className="">
                                        {this.getHLHtml(
                                            this.props.book.highlights
                                        )}
                                    </ListGroup>
                                )}
                            {(this.props.location.query.viewerMode ===
                                viewerModes.MODE_TEACHER_VIEW_STUDENT_BOOK ||
                                this.props.location.query.viewerMode ===
                                    viewerModes.MODE_TEACHER_STUDENT_BLM) && (
                                <div>
                                    {this.props.type === 0 &&
                                        !!this.props.book &&
                                        !!this.props.book.studentNotes &&
                                        !!this.props.book.studentNotes
                                            .length && (
                                            <ListGroup className="">
                                                {this.getNotesHtml(
                                                    this.props.book.studentNotes
                                                )}
                                            </ListGroup>
                                        )}
                                    {this.props.type === 1 &&
                                        !!this.props.book &&
                                        !!this.props.book.studentHighlights && (
                                            <ListGroup className="">
                                                {this.getHLHtml(
                                                    this.props.book
                                                        .studentHighlights
                                                )}
                                            </ListGroup>
                                        )}
                                </div>
                            )}
                        </div>
                    </div>
                </StyledDiv>
            </div>
        );
    }
}

Drawer.propTypes = {
    show: PropTypes.bool.isRequired,
    user: PropTypes.object.isRequired,
    book: PropTypes.object.isRequired,
    goTo: PropTypes.func.isRequired,
    goToPage: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    type: PropTypes.number.isRequired,
    hideDrawer: PropTypes.bool.isRequired,
    showNotesButton: PropTypes.bool,
    showHighlightsButton: PropTypes.bool
};

export default Drawer;
