import React, { Component } from 'react';
import { manualAjaxEnd, manualAjaxStart } from './actions/ajaxStatusActions';

import Loading from './components/common/Loading';
import {
    checkSession,
    confirmChangeSchoolID,
    userLogout,
    userLogoutSessionOnly
} from './actions/userActions';
import { connect } from 'react-redux';
import constants from './constants/constants';
import { hashHistory } from 'react-router';
import { toastr } from 'react-redux-toastr';

import { setInitialNetworkStatus } from './actions/commonActions';
class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };

        this.theme = constants.themeProvider.activeTheme;
    }

    componentDidMount() {
        if (
            this.props.user &&
            this.props.user.APIKey &&
            this.props.user.APIKey !== ''
        ) {
            setTimeout(() => {
                this.shouldForceLogout();
            }, 1000);
            const initialSchoolID = this.props.schoolID;
            if (this.props.isOnline) {
                this.checkSession(initialSchoolID);
                constants.themeProvider.checkUpdatedThemes().then(() => {
                    return constants.themeProvider.initTheme().then(() => {
                        this.theme = constants.themeProvider.activeTheme;
                        this.setState({ loading: true }, () => {
                            this.setState({ loading: false });
                        });
                    });
                });
            }
        }
        constants.themeProvider.checkUpdatedThemes().then(() => {
            return constants.themeProvider
            .initTheme()
            .then(() => {
                this.theme = constants.themeProvider.activeTheme;
                this.setState({ loading: false });
            })
            .catch(() => {
                this.setState({ loading: false });
            });
        });
        document.addEventListener(
            'newVersionAvailable',
            this.handleNewVersion,
            false
        );
        console.log('DISPATCHING ONLINE', navigator.onLine);
    }
    componentWillUnmount() {
        document.removeEventListener(
            'newVersionAvailable',
            this.handleNewVersion,
            false
        );
    }
    handleNewVersion = () => {
        toastr.warning('Installing Update', 'Please wait...', {
            ...constants.toastrWarning,
            timeOut: 8000
        });
        setTimeout(() => {
            window.location.reload();
        }, 6000);
    };
    logout = () => {
        this.props
            .userLogout()
            .then(() => {
                this.props.manualAjaxEnd();
                setTimeout(() => {
                    hashHistory.push('/');
                }, 500);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    checkSession(initialSchoolID) {
        this.props.manualAjaxStart();
        this.props
            .checkSession(this.props.user)
            .then((resp) => {
                this.checkUpdatedSchoolID(initialSchoolID);
                this.props.manualAjaxEnd();
            })
            .catch((err) => {
                console.error('error while checking session', err);
                this.props.manualAjaxEnd();
                if (err && err === 'invalidApiKey') {
                    toastr.warning('Session expired', 'Please login...', {
                        ...constants.toastrWarning,
                        timeOut: 5000
                    });
                    setTimeout(() => {
                        this.props.userLogoutSessionOnly();
                    }, 5000);
                }
                this.shouldForceLogout();
            });
    }

    /*
     * if it has been too long since renewing the session or logging in, then first warn, then force logout
     */
    shouldForceLogout = () => {
        const now = Date.now();
        if (
            now - this.props.user.lastCheckSession >
            constants.forceLogoutTime
        ) {
            toastr.warning(
                'Session expired for more than 30 days',
                'Please login...',
                {
                    ...constants.toastrWarning,
                    timeOut: 5000
                }
            );
            setTimeout(() => {
                this.props.userLogout();
            }, 5000);
            return;
        }

        if (
            now - this.props.user.lastCheckSession >
            constants.forceLogoutTimeWarning
        ) {
            console.info('session expired warning');
            toastr.warning(
                'Session expired for more than 30 days',
                'If you do not logout and login soon, you will be automatically logged out.',
                {
                    ...constants.toastrWarning,
                    timeOut: 0
                }
            );
            return;
        }
    };

    /*
     * shouldRedirectToBookBag
     * if we have a valid user and are not actively changing the school id then redirect to the bookbag
     */
    shouldRedirectToBookBag = () => {
        if (
            this.props.user.ID.length > 0 &&
            !this.props.changeSchoolID &&
            (this.props.location.pathname.search('login') !== -1 ||
                this.props.location.pathname === '/')
        ) {
            hashHistory.push('bag');
        }
    };

    /*
     * checkUpdatedSchoolID
     * when the schoolID in the URL is different from the User's schoolID, log them out
     */
    checkUpdatedSchoolID = (initialSchoolID) => {
        // console.log('checking school id', this.props.schoolID, this.props.user.SchoolID)
        if (initialSchoolID && this.props.user.SchoolID) {
            if (this.props.user.SchoolID !== initialSchoolID) {
                console.log(
                    'schoolID is different, logging out',
                    this.props.user.SchoolID,
                    initialSchoolID
                );
                this.props.confirmChangeSchoolID(initialSchoolID);
            }
        }
    };

    render() {
        if (this.state.loading === true) {
            return (
                <React.StrictMode>
                    <div
                        className="content"
                        style={{ color: '#000', padding: '20px' }}
                    >
                        <Loading show={true} message={'LOADING...'} />
                    </div>
                </React.StrictMode>
            );
        }
        return (
            <React.StrictMode>
                <div className="App">{this.props.children}</div>
            </React.StrictMode>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.user,
        loading: state.ajaxCallsInProgress > 0,
        isOnline: state.offlineQueue.isOnline && navigator.onLine,
        schoolID:
            ownProps.location.query.SchoolID ||
            ownProps.location.query.schoolid ||
            ownProps.location.query.schoolID
    };
};

export default connect(mapStateToProps, {
    userLogout,
    checkSession,
    userLogoutSessionOnly,
    manualAjaxStart,
    manualAjaxEnd,
    confirmChangeSchoolID,
    setInitialNetworkStatus
})(App);
