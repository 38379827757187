import { Button, Col, FormControl, Row } from 'react-bootstrap';
import { ToastContainer, ToastMessage } from 'react-toastr';
import {
    emptyBLMData,
    saveBLMItem,
    saveBLMTemplate,
    teacherMakesBLMComment,
    updateStatus
} from '../../actions/blmActions';
import {
    showRichTextButtons,
    turnOnDrawingMode,
    turnOnEraser,
    turnOnPointer,
    turnOnTextMode
} from '../../actions/projectToolbar/projectToolbarActions';

import CommonModal from '../common/CommonModal';
import ProjectToolbar from './ProjectToolbar';
import React from 'react';
import { StyledButton } from '../../constants/styledComponents';
import UserAPI from '../../api/userAPI';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import constants from '../../constants/constants';
import { fabric } from 'fabric';
import marker_hl_black from '../../images/marker-black.png';
import marker_hl_blue from '../../images/marker-blue.png';
import marker_hl_orange from '../../images/marker-orange.png';
import { updatePendingItem } from '../../actions/bookViewActions';
import { viewerModes } from '../../constants/viewerModes';

const ToastMessageFactory = React.createFactory(ToastMessage.animation);

// Global Fabric canvas
let fabricCanvas;
class FabricUI extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            commentsSlideUp: false,
            toggleClass: 'fa fa-angle-double-up',
            teachersCommentForStudent: '',
            commentFromTeacher: '',
            showMarkersButtons:
                this.props.location.query.viewerMode !==
                viewerModes.MODE_ADMIN_CREATE_TEMPLATE,
            showCommentButtons:
                this.props.location.query.viewerMode ===
                viewerModes.MODE_TEACHER_STUDENT_BLM,
            showToolbar:
                this.props.location.query.viewerMode !==
                viewerModes.MODE_TEACHER_STUDENT_BLM,
            showStudentSaveButtons:
                this.props.location.query.viewerMode !==
                viewerModes.MODE_ADMIN_CREATE_TEMPLATE,
            showAdminSaveButtons:
                this.props.location.query.viewerMode ===
                viewerModes.MODE_ADMIN_CREATE_TEMPLATE,
            currentFontSize: '40', // part of projectToolbar
            freezeBlm:
                this.props.blm.isComplete ||
                this.props.location.query.viewerMode ===
                    viewerModes.MODE_TEACHER_STUDENT_BLM
                    ? 'none'
                    : 'auto',
            blmButtonsClasses: this.props.blm.isComplete
                ? 'freeze-buttons'
                : 'blm-buttons',
            showBlmSubmitModal: false,
            showResetBLMModal: false
        };
        this.stopDrawingMode = this.stopDrawingMode.bind(this);
        this.startDrawingMode = this.startDrawingMode.bind(this);
        this.toggleComments = this.toggleComments.bind(this);
        this.handleChange = this.handleChange.bind(this);

        // rich text functions used in render
        this.toggleBold = this.toggleBold.bind(this);
        this.toggleLinethrough = this.toggleLinethrough.bind(this);
        this.toggleUnderline = this.toggleUnderline.bind(this);
        this.toggleItalic = this.toggleItalic.bind(this);
        this.changeFontSize = this.changeFontSize.bind(this);
        this.startPointer = this.startPointer.bind(this);
        this.addText = this.addText.bind(this);
        this.showBlmSubmitModal = this.showBlmSubmitModal.bind(this);
        this.doPrint = this.doPrint.bind(this);
        this.saveBLMTemplate = this.saveBLMTemplate.bind(this);
        this.startEraser = this.startEraser.bind(this);
        this.startTextMode = this.startTextMode.bind(this);
        this.displayResetModal = this.displayResetModal.bind(this);
        this.isNotCompleteForStudent = this.isNotCompleteForStudent.bind(this);
        this.exitBlm = this.exitBlm.bind(this);
        this.theme = constants.themeProvider.activeTheme;
    }

    componentDidMount() {
        console.log('FabricUI component mounted');
        fabricCanvas = new fabric.Canvas();
        var el = document.getElementById('blm-canvas');
        this.props.turnOnPointer();

        /* initialize the width and height
         */
        fabricCanvas.initialize(el, {
            width: `${this.props.blm.bounds[0][0]}`,
            height: `${this.props.blm.bounds[0][1]}`
        });

        if (this.props.blm) {
            fabricCanvas.loadFromJSON(this.props.blm.blmData, function (obj) {
                fabricCanvas.renderAll();
            });
            // console.log('HAS TEMPLATE', this.props.blm.fabricItems.HasTemplate)
        }

        // TODO refactor/blms
        // These are fabric.js listeners
        // save data is a listener we made
        fabricCanvas.on('saveData', () => {
            console.log('saving data');
            this.saveBlmDraft();
        });

        // fabricCanvas.on('submitData', () => {
        //   console.log("inside of submit data");
        //   this.props.updateBLMData(fabricCanvas.toObject());
        //   this.saveBlmDraft();
        // })

        // This is called whenever a drawing is made
        fabricCanvas.on('path:created', () => {
            // console.log('drawing created');
            this.saveBlmDraft();
        });

        // this is called whenever an object is moved (I believe)
        fabricCanvas.on('object:modified', () => {
            // console.log('inside of object modified');
            this.saveBlmDraft();
        });

        // this is called wehenever we enter a text box
        fabricCanvas.on('text:editing:entered', (e) => {
            let textbox = e.target;
            let borderColor =
                e.target.templateObject === true
                    ? 'rgba(102,153,255,0.45)'
                    : 'rgba(255, 251, 165, 3)';
            textbox.set({ borderColor: borderColor }); //({borderColor: '#FF5733'});
            if (
                !!e.target &&
                e.target.type === 'textbox' &&
                !this.props.projectToolbar.showRichTextButtons
            ) {
                this.props.updatePendingItem(true);
                var id = fabricCanvas.getObjects().indexOf(e.target);
                this.props.showRichTextButtons();
                this.setState({ currentFontSize: textbox.fontSize }, () => {
                    fabricCanvas.setActiveObject(fabricCanvas.item(id));
                });
            } else if (!e.target || e.target.type !== 'textbox') {
                this.props.turnOnPointer();
            }
        });

        // this is called when a user leaves a text box
        fabricCanvas.on('text:editing:exited', (e) => {
            // console.log('EDITING EXITED')
            this.startPointer();
            // this.saveBlmDraft();  // @jfbloom22 why do we save draft here???
        });

        // this is called when a user selects a box
        // This is also called when we go to erase a text box
        fabricCanvas.on('object:selected', (e) => {
            // console.log('object selected');
            let object = e.target;
            let fontSize = object.fontSize;
            if (
                UserAPI.isStudent(this.props.user.RoleID) &&
                e.target.templateObject === true
            ) {
                // console.log('what is going on?')
                // does this template have a fontSize set on the first character?
                if (
                    object.styles &&
                    object.styles['0'] &&
                    object.styles['0']['0'] &&
                    object.styles['0']['0'].fontSize
                ) {
                    fontSize = object.styles[0][0].fontSize;
                }
                object.set({
                    lockMovementX: true,
                    lockMovementY: true,
                    lockScalingX: true,
                    lockScalingY: true,
                    lockRotation: true,
                    fontSize: fontSize
                });
                object.enterEditing(); // students clicking on a template textbox start editing automatically
            }
            // console.log('erasing', this.props.projectToolbar.erasing);
            if (this.props.projectToolbar.erasing) {
                // console.log('erasing an object!');
                this.eraseObjects(e);
            }
        });
    }
    componentDidUpdate(prevProps) {
        if (this.props.blm.isComplete !== prevProps.blm.isComplete) {
            if (this.props.blm.isComplete) {
                this.setState({
                    freezeBlm: 'none',
                    blmButtonsClasses: 'freeze-buttons'
                });
            } else {
                this.setState({
                    freezeBlm: 'auto',
                    blmButtonsClasses: 'blm-buttons'
                });
            }
        }

        if (
            this.props.blm.projectAssignmentID !==
            prevProps.blm.projectAssignmentID
        ) {
            fabricCanvas.setWidth(this.props.blm.bounds[0][0]);
            fabricCanvas.setHeight(this.props.blm.bounds[0][1]);
            fabricCanvas.clear();
            this.props.turnOnPointer();
        }
        // why are we checking loaded blm data id?
        if (
            this.props.viewerSettings.loadedProjectAssignmentID !==
            prevProps.viewerSettings.loadedProjectAssignmentID
        ) {
            fabricCanvas.loadFromJSON(this.props.blm.blmData, (obj) => {
                fabricCanvas.renderAll();
                // we were using this.props.location.query.blmID
                // TODO: why have blmDataID set in state?
                this.setState({
                    blmDataID: this.props.location.query.projectAssignmentID
                });
            });
        }
    }

    /************** Fabricjs functions **************/

    getActiveStyle(styleName, object) {
        object = object || fabricCanvas.getActiveObject();
        if (!object) return '';

        return object.getSelectionStyles && object.isEditing
            ? object.getSelectionStyles()[styleName] || ''
            : object[styleName] || '';
    }

    setActiveStyle(styleName, value, object) {
        object = object || fabricCanvas.getActiveObject();
        if (!object) return;

        if (object.setSelectionStyles && object.isEditing) {
            var style = {};
            style[styleName] = value;
            object.setSelectionStyles(style);
            object.setCoords();
        } else {
            object.set(styleName, value);
        }

        object.setCoords();
        fabricCanvas.renderAll();
    }

    isBold() {
        return this.getActiveStyle('fontWeight') === 'bold';
    }

    toggleBold() {
        this.props.resetActiveTimeout();
        this.setActiveStyle(
            'fontWeight',
            this.getActiveStyle('fontWeight') === 'bold' ? '' : 'bold'
        );
    }

    isItalic() {
        return this.getActiveStyle('fontStyle') === 'italic';
    }

    toggleItalic() {
        this.props.resetActiveTimeout();
        this.setActiveStyle(
            'fontStyle',
            this.getActiveStyle('fontStyle') === 'italic' ? '' : 'italic'
        );
    }

    isUnderline() {
        return (
            this.getActiveStyle('textDecoration').indexOf('underline') > -1 ||
            this.getActiveStyle('underline')
        );
    }

    toggleUnderline() {
        this.props.resetActiveTimeout();
        var value = this.isUnderline()
            ? this.getActiveStyle('textDecoration').replace('underline', '')
            : this.getActiveStyle('textDecoration') + ' underline';

        this.setActiveStyle('textDecoration', value);
        this.setActiveStyle('underline', !this.getActiveStyle('underline'));
    }

    isLinethrough() {
        return (
            this.getActiveStyle('textDecoration').indexOf('line-through') >
                -1 || this.getActiveStyle('linethrough')
        );
    }

    toggleLinethrough() {
        this.props.resetActiveTimeout();
        var value = this.isLinethrough()
            ? this.getActiveStyle('textDecoration').replace('line-through', '')
            : this.getActiveStyle('textDecoration') + ' line-through';

        this.setActiveStyle('textDecoration', value);
        this.setActiveStyle('linethrough', !this.getActiveStyle('linethrough'));
    }

    changeFontSize(event) {
        this.props.resetActiveTimeout();
        const newFontSize = event.target.value;
        var id = fabricCanvas
            .getObjects()
            .indexOf(fabricCanvas.getActiveObject());
        this.setState({ currentFontSize: newFontSize }, () => {
            fabricCanvas.setActiveObject(fabricCanvas.item(id));
            this.setFontSize(newFontSize);
        });
    }

    getFontSize() {
        return this.getActiveStyle('fontSize');
    }

    /*
     * set font size of a single character
     */

    setFontSize(value) {
        this.props.resetActiveTimeout();
        this.setActiveStyle('fontSize', parseInt(value, 10));
    }

    /*
     * set font size of the entire textbox (not currently being used)
     * this.setDefaultFontSize(fabricCanvas.getActiveObject(), newFontSize);
     */

    setDefaultFontSize(textbox, fontSize) {
        this.props.resetActiveTimeout();
        textbox.set({ fontSize });
    }

    /*
     *  Modes ********************************************************
     */

    startPointer() {
        this.props.resetActiveTimeout();
        // stopDrawingMode() What are we doing here? (fabricUI element that is setting drawingMode to false)
        // stopTextMode() --> fabricUI element that is setting textMode to false
        // console.log('inside start pointer');
        this.props.turnOnPointer();
        this.stopDrawingMode();
        this.stopTextMode();
        fabricCanvas.deactivateAll().renderAll();
    }

    stopDrawingMode() {
        fabricCanvas.isDrawingMode = false;
    }

    startDrawingMode(color = '#09d8f8') {
        this.props.resetActiveTimeout();
        this.props.updatePendingItem(true);
        fabricCanvas.isDrawingMode = true;
        fabricCanvas.freeDrawingBrush.color = color;
        fabricCanvas.freeDrawingBrush.width = 10;
        // fabricCanvas.free
        this.props.turnOnDrawingMode(color);
        fabricCanvas.deactivateAll().renderAll();
    }

    startTextMode() {
        this.props.resetActiveTimeout();
        this.props.updatePendingItem(true);
        fabricCanvas.on('mouse:up', this.addText);
        this.stopDrawingMode();
        this.props.turnOnTextMode();
        // this.props.showRichTextButtons()
        fabricCanvas.deactivateAll().renderAll();
    }

    displayResetModal() {
        this.props.resetActiveTimeout();
        this.setState({ showResetBLMModal: true });
    }

    closeResetBLMModal = () => {
        this.props.resetActiveTimeout();
        this.setState({ showResetBLMModal: false });
    };

    resetBLM = () => {
        this.props.resetActiveTimeout();
        // console.log('inside of reset blm');
        if (this.props.blm.originalBLMTemplate) {
            fabricCanvas.loadFromJSON(
                this.props.blm.originalBLMTemplate.Content,
                function (obj) {
                    fabricCanvas.renderAll();
                }
            );
        } else {
            fabricCanvas.loadFromJSON([], function (obj) {
                fabricCanvas.renderAll();
            });
        }
        this.saveBlmDraft();
        this.setState({ showResetBLMModal: false });
    };

    stopTextMode() {
        // console.log('inside stop text mode');
        // this.props.turnOnPointer();
        fabricCanvas.__eventListeners['mouse:up'] = [];
    }

    startEraser() {
        this.props.resetActiveTimeout();
        this.props.updatePendingItem(true);
        this.stopDrawingMode();
        this.stopTextMode();
        fabricCanvas.deactivateAll().renderAll();
        this.props.turnOnEraser();
        // console.log("turning on eraser");
    }
    stopEraser() {
        this.props.turnOnPointer();
    }
    eraseObjects(e) {
        this.props.resetActiveTimeout();
        // console.log('inside of erase objects');
        if (!!e.target._objects && e.target._objects.length > 1) {
            let blmObjects = e.target._objects;
            blmObjects.forEach((blmObj) => {
                if (blmObj.type === 'textbox') {
                    if (
                        blmObj.templateObject === false ||
                        this.props.location.query.viewerMode ===
                            viewerModes.MODE_ADMIN_CREATE_TEMPLATE
                    ) {
                        fabricCanvas.remove(blmObj);
                    } else {
                        // console.log('you cannot delete that object!');
                        this.props.toastCont.error(
                            `You cannot delete a template object`,
                            `ERROR`,
                            {
                                closeButton: true,
                                showAnimation: 'animated fadeInDown'
                            }
                        );
                    }
                } else {
                    fabricCanvas.remove(blmObj);
                    this.saveBlmDraft();
                }
            });
            this.saveBlmDraft();
            fabricCanvas.deactivateAll().renderAll();
        } else {
            if (e.target.type === 'textbox') {
                if (
                    e.target.templateObject === false ||
                    this.props.location.query.viewerMode ===
                        viewerModes.MODE_ADMIN_CREATE_TEMPLATE
                ) {
                    fabricCanvas.remove(e.target);
                    this.saveBlmDraft();
                } else {
                    // console.log('you cannot delete that object!');
                    this.props.toastCont.error(
                        `You cannot delete a template object`,
                        `ERROR`,
                        {
                            closeButton: true,
                            showAnimation: 'animated fadeInDown'
                        }
                    );
                    return;
                }
            } else {
                fabricCanvas.remove(e.target);
                this.saveBlmDraft();
            }
        }
        this.stopEraser();
    }

    addText(e) {
        this.props.resetActiveTimeout();
        if (this.state.addingText === false) {
            return;
        }
        const pointer = fabricCanvas.getPointer(e.e);
        const posX = pointer.x;
        const posY = pointer.y;
        let templateObject =
            this.props.location.query.viewerMode ===
            viewerModes.MODE_ADMIN_CREATE_TEMPLATE
                ? true
                : false;
        let borderColor =
            this.props.location.query.viewerMode ===
            viewerModes.MODE_ADMIN_CREATE_TEMPLATE
                ? 'rgba(102,153,255,0.45)'
                : 'rgba(255, 251, 165, 3)';
        let backgroundColorForText =
            this.props.location.query.viewerMode ===
            viewerModes.MODE_ADMIN_CREATE_TEMPLATE
                ? 'rgba(102,153,255,0.25)'
                : 'rgba(251, 250, 219, 1)';

        let textbox = new fabric.Textbox('', {
            fontFamily: 'comic sans',
            fontSize: 24,
            templateObject: templateObject,
            backgroundColor: backgroundColorForText,
            width: 200,
            left: posX,
            top: posY
        });

        textbox.set({ borderColor: borderColor }); //({borderColor: '#FF5733'});
        textbox.set({ backgroundColor: backgroundColorForText });
        textbox.set({ borderScaleFactor: 5 });
        textbox.set({ padding: 10 });
        textbox.set({ transparentCorners: true });
        textbox.set({ cornerColor: borderColor });

        fabricCanvas.add(textbox);
        fabricCanvas.setActiveObject(textbox);
        textbox.enterEditing();
        this.stopTextMode();
        fabricCanvas.fire('saveData');
    }

    saveBLMTemplate() {
        this.props.resetActiveTimeout();
        this.stopDrawingMode();
        this.stopTextMode();

        if (!this.props.isOnline) {
            this.props.toastCont.warning(
                `No internet connection.`,
                `Error Saving Template`,
                {
                    closeButton: true,
                    showAnimation: 'animated fadeInDown'
                }
            );
            return;
        }

        let jsonData = fabricCanvas.toDatalessJSON([
            'cornerColor',
            'templateObject',
            'backgroundColor',
            'borderColor',
            'borderScaleFactor',
            'padding',
            'transparentCorners'
        ]);
        let blmID = this.props.blm.ID;

        const saveBLMData = Object.assign(
            {},
            { blackLineMasterID: blmID, Content: jsonData, order: 1 }
        );
        this.props
            .saveBLMTemplate(saveBLMData, this.props.user)
            .then((blmItem) => {
                // this.props.emptyBLMData(this.props.blm); // @jfbloom22 Why do we empty???
                this.props.updatePendingItem(false);
                this.props.toastCont.success(`Blm is saved!`, `Success`, {
                    showAnimation: 'animated fadeInDown'
                });
            })
            .catch((error) => {
                console.error('Error saving blm item', error);
                this.props.toastCont.error(
                    'Unable to save template.  Please try again or contact support.',
                    `Error Saving`,
                    {
                        closeButton: true,
                        showAnimation: 'animated fadeInDown'
                    }
                );
            });
    }

    saveBlmDraft() {
        this.props.resetActiveTimeout();
        if (
            this.props.location.query.viewerMode ===
            viewerModes.MODE_ADMIN_CREATE_TEMPLATE
        ) {
            return;
        } else if (
            this.props.location.query.projectAssignmentID !==
            this.props.blm.projectAssignmentID
        ) {
            console.error('Error saving BLM draft: blmIDs do not match');
            return;
        }
        if (!this.props.isOnline) {
            this.props.toastCont.warning(
                `You may loose your work.  Please connect to the internet.`,
                `Auto Save Error`,
                {
                    closeButton: true,
                    showAnimation: 'animated fadeInDown'
                }
            );
        }
        // Determine the mode and then pass in the correct type
        // When you save a blm, it should clear this.props.blm.blmData
        const jsonData = fabricCanvas.toDatalessJSON([
            'cornerColor',
            'templateObject',
            'backgroundColor',
            'borderColor',
            'borderScaleFactor',
            'padding',
            'transparentCorners'
        ]);
        const blackLineMasterID = this.props.location.query.blmID;
        const HasTemplate = this.props.blm.fabricItems.HasTemplate;
        // const HasTemplate = false; // for testing
        const projectAssignmentID = this.props.location.query
            .projectAssignmentID;
        // double check to make sure we have everything
        if (
            !blackLineMasterID ||
            !projectAssignmentID ||
            projectAssignmentID === '00000000-0000-0000-0000-000000000000'
        ) {
            console.error('missing blmID or projectAssignmentID');
            this.props.toastCont.error(
                'ERROR',
                'Error submitting assignment.  Missing an ID.  Please try again or contact support.',
                {
                    closeButton: true,
                    showAnimation: 'animated fadeInDown'
                }
            );
            return;
        }
        const newBLMItem = Object.assign(
            {},
            {
                type: 1,
                blackLineMasterID,
                projectAssignmentID,
                Content: jsonData,
                order: 1,
                HasTemplate: HasTemplate
            }
        );

        this.props
            .saveBLMItem(newBLMItem, this.props.user, false)
            .then((blmItem) => {
                this.props.updatePendingItem(false);
            })
            .catch((error) => {
                console.error('Error saving blm item', error);

                this.props.toastCont.error(
                    'You may loose your work if you continue editing the project.  Please contact support.',
                    `Error Auto Saving`,
                    {
                        closeButton: true,
                        showAnimation: 'animated fadeInDown'
                    }
                );
            });
        // Potential Solutions:
        // Book actions (pull into two page view blmactions and bookactions and make sure)
        // things are identical
        // Save this to redux
        // Issue is we are saving to redux AND THEN we are sending to the API
        // If we are saving a blm draft we also need to save to canvas
        // We already have a listener (save to canvas saves blm draft)
    }

    submitBLM = () => {
        this.props.resetActiveTimeout();
        if (!this.props.isOnline) {
            this.props.toastCont.warning(
                `No internet connection.`,
                `Error Submitting`,
                {
                    closeButton: true,
                    showAnimation: 'animated fadeInDown'
                }
            );
            return;
        }

        this.stopDrawingMode();
        this.stopTextMode();
        this.setState({
            freezeBlm: 'none',
            blmButtonsClasses: 'freeze-buttons',
            showBlmSubmitModal: false
        });

        const jsonData = fabricCanvas.toDatalessJSON([
            'cornerColor',
            'templateObject',
            'backgroundColor',
            'borderColor',
            'borderScaleFactor',
            'padding',
            'transparentCorners'
        ]);
        const blackLineMasterID = this.props.location.query.blmID;
        const projectAssignmentID = this.props.location.query
            .projectAssignmentID;
        // double check to make sure we have everything
        if (
            !blackLineMasterID ||
            !projectAssignmentID ||
            projectAssignmentID === '00000000-0000-0000-0000-000000000000'
        ) {
            console.error('missing blmID or paID');
            this.props.toastCont.error(
                'ERROR',
                'Error submitting assignment.  Missing an ID.  Please try again or contact support.',
                {
                    closeButton: true,
                    showAnimation: 'animated fadeInDown'
                }
            );
            return;
        }

        const saveBLMData = Object.assign(
            {},
            {
                type: 1,
                blackLineMasterID,
                projectAssignmentID,
                Content: jsonData,
                order: 1
            }
        );
        const submitBLMData = Object.assign(
            {},
            { blackLineMasterID, IsComplete: true, projectAssignmentID }
        );

        this.props
            .saveBLMItem(saveBLMData, this.props.user, true)
            .then((blmItem) => {
                return this.props
                    .updateStatus(submitBLMData, this.props.user)
                    .then((updatedBlm) => {
                        this.props.updatePendingItem(false);
                        this.props.toastCont.success(
                            `Your worksheet has been sent to your teacher!`,
                            `Success`,
                            {
                                showAnimation: 'animated fadeInDown'
                            }
                        );
                    });
            })
            .catch((error) => {
                console.error('Error saving and submitting blm', error);
                this.props.toastCont.error(
                    'Unable to submit project.  Please try again or contact support.',
                    `Error`,
                    {
                        closeButton: true,
                        showAnimation: 'animated fadeInDown'
                    }
                );
            });
    };

    printElement(elem, append, delimiter, clone = true) {
        this.props.resetActiveTimeout();
        var domClone = clone ? elem.cloneNode(true) : elem;
        domClone.style.transform = 'none';
        var $printSection = document.getElementById('printSection');

        if (!$printSection) {
            $printSection = document.createElement('div');
            $printSection.id = 'printSection';
            document.body.appendChild($printSection);
        }

        if (append !== true) {
            $printSection.innerHTML = '';
        } else if (append === true) {
            if (typeof delimiter === 'string') {
                $printSection.innerHTML += delimiter;
            } else if (typeof delimiter === 'object') {
                $printSection.appendChild(delimiter);
            }
        }

        $printSection.appendChild(domClone);
    }

    doPrint() {
        this.props.resetActiveTimeout();
        this.stopDrawingMode();
        this.stopTextMode();
        this.setState({ erasing: false });
        let blmContent = document.getElementById(`blm`).firstElementChild;

        let canvasImage = fabricCanvas.toDataURL({
            format: 'png',
            multiplier: 2
        });
        // Make another div that has the additional information we need ex:
        // var info = document.createElement('div'); info.innerHTML = 'THE ACTUAL INFO'
        let image = document.createElement('img');
        image.src = canvasImage;
        image.style.width = blmContent.style.width;
        image.style.height = blmContent.style.height;
        image.style.position = 'absolute';
        image.style.top = '0';
        image.style.left = '0';
        this.printElement(blmContent, null, null);
        this.printElement(image, true, '', false);
        setTimeout(function () {
            window.print();
            let $printSection = document.getElementById('printSection');
            document.body.removeChild($printSection);
        }, 100);
    }

    showTeachersComment() {
        this.props.resetActiveTimeout();
        if (
            this.props.location.query.viewerMode ===
            viewerModes.MODE_ADMIN_CREATE_TEMPLATE
        ) {
            return false;
        } else if (this.props.blm.teachersComment === '') {
            return false;
        } else {
            return true;
        }
    }

    toggleComments() {
        this.props.resetActiveTimeout();
        this.stopDrawingMode();
        this.stopTextMode();
        if (this.state.commentsSlideUp) {
            this.setState({
                commentsSlideUp: false,
                toggleClass: 'fa fa-angle-double-up'
            });
        } else {
            this.setState({
                commentsSlideUp: true,
                toggleClass: 'fa fa-angle-double-down'
            });
        }
    }

    handleChange(e) {
        this.props.resetActiveTimeout();
        this.setState({ teachersCommentForStudent: e.target.value });
    }

    submitTeacherComment() {
        this.props.resetActiveTimeout();
        if (!this.props.isOnline) {
            this.props.toastCont.warning(
                `No internet connection.`,
                `Error Commenting`,
                {
                    closeButton: true,
                    showAnimation: 'animated fadeInDown'
                }
            );
            return;
        }

        const ProjectAssignmentID = this.props.location.query
            .projectAssignmentID;
        const BlackLineMasterID = this.props.blm.ID;
        const StudentID = this.props.location.query.studentID;
        const teacherComment = Object.assign(
            {},
            {
                BlackLineMasterID,
                StudentID,
                text: this.state.teachersCommentForStudent,
                ProjectAssignmentID
            }
        );
        this.toggleComments(); // hide the comment to make it look like it sent

        this.props
            .teacherMakesBLMComment(teacherComment, this.props.user)
            .then((comment) => {
                // console.log('teacher comment saved', teacherComment);
                this.toastCont.success('Comment was saved!', `Success`, {
                    closeButton: true,
                    showAnimation: 'animated fadeInUp'
                });
            })
            .catch((error) => {
                console.error('Comment was not saved', error);
                this.toastCont.error('Comment was not saved', `Error`, {
                    closeButton: true,
                    showAnimation: 'animated fadeInUp'
                });
            });
    }

    isNotCompleteForStudent() {
        // if the user is a student then we check if this is not complete
        // if it is not complete then we return true which means (SHOW THIS!)
        // If we are not a student, then we need to return true to say, show this)
        if (UserAPI.isStudent(this.props.user.RoleID)) {
            if (this.props.blm.isComplete !== true) {
                return true;
            }
        } else {
            return true;
        }
    }

    exitBlm() {
        if (
            this.props.location.query.viewerMode ===
                viewerModes.MODE_ADMIN_CREATE_TEMPLATE ||
            this.props.location.query.viewerMode ===
                viewerModes.MODE_TEACHER_STUDENT_BLM
        ) {
            // console.log('closing the project as a student');
            this.props.turnOnPointer();
            this.props.closeBookView();
        } else {
            // console.log('closing project as Not a student')
            this.props.turnOnPointer();
            this.props.exitBlmMode(false);
        }
    }
    // MODAL LOGIC BELOW
    closeBlmSubmitModal = () => {
        this.props.resetActiveTimeout();
        this.setState({ showBlmSubmitModal: false });
    };

    showBlmSubmitModal() {
        this.props.resetActiveTimeout();
        this.stopDrawingMode();
        this.stopTextMode();
        this.props.turnOnPointer();
        this.setState({ showBlmSubmitModal: true });
    }
    render() {
        let freezeComments = 'teacher-comments';
        let commentsContainerClassName = this.state.commentsSlideUp
            ? 'comments-container comments-slide-up'
            : 'comments-container';
        let commentsWidth = 12;
        let teachersComment;
        let markerSelected = marker_hl_orange;
        let styles = {
            width: `${this.props.blm.bounds[0][0]}px`,
            height: `${this.props.blm.bounds[0][1]}px`,
            // transform: `scale(${this.props.projectScalePercent})`,
            // WebkitTransform: `scale(${this.props.projectScalePercent})`,
            pointerEvents: `${this.state.freezeBlm}`
        };
        if (
            this.props.location.query.viewerMode ===
                viewerModes.MODE_ADMIN_CREATE_TEMPLATE ||
            this.props.location.query.viewerMode ===
                viewerModes.MODE_TEACHER_STUDENT_BLM
        ) {
            freezeComments = 'teacher-comments';
            commentsWidth = 8;
            teachersComment = this.state.teachersCommentForStudent;
        } else {
            freezeComments = 'teacher-comments freeze';
            teachersComment = this.props.blm.teachersComment;
        }
        if (this.props.projectToolbar.drawingColor === '#000') {
            markerSelected = marker_hl_black;
        } else if (this.props.projectToolbar.drawingColor === '#2A3DB9') {
            markerSelected = marker_hl_blue;
        } else if (this.props.projectToolbar.drawingColor === '#ff8f02') {
            markerSelected = marker_hl_orange;
        }

        return (
            <div>
                <div className={this.props.canvasClassForBlm}>
                    <div style={styles}>
                        <canvas id="blm-canvas"></canvas>
                    </div>
                    {this.showTeachersComment() && (
                        <span className={commentsContainerClassName}>
                            <div className="toggle-comments">
                                <StyledButton
                                    css={this.theme.primaryButtonStyle}
                                    role="button"
                                    onClick={() => this.toggleComments()}
                                >
                                    <i
                                        className={this.state.toggleClass}
                                        aria-hidden="true"
                                    ></i>
                                </StyledButton>
                            </div>
                            <Row>
                                <div className={freezeComments}>
                                    <Col sm={commentsWidth}>
                                        <FormControl
                                            type="text"
                                            value={teachersComment}
                                            placeholder="Enter your notes about the student's work here..."
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                    {this.state.showCommentButtons && (
                                        <Col sm={4}>
                                            <StyledButton
                                                css={
                                                    this.theme
                                                        .primaryButtonStyle
                                                }
                                                className="teacher-comment-save"
                                                onClick={() =>
                                                    this.submitTeacherComment()
                                                }
                                                role="button"
                                                type="button"
                                            >
                                                Save
                                            </StyledButton>
                                            <Button
                                                variant="default"
                                                className="teacher-comment-cancel"
                                                role="button"
                                                type="button"
                                            >
                                                Cancel
                                            </Button>
                                        </Col>
                                    )}
                                </div>
                            </Row>
                        </span>
                    )}
                </div>
                <ProjectToolbar
                    projectToolbar={this.props.projectToolbar}
                    parentState={this.state}
                    exitBlm={this.exitBlm}
                    startPointer={this.startPointer}
                    toggleBold={this.toggleBold}
                    toggleItalic={this.toggleItalic}
                    toggleUnderline={this.toggleUnderline}
                    toggleLinethrough={this.toggleLinethrough}
                    isNotCompleteForStudent={this.isNotCompleteForStudent}
                    startDrawingMode={this.startDrawingMode}
                    startTextMode={this.startTextMode}
                    startEraser={this.startEraser}
                    saveBLMTemplate={this.saveBLMTemplate}
                    showBlmSubmitModal={this.showBlmSubmitModal}
                    displayResetModal={this.displayResetModal}
                    markerSelected={markerSelected}
                    changeFontSize={this.changeFontSize}
                    doPrint={this.doPrint}
                    projectScalePercent={this.props.projectScalePercent}
                    resetActiveTimeout={this.props.resetActiveTimeout}
                />
                <CommonModal
                    className="common-modal"
                    bsSize="small"
                    title={`This will reset all of your markups and textboxes.`}
                    children={`Are you sure want to continue?`}
                    modalVisable={this.state.showResetBLMModal}
                    cancelText="Cancel"
                    submitText="Reset"
                    cancel={this.closeResetBLMModal}
                    submit={this.resetBLM}
                />
                <CommonModal
                    className="common-modal"
                    bsSize="small"
                    title={`Please note that once you submit your work to your teacher you will not be able to make changes until the teacher unlocks it.`}
                    children={`Would you like to continue?`}
                    modalVisable={this.state.showBlmSubmitModal}
                    cancelText="Cancel"
                    submitText="Submit"
                    cancel={this.closeBlmSubmitModal}
                    submit={this.submitBLM}
                />
                <ToastContainer
                    toastMessageFactory={ToastMessageFactory}
                    ref={(toast) => {
                        this.toastCont = toast;
                    }}
                    className="toast-top-right"
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        completedBLMs: state.completedBLMs,
        blmData: state.blmData,
        viewerMode: state.viewerMode,
        projectToolbar: state.projectToolbar
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            saveBLMTemplate,
            emptyBLMData,
            saveBLMItem,
            updateStatus,
            teacherMakesBLMComment,
            turnOnEraser,
            turnOnPointer,
            turnOnDrawingMode,
            turnOnTextMode,
            showRichTextButtons,
            updatePendingItem
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(FabricUI);
