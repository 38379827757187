import { Col, Container, Row } from 'react-bootstrap';
import { StyledButton, StyledDiv } from '../../constants/styledComponents';
import {
    addBook,
    searchBookBagBooks,
    initialDashboardQuery,
    removeBook,
    downloadBook,
    deleteDownloadedBook
} from '../../actions/bookActions';
import { addQuery, removeQuery } from '../../vendor/utils-router';
import { manualAjaxStart } from '../../actions/ajaxStatusActions';

import BookBagHeader from './BookBagHeader';
import Loading from '../common/Loading';
import React from 'react';

import config from '../../api/config';
import { connect } from 'react-redux';
import constants from '../../constants/constants';
import defaultPhoto from '../../images/default.jpg';
import downloadedImage from '../../images/downloaded.png';
import { forEach } from 'lodash';
import { hashHistory } from 'react-router';
import { indexOf } from 'lodash';

import readBook from '../../images/read.png';
import { requireSignIn } from '../../routes';

import { sortBy } from 'lodash';
import { toastr } from 'react-redux-toastr';
import { toggleUserProfileModal } from '../../actions/dashboard/dashboardActions';
import unReadBook from '../../images/unread.png';
import { toggleClassCodeModal, userLogout } from '../../actions/userActions';
import UltimatePagination from 'react-ultimate-pagination-bootstrap-4';
import { BookBagSearchContainer } from './BookBagSearchContainer';
import UserAPI from '../../api/userAPI';

let bookState = unReadBook;

class BookBag extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            prevSelected: [],
            selectedTypes: [],
            selectedBookID: false,
            loading: false,
            emptySearchMessage:
                "Please enter your search criteria above and press 'Go'"
        };

        this.openingBook = false;
        this.theme = constants.themeProvider.activeTheme;
    }

    componentDidMount() {
        console.log('bookbag is mounted');
        // this.handleTypeChange(this.props.query.tag); // TODO what is this????
        // this.handleTypeChange(this.props.query.type);
        // remove the BLM ID and projectAssignmentID from query so that we do not stay in BLM mode when switching books
        removeQuery('blmID');
        removeQuery('projectAssignmentID');
        this.checkAddCode();
        this.checkCanAccessBookBag();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.books !== this.props.books) {
            // set the appropriate empty search message.  If this is the local bag and they have no search criteria, then they have no books in their bag
            let emptySearchMessage = '';
            if (this.props.books.length <= 0 && !this.filtersActive()) {
                emptySearchMessage = `You currently do not have any books in your book bag.`;
            }
            this.setState({ emptySearchMessage });
        }
        if (prevProps.query !== this.props.query) {
            this.setState({ prevSelected: [] });
        }
    }

    /*
    * only students, generic, and demo can access the bookbag.  otherwise log them out
    */
    checkCanAccessBookBag = () => {
        if (this.props.user.SchoolID && (this.props.isStudent || this.props.isDemo || this.props.isGeneric) === false){
            toastr.warning('Warning', 'User does not have access to the bookbag.  Logging out...', constants.toastrWarningOptions);
            setTimeout(() => {
                this.props.userLogout();
                hashHistory.replace('/');
            }, 5000);
        }


    }

    checkAddCode = () =>{
        console.log('checking addcode')
        if (this.props.addCode){
            setTimeout(() => {
                this.props.toggleClassCodeModal(true);
            }, 1000);
        }
    }

    handleBookClick = (e, book) => {
        if (e) {
            e.preventDefault();
        }
        if (this.state.selectedBookID === book.ID) {
            this.openBook(book);
        } else {
            this.setState({ selectedBookID: book.ID });
        }
    };

    openBook = (book) => {
        // prevent double click on open book
        // If we are offline:
        if (this.openingBook) return;

        this.openingBook = true;
        setTimeout(() => {
            this.openingBook = false;
        }, 300);

        if (book.IsExternal) {
            // open the book in the Lerner viewer, not in the DiBS viewer
            const externalURL = `${config.API.Main}/book/openexternalviewer?bookID=${book.ID}`;
            var win = window.open(externalURL, '_blank');
            win.focus();
        } else {
            // addQuery({bookID: book.ID});
            const location = Object.assign(
                {},
                hashHistory.getCurrentLocation()
            );
            Object.assign(location.query, { bookID: book.ID });
            hashHistory.push({
                pathname: `/viewer`,
                query: location.query
            });
        }
    };

    handleSubmit = (e, activePage) => {
        if (e) {
            e.preventDefault();
        }
        console.log(`initiating a bookbag search: ${this.props.query.search}`);
        addQuery({ activePage });
        this.props.searchBookBagBooks(this.filtersActive(), {
            ...this.props.query,
            activePage
        });
    };

    // are any book bag filters active? returns a boolean
    filtersActive = () => {
        if (
            this.props.query.search.length === 0 &&
            this.props.query.type.length === 0 &&
            this.props.query.tag.length === 0 &&
            this.props.query.grl.length === 0 &&
            this.props.bookbagFilters.length === 0
        ) {
            return false;
        } else {
            return true;
        }
    };

    handleInvalidSession = () => {
        toastr.error(
            `Please login again.`,
            `Session Expired`,
            constants.toastrErrorOptions
        );
        setTimeout(() => {
            this.props.userLogout();
            hashHistory.replace('/');
        }, 3000);
    };

    displayBookReadingLevel = (book) => {
        let grl, atos, lex;
        let hideBookReadingLevel = 'book-reading-level';
        if (
            book.GuidedReadingLevel === '' ||
            book.GuidedReadingLevel === null
        ) {
            grl = 'grl-hide';
        } else if (book.ATOS === '' || book.ATOS === null) {
            atos = 'atos-hide';
        } else if (book.LEX === '' || book.LEX === null) {
            lex = 'lex-hide';
        }

        if (!book.GuidedReadingLevel && !book.ATOS && !book.LEX) {
            hideBookReadingLevel = '';
            return;
        }

        return (
            <div className={hideBookReadingLevel}>
                {book.ATOS !== null && book.ATOS !== '' && (
                    <span className={atos}>A: {book.ATOS}, </span>
                )}
                {book.LEX !== null && book.LEX !== '' && (
                    <span className={lex}>L: {book.LEX}, </span>
                )}
                {book.GuidedReadingLevel !== null &&
                    book.GuidedReadingLevel !== '' && (
                        <span className={grl}>
                            GRL: {book.GuidedReadingLevel}
                        </span>
                    )}
            </div>
        );
    };

    displayBookImage = (book) => {
        let img = !!book.OfficialImage ? book.OfficialImage : defaultPhoto;
        const bookTitle = book.Title;
        let downloadedIcon;
        forEach(this.props.downloadedBooks, (dlBook) => {
            if (book.ID === dlBook.ID) {
                downloadedIcon = downloadedImage;
            }
        });

        let photo = {
            backgroundImage: `url(${img})`,
            backgroundPosition: 'center center',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat'
        };
        if (!!book.OfficialImage) {
            return (
                <div>
                    <p className="book-title">{bookTitle}</p>
                    <div
                        className="book-details"
                        style={photo}
                        onClick={(e) => this.handleBookClick(e, book)}
                    >
                        <img
                            className="downloaded-book"
                            src={downloadedIcon}
                            alt=""
                        />
                        {book.IsExternal && (
                            <div className="btn-blue external-link">
                                <i
                                    className="fa fa-external-link"
                                    aria-hidden="true"
                                ></i>
                            </div>
                        )}
                        {this.displayBookReadingLevel(book)}
                    </div>
                </div>
            );
        } else {
            return (
                <div
                    className="book-details"
                    style={photo}
                    onClick={(e) => this.handleBookClick(e, book)}
                >
                    <img
                        className="downloaded-book"
                        src={downloadedIcon}
                        alt=""
                    />
                    {book.IsExternal && (
                        <div className="btn-blue external-link">
                            <i
                                className="fa fa-external-link"
                                aria-hidden="true"
                            ></i>
                        </div>
                    )}
                    <p className="book-title">{bookTitle}</p>
                    {this.displayBookReadingLevel(book)}
                </div>
            );
        }
    };

    render() {
        if (requireSignIn(this.props.user, this.props.location)) {
            return null;
        }
        let books = !!this.props.books
            ? this.props.books.filter((book) => {
                  return book;
              })
            : [];
        const downloadedBooks = this.props.downloadedBooks;
        let filteredBooks = this.props.isOnline
            ? sortBy(books, [
                  function (o) {
                      return o.Title;
                  }
              ])
            : sortBy(downloadedBooks, [
                  function (o) {
                      return o.Title;
                  }
              ]);

        return (
            <Container className="content modal-container book-bag-content">
                <BookBagHeader
                    isOnline={this.props.isOnline}
                    toggleUserAccountModal={this.props.toggleUserProfileModal}
                    addCode={this.props.addCode}
                />
                <BookBagSearchContainer
                    query={this.props.query}
                    theme={this.theme}
                    isFiltersActive={this.filtersActive()}
                    handleInvalidSession={this.handleInvalidSession}
                />

                {!this.props.isOnline && (
                    <Row>
                        <Col md={12} className="offline-message">
                            You are offline
                        </Col>
                    </Row>
                )}
                <Row className="books-row text-center animated bounceInUp">
                    {filteredBooks.length <= 0 && (
                        <Col>
                            <div className="empty-search-message">
                                {this.state.emptySearchMessage}
                            </div>
                        </Col>
                    )}
                    {filteredBooks.map((book) => {
                        let selected = 'd-none';
                        let glow = 'book-cover book-shadow';
                        let bookHeight = '';

                        if (this.state.selectedBookID === book.ID) {
                            selected = 'visible animated fadeInDown';
                            bookHeight = ' pulse raise-book';
                            glow = 'book-cover glow';
                            if (book.CurrentPage !== null && book.CurrentPage !== 1) {
                                bookState = readBook;
                            }
                        } else if (
                            indexOf(this.state.prevSelected, book.ID) !== -1
                        ) {
                            bookHeight = ' ';
                        }
                        if (book.CurrentPage !== null && book.CurrentPage !== 1) {
                            bookState = readBook;
                        } else {
                            bookState = unReadBook;
                        }
                        const isDownloaded = this.props.downloadedBooks.find(
                            (downloadedBook) => downloadedBook.ID === book.ID
                        )
                            ? true
                            : false;
                        return (
                            <Col
                                sm={3}
                                md={3}
                                key={book.ID}
                                className={`${bookHeight} book-container`}
                            >
                                <div className="book">
                                    <div className={glow}>
                                        {this.displayBookImage(book)}
                                        <img src={bookState} alt="" />
                                    </div>
                                    <div className={selected}>
                                        <StyledButton
                                            className="book-button"
                                            css={
                                                this.theme.secondaryButtonStyle
                                            }
                                            onClick={() => this.openBook(book)}
                                        >
                                            <Col md={12}>READ</Col>
                                        </StyledButton>
                                        {book.isInBookBag === true &&
                                            isDownloaded === false && (
                                                <StyledButton
                                                    className="download-book-button"
                                                    css={
                                                        this.theme
                                                            .successButtonStyle
                                                    }
                                                    onClick={() =>
                                                        this.props
                                                            .downloadBook(book)
                                                            .then(() => {
                                                                toastr.success('Succcess', 'Book downloaded for viewing offline.', constants.toastrSuccessOptions)
                                                            })
                                                    }
                                                >
                                                    <Col md={12}>
                                                        DOWNLOAD
                                                    </Col>
                                                </StyledButton>
                                            )}
                                        {book.isInBookBag === true &&
                                            isDownloaded === true && (
                                                <StyledButton
                                                    className="download-book-button"
                                                    css={
                                                        this.theme
                                                            .successButtonStyle
                                                    }
                                                    onClick={() =>
                                                        this.props.deleteDownloadedBook(
                                                            book.ISBN
                                                        )
                                                    }
                                                >
                                                    <Col md={12}>
                                                        REMOVE DOWNLOAD
                                                    </Col>
                                                </StyledButton>
                                            )}

                                        {book.isInBookBag === false && (
                                            <StyledButton
                                                className="add-book-button"
                                                css={
                                                    this.theme
                                                        .successButtonStyle
                                                }
                                                onClick={() =>
                                                    this.props.addBook(book)
                                                }
                                            >
                                                <Col md={12}>
                                                    ADD TO BOOK BAG
                                                </Col>
                                            </StyledButton>
                                        )}
                                        {book.IsStudentAdded === true &&
                                            book.isInBookBag === true && (
                                                <StyledButton
                                                    className="add-book-button"
                                                    css={
                                                        this.theme
                                                            .successButtonStyle
                                                    }
                                                    onClick={() =>
                                                        this.props.removeBook(
                                                            book.ID, book.ISBN
                                                        )
                                                    }
                                                >
                                                    <Col md={12}>
                                                        REMOVE FROM BAG
                                                    </Col>
                                                </StyledButton>
                                            )}
                                    </div>
                                </div>
                            </Col>
                        );
                    })}
                </Row>
                {this.props.books.length > 0 && (
                    <Row>
                        <Col md={12} className="text-center">
                            <StyledDiv
                                css={{
                                    '.pagination li.active a': {
                                        'background-color': this.theme
                                            .primaryBackground,
                                        'border-color': this.theme
                                            .primaryBackground
                                    },
                                    '.pagination': {
                                        margin: '5px 50% 30px 50%'
                                    }
                                }}
                            >
                                <UltimatePagination
                                    totalPages={this.props.pageResults}
                                    boundaryPagesRange={2}
                                    currentPage={parseInt(
                                        this.props.query.activePage,
                                        10
                                    )}
                                    onChange={(pageNumber) =>
                                        this.handleSubmit('', pageNumber)
                                    }
                                />
                            </StyledDiv>
                        </Col>
                    </Row>
                )}
                <Loading show={this.props.loading} />
            </Container>
        );
    }
}

function mapStateToProps(state, ownProps) {
    const addCode = ownProps.location.query.addCode || ownProps.location.query.addcode || ownProps.location.query.AddCode;
    return {
        completedBLMs: state.completedBLMs,
        user: state.user,
        book: state.book,
        books: state.books,
        bookbagFilters: state.bookbagFilters,
        loading: state.ajaxCallsInProgress > 0,
        isOnline: state.offlineQueue.isOnline && navigator.onLine,
        downloadedBooks: state.downloadedBooks,
        pageResults: state.dashboardPageResults,
        query: { ...initialDashboardQuery, ...ownProps.location.query },
        addCode,
        isGeneric: UserAPI.isGeneric(state.user.RoleID),
        isStudent: UserAPI.isStudent(state.user.RoleID),
        isDemo: UserAPI.isDemo(state.user.RoleID)
    };
}

export default connect(mapStateToProps, {
    addBook,
    removeBook,
    searchBookBagBooks,
    userLogout,
    manualAjaxStart,
    toggleUserProfileModal,
    downloadBook,
    deleteDownloadedBook,
    toggleClassCodeModal
})(BookBag);
