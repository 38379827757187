/*
 * Pages component is responsible for containing the book and/or project pages.
 * it is also responsible for gestures and changing pages
 * It centers the pages currently only horrizontally ( in the future we would like it to center vertically as well) It positions the left and right arrow buttons.
 * it does this by adding up the width and height of the elements it contains, then centers itself
 * try simply adding up the width of the elements inside and then setting the pages container width.
 * calculate the width of each page by multiplying their bounds by the scalePercent of that page.
 */

import * as React from 'react';

import BLM from '../blm/BLM';
import BookPage from './BookPage';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { StyledButton } from '../../constants/styledComponents';
import UserAPI from '../../api/userAPI';
import constants from '../../constants/constants';
import icon_arrowleft from '../../images/icon_arrowleft.png';
import icon_arrowright from '../../images/icon_arrowright.png';
import { viewerModes } from '../../constants/viewerModes';
import {map} from 'lodash';
class Pages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            transX: '',
            transY: '',
            scaleX: '',
            scaleY: '',
            pagesContainerWidth: 0,
            pagesContainerHeight: 0,
            leftMarginForArrow: 0,
            dragging: false
        };
        this.handleSwipe = this.handleSwipe.bind(this);
        this.resizePagesContainer = this.resizePagesContainer.bind(this);
        this.pagesOnMouseDown = this.pagesOnMouseDown.bind(this);
        this.pagesOnMouseMove = this.pagesOnMouseMove.bind(this);
        this.pagesOnMouseUp = this.pagesOnMouseUp.bind(this);
        this.shouldShowProject = this.shouldShowProject.bind(this);

        this.lastClientX = 0;
        this.lastClientY = 0;
        this.theme = constants.themeProvider.activeTheme;
    }

    componentDidMount() {
        // window.addEventListener('resize', this.resizePagesContainer);
        // this.resizePagesContainer();
    }

    componentWillUnmount() {
        // window.removeEventListener('resize', this.resizePagesContainer);
    }

    /*
     * Step 5 in loading pages container
     *
     */
    componentDidUpdate(prevProps) {
        let shouldResize = false;
        if (
            prevProps.leftPageContainerWidth !==
                this.props.leftPageContainerWidth ||
            prevProps.leftPageContainerHeight !==
                this.props.leftPageContainerHeight
        ) {
            shouldResize = true;
        }
        if (prevProps.pagesVisible !== this.props.pagesVisible) {
            shouldResize = true;
        }
        // we must check for BLM mode changes here because the size and scale do not change when closing a BLM on a wide screen
        if (
            prevProps.blm.Title !== this.props.blm.Title ||
            prevProps.blmMode !== this.props.blmMode
        ) {
            shouldResize = true;
        }
        // if (prevProps.book.ID !== this.props.book.ID || prevProps.book.CurrentPage !== this.props.book.CurrentPage) {
        //   this.resizePagesContainer();
        // }
        // if (prevProps.bookScalePercent !== this.props.bookScalePercent){
        // this.resizePagesContainer();
        // }
        // when the project is the right page, we need to resize after it has
        if (prevProps.projectScalePercent !== this.props.projectScalePercent) {
            shouldResize = true;
        }
        if (prevProps.book.bookIsReady !== this.props.book.bookIsReady) {
            shouldResize = true;
        }
        if (prevProps.blm.projectIsReady !== this.props.blm.projectIsReady) {
            shouldResize = true;
        }
        if (shouldResize) {
            this.resizePagesContainer();
        }
    }

    /*
     * resize the pages container
     *
     */
    resizePagesContainer() {
        // console.log('resizing pages container')
        // const maxContentHeight = window.innerHeight - 120; // height of window - toolbar and top toggle
        let leftW = 0;
        let leftH = 0;
        // let rightW = this.props.book.bounds[rIndex][0];
        // let rightH = this.props.book.bounds[rIndex][1];
        let rightW = 0;
        let rightH = 0;
        const pagesVisible = this.props.pagesVisible;
        let leftScalePercent = this.props.bookScalePercent; // TODO might rename bookScalePercent to leftPageScalePercent.  We are Assuming that the pages of the book are all the same size.
        let rightScalePercent = 0;
        let totalWidthOfPages = 0;
        let tallestPageHeight = 0;
        let widthOfArrow = 50;
        let widthOfProjectToolbar = 50;
        let leftMarginForArrow = 0;
        let gapBetweenPages = 5;

        // if blmMode then use the bounds of the BLM instead.
        if (this.props.blmMode && this.props.blm.projectIsReady) {
            if (pagesVisible === 1) {
                leftScalePercent = this.props.projectScalePercent;
                leftW = this.props.blm.bounds[0][0] * leftScalePercent;
                leftH = this.props.blm.bounds[0][1] * leftScalePercent;
                totalWidthOfPages = leftW + widthOfProjectToolbar;
            } else {
                leftW = this.props.leftPageContainerWidth;
                leftH = this.props.leftPageContainerHeight;
                // TODO before determining the rightScalePercent we need to determin if we are limiting by height or width.
                // maybe we calculate both the scalePercent for height and width - then use the greater scaling (resulting in smaller page size)
                rightScalePercent = this.props.projectScalePercent;
                // rightScalePercentWidth = maxContentWidth / rightW;
                rightW = this.props.blm.bounds[0][0] * rightScalePercent;
                rightH = this.props.blm.bounds[0][1] * rightScalePercent;
                totalWidthOfPages =
                    leftW + rightW + widthOfArrow + gapBetweenPages;
                leftMarginForArrow = leftW;
                // if the Project is wider than it is tall, switch to single page view automatically
                if (leftW > leftH) {
                    this.props.automaticUpdatePagesVisible(1);
                }
            }
        } else if (this.props.book.bookIsReady) {
            if (pagesVisible === 1) {
                leftW = this.props.leftPageContainerWidth;
                leftH = this.props.leftPageContainerHeight;
                totalWidthOfPages = leftW;
                leftMarginForArrow = leftW;
            } else {
                // set both left and right to the leftPageContainer because it is easier and we can assume that both pages of the book are the same size.
                leftW = this.props.leftPageContainerWidth;
                leftH = this.props.leftPageContainerHeight;
                rightW = this.props.leftPageContainerWidth;
                rightH = this.props.leftPageContainerHeight;
                totalWidthOfPages = leftW + rightW + gapBetweenPages;
                leftMarginForArrow = leftW + rightW + gapBetweenPages;
                // if the book page is wider than it is tall, switch to single page view automatically
                // if (leftW > leftH){
                //   this.props.automaticUpdatePagesVisible(1);
                // }
            }
        }

        tallestPageHeight = leftH > rightH ? leftH : rightH;

        this.setState({
            pagesContainerHeight: tallestPageHeight,
            pagesContainerWidth: totalWidthOfPages,
            leftMarginForArrow
        });
    }

    handleSwipe(direction) {
        this.props.resetActiveTimeout();
        console.log(`you swiped ${direction}`);
        switch (direction) {
            case 'top':
                break;
            case 'bottom':
                break;
            case 'left':
                this.props.nextPage();
                break;
            case 'right':
                this.props.prevPage();
                break;
            default:
                break;
        }
    }

    getBookPage(page, pageID) {
        return (
            <BookPage
                {...this.props}
                pageID={pageID}
                page={page}
                pagesContainerHeight={this.state.pagesContainerHeight}
                pagesContainerWidth={this.state.pagesContainerWidth}
                key={pageID}
                pagesOnMouseDown={this.pagesOnMouseDown}
                pagesOnMouseMove={this.pagesOnMouseMove}
                pagesOnMouseUp={this.pagesOnMouseUp}
            />
        );
    }

    /*
     * do Not show the book if we are in BLMMode and only 1 page visible
     * wait to show the book until prop.pages is defined
     */
    shouldShowBook() {
        let showBook = false;
        if (this.state.pagesContainerWidth === 0) {
            showBook = false;
        }
        if (!this.props.book.bookIsReady) {
            return false;
        }

        // wait for the highlighters to be ready - this might block the book page from loading completely
        // if (!this.props.highlighterLeft || !this.props.highlighterRight){
        //   return false;
        // }
        if (this.props.blmMode && this.props.pagesVisible === 1) {
            showBook = false;
        } else if (!!this.props.book.cachedPages) {
            showBook = true;
        } else {
            showBook = false;
        }
        return showBook;
    }
    shouldShowProject() {
        let showProject = false;
        if (
            this.props.blmMode &&
            this.props.blm.bounds &&
            this.state.pagesContainerWidth &&
            this.props.blm.projectIsReady
        ) {
            showProject = true;
        }
        return showProject;
    }

    /*
     * shouldShowCLoseButton
     */
    shouldShowCloseButton = () => {
        const { RoleID } = this.props.user;
        if (
            // is a student
            UserAPI.isStudent(RoleID) === true ||
            // is generic and not resource mode
            (UserAPI.isGeneric(RoleID) === true &&
                this.props.viewerMode !== viewerModes.MODE_RESOURCE) ||
            // is demo
            UserAPI.isDemo(RoleID) === true
        ) {
            return true;
        } else {
            return false;
        }
    };

    /*
     * respond to mouse down
     * if we are not already dragging and the pointer tool is active, then drag scroll
     */
    pagesOnMouseDown(e, pageNumber) {
        e.persist();
        if (!this.state.dragging && this.props.bookToolbar.pointing) {
            this.setState({ dragging: true });
            this.lastClientX = e.clientX || e.touches[0].clientX;
            this.lastClientY = e.clientY || e.touches[0].clientY;
            const tempX = this.lastClientX;
            const tempY = this.lastClientY;

            // if they don't move much, then they are highlighting rather than dragging
            setTimeout(() => {
                const moveX = parseInt(tempX - this.lastClientX, 10);
                const moveY = parseInt(tempY - this.lastClientY, 10);
                if (moveX < 30 || moveY < 30) {
                    this.setState({ dragging: false });
                }
            }, 200);
        }
        this.props.onMouseDown(e, pageNumber);
    }
    pagesOnMouseMove(e) {
        e.persist();
        if (this.state.dragging) {
            this.pagesContainer.scrollLeft -=
                -this.lastClientX + (this.lastClientX = e.clientX);
            this.pagesContainer.scrollTop -=
                -this.lastClientY + (this.lastClientY = e.clientY);
            this.mouseMove++;
        }
        this.props.onMouseMove(e);
    }

    pagesOnMouseUp(e, pageNumber) {
        if (this.state.dragging) {
            this.setState({ dragging: false });
        }

        e.persist();
        this.props.onMouseUp(e, pageNumber);
    }

    render() {
        let bookReadyClass = this.shouldShowBook() ? 'book-ready' : '';
        let classes = `pages ${this.props.bookToolbar.pagesClassName} ${bookReadyClass}`;
        let showBookArrows = 'block';
        // TODO canvasClassForBLM is defined in Pages as well as BLM - pick one!
        let canvasClassForBlm, bookArrowLeftStyle, bookArrowRightStyle;
        if (this.props.pagesVisible === 1 && this.props.blmMode) {
            showBookArrows = 'none';
            canvasClassForBlm = 'page single-page blm-canvas-container';
        } else if (this.props.pagesVisible === 2 && this.props.blmMode) {
            canvasClassForBlm = 'page blm-page blm-canvas-container';
        }
        if (this.props.blmMode) {
            classes += ' blm-mode';
        }
        if (this.props.pagesVisible === 1) {
            classes += ' one-visible';
        } else {
            classes += ' two-visible';
        }
        bookArrowLeftStyle = { display: `${showBookArrows}` };
        bookArrowRightStyle = {
            display: `${showBookArrows}`,
            marginLeft: `${this.state.leftMarginForArrow}px`
        };
        const pagesContainerStyle = {
            width: `${this.state.pagesContainerWidth}px`,
            height: `${this.state.pagesContainerHeight}px`
        };
        return (
            <div
                style={{
                    overflow: 'auto',
                    height: '100vh',
                    width: '100vw',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                }}
                ref={this.pagesContainer}
            >
                {this.shouldShowCloseButton() && (
                    <StyledButton
                        variant="link"
                        className="close-book"
                        css={this.theme.primaryIconOnlyButtonStyle}
                        onClick={() => this.props.closeBookView()}
                    >
                        <i className="fa fa-times" aria-hidden="true"></i>
                    </StyledButton>
                )}
                <div className="pages-visible-buttons">
                    <StyledButton
                        className="item icon-btn"
                        css={this.theme.primaryButtonStyle}
                        role="button"
                        onClick={() => this.props.updatePagesVisible(1)}
                    >
                        <i className="fa fa-file-o" aria-hidden="true"></i>
                    </StyledButton>
                    <StyledButton
                        className="item icon-btn"
                        css={this.theme.primaryButtonStyle}
                        role="button"
                        onClick={() => this.props.updatePagesVisible(2)}
                    >
                        <i className="fa fa-columns" aria-hidden="true"></i>
                    </StyledButton>
                </div>
                <div id="pages" className={classes} style={pagesContainerStyle} ref={this.props.forwardedRef}>
                    <div className="pageIndex prev" style={bookArrowLeftStyle}>
                        <Button
                            variant="default"
                            role="button"
                            onClick={this.props.prevPage}
                        >
                            <img alt="" src={icon_arrowleft} />
                        </Button>
                    </div>
                    <div className="pageIndex next" style={bookArrowRightStyle}>
                        <Button
                            variant="default"
                            role="button"
                            onClick={this.props.nextPage}
                        >
                            <img alt="" src={icon_arrowright} />
                        </Button>
                    </div>

                    {this.shouldShowBook() &&
                        map(this.props.book.cachedPages, (page, pageID) => {
                            return this.getBookPage(page, pageID);
                        })}

                    {this.shouldShowProject() && (
                        <BLM
                            blmHtml={this.props.blmHtml}
                            blm={this.props.blm}
                            user={this.props.user}
                            toastCont={this.props.toastCont}
                            blmMode={this.props.blmMode}
                            transX={this.state.transX}
                            transY={this.state.transY}
                            scaleY={this.state.scaleY}
                            scaleX={this.state.scaleX}
                            exitBlmMode={this.props.exitBlmMode}
                            canvasClassForBlm={canvasClassForBlm}
                            closeBookView={this.props.closeBookView}
                            location={this.props.location}
                            viewerSettings={this.props.viewerSettings}
                            isOnline={this.props.isOnline}
                            leftPageContainerWidth={
                                this.props.leftPageContainerWidth
                            }
                            leftPageContainerHeight={
                                this.props.leftPageContainerHeight
                            }
                            pagesContainerHeight={
                                this.state.pagesContainerHeight
                            }
                            pagesContainerWidth={this.state.pagesContainerWidth}
                            pagesVisible={this.props.pagesVisible}
                            updateProjectScalePercent={
                                this.props.updateProjectScalePercent
                            }
                            projectScalePercent={this.props.projectScalePercent}
                            resetActiveTimeout={this.props.resetActiveTimeout}
                        />
                    )}
                </div>
            </div>
        );
    }
}

Pages.propTypes = {
    onMouseDown: PropTypes.func.isRequired,
    onMouseUp: PropTypes.func.isRequired,
    onMouseMove: PropTypes.func.isRequired,
    nextPage: PropTypes.func.isRequired,
    prevPage: PropTypes.func.isRequired,
    pagesVisible: PropTypes.number.isRequired,
    blmMode: PropTypes.bool.isRequired,
    user: PropTypes.object.isRequired,
    book: PropTypes.object.isRequired,
    highlighterRight: PropTypes.object,
    highlighterLeft: PropTypes.object,
    wordTapped: PropTypes.func.isRequired,
    location: PropTypes.object,
    updateLeftPageContainer: PropTypes.func.isRequired,
    leftPageContainerWidth: PropTypes.number.isRequired,
    leftPageContainerHeight: PropTypes.number.isRequired,
    bookScalePercent: PropTypes.number.isRequired,
    updateBookScalePercent: PropTypes.func.isRequired,
    updateProjectScalePercent: PropTypes.func.isRequired,
    projectScalePercent: PropTypes.number,
    automaticUpdatePagesVisible: PropTypes.func.isRequired,
    bookToolbar: PropTypes.object.isRequired,
    resetActiveTimeout: PropTypes.func.isRequired,
    viewerMode: PropTypes.string.isRequired
};

export default React.forwardRef((props, ref) => {
    return <Pages {...props} forwardedRef={ref} />;
  });;
