import { camelCase } from 'lodash';
import dibsLogo from '../images/dibs-logo.png';
import brainhiveLogo from '../images/logo.png';
import themesById from '../constants/themes.json';
import BookFS from '../api/bookFS';
import config from '../api/config';
import { pWaCompatUtil } from './pWaCompatUtil';
import { CSSProp } from 'styled-components';

interface Itheme {
    name: string;
    logoSrc: string;
    primaryBackground: string;
    primaryDark: string;
    primaryLight:string;
    primaryFontColor: string;
    secondaryBackground: string;
    secondaryButtonStyle?: CSSProp; // generated on front end
    warningButtonStyle?: CSSProp;
    primaryButtonStyle?: CSSProp;
    successButtonStyle?: CSSProp;
    primaryIconOnlyButtonStyle?: CSSProp;
    secondaryDark: string;
    secondaryLight: string;
    secondaryFontColor: string;
    warningBackground: string;
    warningDark: string;
    warningLight: string;
    warningFontColor: string;
    successBackground: string;
    successDark: string;
    successLight: string;
    successFontColor: string;
    error: string;
    manifestUrl: string;
}

export const themeProvider = {
    activeTheme: themesById['default'] as Itheme,
    themesById: undefined as {[key:string]: Itheme} | undefined, // only populated if we have received updated themes from the API
    initTheme: function () {
        return BookFS.fs.getItem('activeTheme').then((persistedActiveTheme: any) => {
            // apply any updates we might have received into themesByID
            if (this.themesById) {
                console.log('init theme from API');
                return this.setTheme(
                    this.selectThemeBySubdomain(this.themesById)
                );
            } else if (persistedActiveTheme) {
                console.log('init persisted theme');
                return this.setTheme(persistedActiveTheme);
            } else {
                console.log('init fallback theme');
                // no persistedTheme, and no updated themes from the API, fallback to local json version of themes
                return this.setTheme(this.selectThemeBySubdomain());
            }
        }).catch(error => console.error('error initializing theme', error));
    },
    selectThemeBySubdomain: function (themes: {[key:string]: Itheme} = themesById) {
        let subdomain = 'default';
        let hasTheme = false;
        Object.keys(themes).forEach((key) => {
            hasTheme = window.location.href.search(key) !== -1;
            if (hasTheme) {
                subdomain = key;
            }
        });
        return themes[subdomain];
    },
    normalizeTheme: function (theme: Itheme) {
        // if the theme does not have an attribute, fall back to the default attribute
        const defaultTheme = this.selectThemeBySubdomain();
        Object.keys(defaultTheme).forEach((key) => {
            theme = { ...theme, [key]: theme[key as keyof Itheme] || defaultTheme[key as keyof Itheme] };
        });

        let logo = theme.logoSrc;
        if (theme.name === 'brainhive') {
            logo = brainhiveLogo;
        }
        if (theme.name === 'default') {
            logo = dibsLogo;
        }
        const warningButtonStyle = {
            'background-color': theme.warningBackground,
            'border-bottom-color': theme.warningDark,
            color: theme.warningFontColor,
            '&:active': {
                'background-color': theme.warningDark,
                'border-bottom-color': '#000'
            }
        };
        const primaryButtonStyle = {
            'background-color': theme.primaryBackground,
            'border-bottom-color': theme.primaryDark,
            color: theme.primaryFontColor,
            '&:active': {
                'background-color': theme.primaryDark,
                'border-bottom-color': '#000'
            }
        };
        const secondaryButtonStyle = {
            'background-color': theme.secondaryBackground,
            'border-bottom-color': theme.secondaryDark,
            color: theme.secondaryFontColor,
            '&:active': {
                'background-color': theme.secondaryDark,
                'border-bottom-color': '#000'
            }
        };
        const successButtonStyle = {
            'background-color': theme.successBackground,
            'border-bottom-color': theme.successDark,
            color: theme.successFontColor,
            '&:active': {
                'background-color': theme.successDark,
                'border-bottom-color': '#000'
            }
        };
        const primaryIconOnlyButtonStyle = {
            color: theme.primaryBackground,
            '&:active': {
                color: theme.primaryDark
            }
        };
        return {
            ...theme,
            logo,
            warningButtonStyle,
            primaryButtonStyle,
            secondaryButtonStyle,
            successButtonStyle,
            primaryIconOnlyButtonStyle
        };
    },
    setTheme: function (theme: Itheme) {
        // console.log('persisting theme', theme); // TODO why are we persisting so many times?
        this.activeTheme = this.normalizeTheme(theme);
        const manifestPlaceholder = document
        .querySelector('#my-manifest-placeholder');
        if (manifestPlaceholder){
            manifestPlaceholder.setAttribute('href', this.activeTheme.manifestUrl);
            pWaCompatUtil();
        }
        return BookFS.fs.setItem('activeTheme', theme);
    },
    checkUpdatedThemes: function () {
        const URL = `${config.API.Admin}/home/getviewerthemes`;
        var request = new Request(URL, { method: 'GET' });

        return fetch(request)
            .then((response) => {
                if (response.status !== 200) {
                    throw response;
                }
                return response.json();
            })
            .then((res) => {
                this.themesById = res.reduce((themes: any, t: any) => {
                    return {
                        ...themes,
                        [t.Name]: Object.keys(t).reduce(
                            (camelCaseKeys, rawKey) => {
                                return {
                                    ...camelCaseKeys,
                                    [camelCase(rawKey)]: t[rawKey]
                                };
                            },
                            {}
                        )
                    };
                }, {});
                // console.log('themes', JSON.stringify(this.themesById))
                return this.initTheme();
            })
            .catch((error) => {
                console.error('unable to check for updated theme', error);
            });
    }
};
