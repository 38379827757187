import * as React from 'react';

import { Button, Col, FormControl } from 'react-bootstrap';
import { StyledButton, StyledRow } from '../../constants/styledComponents';

import UserAPI from '../../api/userAPI';
import book from '../../images/book.png';
import constants from '../../constants/constants';
import graphic_organizers from '../../images/graphic_organizers.png';
import { toastr } from 'react-redux-toastr';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchTerm: '',
            filteredBooks: this.props.books
        };
        this.searchBooks = this.searchBooks.bind(this);
        this.toggleClass = 'fa fa-angle-double-up';
        this.theme = constants.themeProvider.activeTheme;
    }

    searchBooks(event) {
        let filteredBooks = this.props.books.filter((book) =>
            book.Title.toLowerCase().includes(event.target.value.toLowerCase())
        );
        if (!filteredBooks) {
            filteredBooks = [];
        }
        this.setState({
            search: event.target.value,
            filteredBooks
        });
    }

    render() {
        return (
            <div className="viewer-header">
                <StyledRow
                    className="page-header"
                    css={{
                        'border-bottom-color': this.theme.primaryBackground
                    }}
                >
                    <Col sm={9}>
                        {UserAPI.isStudent(this.props.user.RoleID) && (
                            <div>
                                <div className="book-blm-menu btn-group">
                                    <Button
                                        role="button"
                                        type="button"
                                        className="item dropdown-toggle"
                                        variant="default"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        <img
                                            alt=""
                                            src={book}
                                            data-pin-nopin="true"
                                        ></img>
                                        Book <span className="caret"></span>
                                    </Button>
                                    <ul className="dropdown-menu">
                                        <Col md={12} lg={12}>
                                            <FormControl
                                                type="text"
                                                placeholder="Search"
                                                value={this.state.search}
                                                onChange={this.searchBooks}
                                            />
                                        </Col>
                                        {this.props.books.length > 0 &&
                                            this.state.filteredBooks.map(
                                                (book) => {
                                                    return (
                                                        <div key={book.ID}>
                                                            <li>
                                                                <Button
                                                                    variant="link"
                                                                    role="button"
                                                                    type="button"
                                                                    onClick={() => {
                                                                        this.props.changeBook(
                                                                            book
                                                                        );
                                                                    }}
                                                                >
                                                                    {book.Title}
                                                                </Button>
                                                            </li>
                                                        </div>
                                                    );
                                                }
                                            )}
                                    </ul>
                                </div>
                                {
                                    // <Button className="item" variant="default" role="button">
                                    //   <img alt="" src={quizzes} data-pin-nopin="true"></img>
                                    //   <p>Quizzes</p>
                                    // </Button>
                                    // <Button className="item" variant="default" role="button">
                                    //   <img alt="" src={projects} data-pin-nopin="true"></img>
                                    //   <p>Projects</p>
                                    // </Button>
                                }
                                <span>
                                    <Button
                                        role="button"
                                        type="button"
                                        className="item dropdown-toggle"
                                        variant="default"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        <img
                                            alt=""
                                            src={graphic_organizers}
                                            data-pin-nopin="true"
                                        ></img>
                                        Activities{' '}
                                        <span className="caret"></span>
                                    </Button>
                                    <ul className="dropdown-menu blm">
                                        {this.props.blms.length > 0 &&
                                            this.props.blms.map((blm) => {
                                                return (
                                                    <li key={blm.ID}>
                                                        <Button
                                                            variant="link"
                                                            role="button"
                                                            type="button"
                                                            onClick={() => {
                                                                this.props.changeBlm(
                                                                    blm.projectAssignmentID,
                                                                    blm.ID
                                                                );
                                                            }}
                                                        >
                                                            {blm.FriendlyName ||
                                                                blm.Title}
                                                        </Button>
                                                    </li>
                                                );
                                            })}
                                    </ul>
                                </span>
                                {/* {this.props.isDownloaded === false && (
                                    <Button
                                        role="button"
                                        className="item"
                                        type="button"
                                        variant="default"
                                        onClick={() =>
                                            this.props.downloadBook(
                                                this.props.book
                                            )
                                        }
                                    >
                                        Download Book
                                    </Button>
                                )}
                                {this.props.isDownloaded === true && (
                                    <Button
                                        role="button"
                                        className="item"
                                        type="button"
                                        variant="default"
                                        onClick={
                                            this.props.deleteDownloadedBook
                                        }
                                    >
                                        Book Downloaded (Remove?)
                                    </Button>
                                )} */}
                            </div>
                        )}
                    </Col>
                    {/*          <div className="pages-visible-buttons">
              <Button className="item icon-btn" variant="default" role="button" onClick={() => this.props.updatePagesVisible(1)}>
                <i className="fa fa-file-o" aria-hidden="true"></i>
              </Button>
              <Button className="item icon-btn" variant="default" role="button" onClick={() => this.props.updatePagesVisible(2)}>
                <i className="fa fa-columns" aria-hidden="true"></i>
              </Button>
          </div>*/}
                </StyledRow>
                <div className="toggle">
                    <StyledButton
                        css={this.theme.primaryButtonStyle}
                        role="button"
                        type="button"
                        onClick={this.props.toggleHeader}
                    >
                        <i
                            className={this.props.toggle.class}
                            aria-hidden="true"
                        ></i>
                    </StyledButton>
                </div>
            </div>
        );
    }
}

export default Header;
