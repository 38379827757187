import * as React from 'react';
import allSquare from '../../images/NandF_Square.png';
import backpackImg from '../../images/backpack.png';
import bookshelfImg from '../../images/bookshelf.png';
import languageSquare from '../../images/Language_Square.png';
import mathSquare from '../../images/Math_Square.png';
import nonFictionSquare from '../../images/Nonfiction_Square.png';
import AdvancedSearchModal from './AdvancedSearchModal';
import scienceSquare from '../../images/Science_Square.png';
import socialStudiesSquare from '../../images/SocialStudies_Square.png';
import fictionSquare from '../../images/Fiction_Square.png';
import { FormGroup, Row, Col, FormControl, Button } from 'react-bootstrap';
import { StyledButton } from '../../constants/styledComponents';
import { addQuery, removeQuery } from '../../vendor/utils-router';
const FontAwesome = require('react-fontawesome');

import {
    checkForUpdatedBookFiles,
    getBookItemsList,
    initialDashboardQuery,
    searchBookBagBooks
} from '../../actions/bookActions';
import { clearAdvancedSearchFilter } from '../../actions/bookbagFiltersActions';

interface Iprops {
    query: typeof initialDashboardQuery;
    isOnline: boolean;
    bookbagFilters: {
        tags: any;
        spanishOnly: any;
        selectedReadingLevelTypeOption: any;
        readingMax: any;
        readingMin: any;
    };
    showSearchBar: boolean;
    theme: any;
    books: Array<any>;
    searchBookBagBooks: typeof searchBookBagBooks;
    isFiltersActive: boolean;
    user: any;
    downloadedBooks: Array<any>;
    checkForUpdatedBookFiles: typeof checkForUpdatedBookFiles;
    manualAjaxEnd: () => void;
    handleInvalidSession: () => void;
    clearAdvancedSearchFilter: typeof clearAdvancedSearchFilter;
    loading: boolean;
    isStudent: boolean;
    isGeneric: boolean;
    getBookItemsList: typeof getBookItemsList;
}

interface Istate {
    showAdvancedSearch: boolean;
}

class BookBagSearch extends React.Component<Iprops, Istate> {
    constructor(props: Iprops) {
        super(props);
        this.state = {
            showAdvancedSearch: false
        };
    }
    componentDidMount() {
        this.initializeComponent();
    }
    componentDidUpdate(prevProps: Iprops) {
        if (prevProps.isOnline !== this.props.isOnline && this.props.isOnline) {
            setTimeout(() => {
                this.updateBookItems();
            }, 300);
        }
    }
    /*
     * initial setup of the component
     * call getBookListItems to get the latest book items for DOWNLOADED books only
     * this.props.downloadedBooks, loop through array to get all bookIDs
     * Action: save the book contents to downloaded books
     * Potential Issue: Being offline, saving a book item, going online and going straight to book bag:
     * Solution: check to see if there are any ajaxCallsInProgress
     */
    initializeComponent = () => {
        if (!this.props.query.searchMode) {
            addQuery({ searchMode: 'local' });
        }
        this.updateBookItems();

        if (this.props.isGeneric) {
            addQuery({ searchMode: 'remote' });
            setTimeout(() => {
                this.props.searchBookBagBooks(
                    this.props.isFiltersActive,
                    this.props.query
                );
            }, 100);
        } else if (this.props.isStudent) {
            // if local then always load books.  If remote the only load books if there is a search
            if (
                !this.props.query.searchMode ||
                this.props.query.searchMode === 'local'
            ) {
                this.props.searchBookBagBooks(
                    this.props.isFiltersActive,
                    this.props.query
                );
            } else if (
                this.props.query.searchMode === 'remote' &&
                this.props.query.search.length > 0 &&
                this.props.books.length === 0
            ) {
                this.props.searchBookBagBooks(
                    this.props.isFiltersActive,
                    this.props.query
                );
            }
            if (this.props.isOnline) {
                console.log('****** we are online');
            } else {
                console.log('****** we are offline');
            }
        } else {
            // if you are not a student or a Generic user, they will be sent to the login screen via the auth check in the render function.
        }
        // TODO load all assigned blms and save this to redux
        // TODO start with including it into props
    };
    /*
     * If we are on a device and downloaded books, check to see if there are updated Book Items or updated Book files
     */
    updateBookItems = () => {
        console.log('about to update book items');
        if (this.props.isOnline && this.props.downloadedBooks.length > 0) {
            // Make call to grab all book items if ajax calls are not currently in progress
            if (!this.props.loading) {
                this.props.getBookItemsList(
                    this.props.downloadedBooks,
                    this.props.user
                );
            } else {
                //wait 5 seconds then try again
                setTimeout(() => {
                    this.updateBookItems();
                }, 5000);
            }
        }
    };

    isActiveAdvancedSearch = () => {
        const {
            tags,
            spanishOnly,
            selectedReadingLevelTypeOption,
            readingMax,
            readingMin
        } = this.props.bookbagFilters;
        if (
            (tags && tags.length) ||
            spanishOnly ||
            (selectedReadingLevelTypeOption &&
                selectedReadingLevelTypeOption.value !== 'grl') ||
            readingMax ||
            readingMin
        ) {
            return true;
        } else {
            return false;
        }
    };

    /*
     * Switch to Remote Books
     */
    switchToRemote = () => {
        // this.setState({ searchMode: 'remote' }, () => {
        //   // only load remote books if there is a search paramater
        //   // do we want to search if there is a tag, type, or reading level selected???  Lets stick with search for now
        //   // if (this.state.search.length > 0 || this.state.tag !== 'all-tags' || this.state.type !== 'all' || this.state.readingLeve.length > 0){
        // });
        addQuery({ searchMode: 'remote' });
        removeQuery('activePage');
    };

    /*
     * Switch to Local Books
     */
    switchToLocal = () => {
        addQuery({ searchMode: 'local' });
        removeQuery('activePage');
    };

    searchForBooks = (event: React.ChangeEvent<any>) => {
        if (event) {
            addQuery({ search: event.target.value });
        }
    };

    searchForReadingLevel = (event: React.ChangeEvent<any>) => {
        if (event) {
            addQuery({ grl: event.target.value });
        }
    };

    handleTagChange = (val: string) => {
        addQuery({ tag: val });
    };

    handleTypeChange = (val: string) => {
        addQuery({ type: val });
    };

    showAdvancedSearch = () => {
        this.setState({ showAdvancedSearch: true });
    };

    closeAdvancedSearch = () => {
        this.setState({ showAdvancedSearch: false });
    };
    clearAdvancedSearchForm = () => {
        removeQuery('search', 'type', 'tag', 'grl');
        addQuery({ activePage: 1 });
        this.props.clearAdvancedSearchFilter();
    };

    handleSubmit = (
        event: React.FormEvent<HTMLFormElement>,
        activePage: number
    ) => {
        if (event) {
            event.preventDefault();
        }
        console.log(`initiating a bookbag search: ${this.props.query.search}`);
        addQuery({ activePage });
        this.props.searchBookBagBooks(this.props.isFiltersActive, {
            ...this.props.query,
            activePage
        });
    };

    render() {
        let backpack = '';
        let bookshelf = '';
        let bookbagSearchMode = 'bookbag-searchmode';
        if (this.props.isGeneric) {
            bookbagSearchMode = 'd-none';
        }
        if (
            !this.props.query.searchMode ||
            this.props.query.searchMode === 'local'
        ) {
            backpack += 'backpack';
            bookshelf += '';
        } else {
            backpack += '';
            bookshelf += 'bookshelf';
        }

        let advancedSearchButton = this.isActiveAdvancedSearch()
            ? 'advanced-search-active'
            : 'advanced-search';
        // tags
        let languageBC =
            this.props.query.tag === 'languageArts' ? 'language-bc' : '';
        let socialStudiesBC =
            this.props.query.tag === 'socialStudies' ? 'social-science-bc' : '';
        let mathBC = this.props.query.tag === 'math' ? 'math-bc' : '';
        let scienceSearchBC =
            this.props.query.tag === 'science' ? 'science-bc' : '';
        let allTags = this.props.query.tag === 'allTags' ? 'all-tags' : '';
        // types
        let allBC = this.props.query.type === 'All' ? 'all-bc' : '';
        let fictionBC = this.props.query.type === 'Fiction' ? 'fiction-bc' : '';
        let nonFictionBC =
            this.props.query.type === 'Non-Fiction' ? 'non-fiction-bc' : '';

        return (
            <FormGroup className="search-input">
                {this.props.showSearchBar && this.props.isOnline && (
                    <div>
                        <form
                            onSubmit={(e) => this.handleSubmit(e, 1)}
                            className="dibs-form bookbag-search-form"
                        >
                            <Row>
                                <Col
                                    className={bookbagSearchMode}
                                    sm={2}
                                    md={2}
                                    lg={{ span: 2, offset: 1 }}
                                >
                                    <figure>
                                        <img
                                            className={backpack}
                                            onClick={() => {
                                                this.switchToLocal();
                                            }}
                                            src={backpackImg}
                                            alt="Book Bag"
                                        />
                                        <figcaption>Book Bag</figcaption>
                                    </figure>
                                    <figure>
                                        <img
                                            className={bookshelf}
                                            onClick={() => {
                                                this.switchToRemote();
                                            }}
                                            src={bookshelfImg}
                                            alt="Book Room"
                                        />
                                        <figcaption>Book Room</figcaption>
                                    </figure>
                                </Col>
                                <Col
                                    sm={3}
                                    md={4}
                                    lg={{
                                        span: 4,
                                        offset: this.props.isStudent ? 0 : 1
                                    }}
                                >
                                    <FormGroup>
                                        <FormControl
                                            type="search"
                                            placeholder="Search Title, ISBN, and TAGS/Keywords"
                                            value={this.props.query.search}
                                            onChange={this.searchForBooks}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={3} md={4} lg={3}>
                                    <FormGroup>
                                        <FormControl
                                            className="reading-level"
                                            type="text"
                                            placeholder="Reading Level (GRL, ATOS or Lex)"
                                            value={this.props.query.grl.toUpperCase()}
                                            onChange={
                                                this.searchForReadingLevel
                                            }
                                        />
                                    </FormGroup>
                                </Col>
                                <Col
                                    sm={1}
                                    md={1}
                                    lg={{
                                        span: 1,
                                        offset: this.props.isStudent ? 0 : 2
                                    }}
                                    className="text-right"
                                >
                                    <StyledButton
                                        className={'dashboard-go'}
                                        css={
                                            this.props.theme.primaryButtonStyle
                                        }
                                        role="button"
                                        type="submit"
                                    >
                                        {' '}
                                        Go{' '}
                                    </StyledButton>
                                </Col>
                            </Row>
                        </form>

                        {/* search icon buttons */}
                        <Row className="bookbag-search-images">
                            <Col sm={5} md={5} lg={{ span: 5, offset: 1 }}>
                                <figure>
                                    <img
                                        className={allTags}
                                        onClick={() => {
                                            this.handleTagChange('allTags');
                                        }}
                                        src={allSquare}
                                        alt=""
                                    />
                                    <figcaption>All</figcaption>
                                </figure>
                                <figure>
                                    <img
                                        className={socialStudiesBC}
                                        onClick={() => {
                                            this.handleTagChange(
                                                'socialStudies'
                                            );
                                        }}
                                        src={socialStudiesSquare}
                                        alt=""
                                        title="scienceSearch"
                                    />
                                    <figcaption>Social Studies</figcaption>
                                </figure>
                                <figure>
                                    <img
                                        className={scienceSearchBC}
                                        onClick={() => {
                                            this.handleTagChange('science');
                                        }}
                                        src={scienceSquare}
                                        alt=""
                                    />
                                    <figcaption>Science</figcaption>
                                </figure>
                                <figure>
                                    <img
                                        className={mathBC}
                                        onClick={() => {
                                            this.handleTagChange('math');
                                        }}
                                        src={mathSquare}
                                        alt=""
                                    />
                                    <figcaption>Math</figcaption>
                                </figure>
                                <figure>
                                    <img
                                        className={languageBC}
                                        onClick={() => {
                                            this.handleTagChange(
                                                'languageArts'
                                            );
                                        }}
                                        src={languageSquare}
                                        alt=""
                                    />
                                    <figcaption>Language</figcaption>
                                </figure>
                            </Col>
                            <Col sm={3} md={3}>
                                <figure>
                                    <img
                                        className={allBC}
                                        onClick={() => {
                                            this.handleTypeChange('All');
                                        }}
                                        src={allSquare}
                                        alt=""
                                    />
                                    <figcaption>All</figcaption>
                                </figure>
                                <figure>
                                    <img
                                        className={fictionBC}
                                        onClick={() => {
                                            this.handleTypeChange('Fiction');
                                        }}
                                        src={fictionSquare}
                                        alt=""
                                    />
                                    <figcaption>Fiction</figcaption>
                                </figure>
                                <figure>
                                    <img
                                        className={nonFictionBC}
                                        onClick={() => {
                                            this.handleTypeChange(
                                                'Non-Fiction'
                                            );
                                        }}
                                        src={nonFictionSquare}
                                        alt=""
                                    />
                                    <figcaption>Non-Fiction</figcaption>
                                </figure>
                            </Col>
                            <Col
                                sm={2}
                                md={2}
                                className="advanced-search-buttons"
                            >
                                <Button
                                    className={advancedSearchButton}
                                    variant="link"
                                    onClick={() => this.showAdvancedSearch()}
                                >
                                    <FontAwesome name="search" /> Advanced
                                    Search
                                </Button>
                                <Button
                                    className="clear-advanced-search"
                                    variant="link"
                                    onClick={() =>
                                        this.clearAdvancedSearchForm()
                                    }
                                >
                                    <FontAwesome name="times" /> Clear Search
                                </Button>
                            </Col>
                        </Row>
                        <AdvancedSearchModal
                            show={this.state.showAdvancedSearch}
                            cancel={this.closeAdvancedSearch}
                        />
                    </div>
                )}
            </FormGroup>
        );
    }
}

export default BookBagSearch;
