import config from './config';
import { forEach } from 'lodash';
import JSZip from 'jszip';
import BookFS from './bookFS';
import {
    addTappableClassOnly,
    addSpeechMarkTimesAndTappableClass
} from '../utilities/TextToSpeechUtility';
import UserAPI from './userAPI';

export default class BookAPI {
    /* get the json properties for a particular book from blob storage */
    static getBookProperties(book, token) {
        const remoteURL = `${config.API.Storage}${book.EBookPath}${book.ISBN}/properties.json${token}`;
        var request = new Request(remoteURL, { method: 'GET' });

        return fetch(request).then((response) => {
            if (response.status !== 200) {
                throw response;
            }
            return response.json();
        });
    }

    /*
     * Get the speechmarks
     */
    static getSpeechMarksRemote(book, index, token, voice = 'default') {
        if (!book.EBookPath || !book.ISBN || !token) {
            throw new Error(
                `missing book info path: ${book.EBookPath} ISBN: ${book.ISBN} token: ${token}`
            );
        }
        const URL = `${config.API.Storage}${book.EBookPath}${book.ISBN}/audio/${voice}/${index}.marks${token}`;
        // return Promise.resolve(constants.testSpeechMarkRaw); // test speech marks
        var request = new Request(URL, { method: 'GET' });
        return fetch(request).then((response) => {
            if (response.status !== 200) {
                return Promise.resolve([]);
            }
            return response.text();
        });
    }

    /* get and process data for a particular book page from file storage (type blob) */
    static getBookPageLocal(book, index, pageKey) {
        console.log('getting book page local');
        const htmURL = `${index}.html`;
        const svgURL = `${index}/${index}.svg`;

        // fetch the html for the page
        const promise1 = BookFS.getBookFileEntry(book.ISBN, htmURL);

        // fetch the svg data for the page
        const promise2 = BookFS.getBookFileEntry(book.ISBN, svgURL);

        return Promise.all([promise1, promise2]).then((values) => {
            // return values;
            let pageData = values[0]; // html page data
            let svgData = values[1]; // temporarily set this to an array with the SVG file and the URL to the file
            let fontPath = '';
            if (svgData === 404) {
                //not found
                svgData = '';
            } else {
                // const childImageUrl = svgData.search()
                // return BookFS.getBookFileUrl(book.ISBN, )
                const svgPromises = [];
                const fontPromises = [];
                // let rootPath = svgData[1]; // URL to the file
                // rootPath = rootPath.substring(0, rootPath.lastIndexOf('/')); // we are only replacing the rootPath, not the file name or the extension
                // fontPath = rootPath.substring(0, rootPath.lastIndexOf('/')); // the root directory of this book
                // svgData = svgData[0];
                // replace all relative image urls with full url
                svgData.replace(
                    /((\w*)\/[0-9]*.(png|jpg))+/g,
                    (originalPath) => {
                        svgPromises.push(
                            BookFS.getBookFileUrl(
                                book.ISBN,
                                `${index}/${originalPath}`
                            ).then((newPath) => {
                                svgData = svgData.replace(
                                    originalPath,
                                    newPath
                                );
                            })
                        );
                    }
                );

                pageData.replace(
                    /(fonts\/[a-zA-Z0-9\-_]*.\w*)/g,
                    (originalPath) => {
                        fontPromises.push(
                            BookFS.getBookFileUrl(
                                book.ISBN,
                                `${originalPath}`
                            ).then((newPath) => {
                                pageData = pageData.replace(
                                    originalPath,
                                    newPath
                                );
                            })
                        );
                    }
                );

                return Promise.all([...svgPromises, ...fontPromises]).then(
                    (childValues) => {
                        svgData = svgData.replace(
                            '<?xml version="1.0" encoding="UTF-8" standalone="no"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">',
                            ''
                        );
                        // now replace the entire svg <object> in the html with the svg element
                        pageData = pageData.replace(
                            /(<object[^<]*<\/object>)+/,
                            svgData
                        );

                        // if marks are enabled and available, the speechMarks array for this page will have length
                        if (
                            book.cachedPages[pageKey] &&
                            book.cachedPages[pageKey].speechMarks.length > 0
                        ) {
                            pageData = addSpeechMarkTimesAndTappableClass(
                                book,
                                pageData,
                                index
                            );
                        } else {
                            pageData = addTappableClassOnly(pageData);
                        }
                        return pageData;
                    }
                );
            }
        });
    }

    /* get and process data for a particular book page from blob storage */
    static getBookPageRemote(book, index, token, pageKey) {
        if (!book.EBookPath || !book.ISBN || !token) {
            throw new Error(
                `missing book info path: ${book.EBookPath} ISBN: ${book.ISBN} token: ${token}`
            );
        }

        const root = `${config.API.Storage}${book.EBookPath}${book.ISBN}/`;
        const htmURL = `${root}${index}.html${token}`;
        const svgURL = `${root}${index}/${index}.svg${token}`;

        // fetch the html for the page
        const p1 = fetch(htmURL).then((response) => {
            if (response.status !== 200) {
                throw response;
            }
            return response.text();
        });
        // fetch the svg data for the page
        const p2 = fetch(svgURL)
            .then((response) => {
                if (response.status !== 200) {
                    throw response;
                }
                return response.text();
            })
            // catch any errors with svg fetch so we can
            // inspect and not autofail the whole request.
            .catch((err) => {
                if (err.status === 404) {
                    // then this is a benign error - the svg is simply not there as expected
                    return err.status;
                } else {
                    throw err;
                }
            });
        return Promise.all([p1, p2]).then((values) => {
            // return values;
            let pageData = values[0];
            let svgData = values[1];
            if (svgData === 404) {
                //not found
                svgData = '';
            } else {
                // replace all relative image urls with full url
                svgData = svgData.replace(
                    /((\w*)\/[0-9]*.(png|jpg))+/g,
                    `${root}${index}/$1${token}`
                );
                // remove extra crap we do not need for an embedded svg
                svgData = svgData.replace(
                    '<?xml version="1.0" encoding="UTF-8" standalone="no"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">',
                    ''
                );
            }
            // now replace the entire svg <object> in the html with the svg element
            pageData = pageData.replace(/(<object[^<]*<\/object>)+/, svgData);
            // replace fonts path
            pageData = pageData.replace(
                /(fonts\/[a-zA-Z0-9\-_]*.\w*)/g,
                `${root}$1${token}`
            );

            // if marks are enabled and available, the speechMarks array for this page will have length
            if (
                book.cachedPages[pageKey] &&
                book.cachedPages[pageKey].speechMarks.length > 0
            ) {
                pageData = addSpeechMarkTimesAndTappableClass(
                    book,
                    pageData,
                    index
                );
            } else {
                pageData = addTappableClassOnly(pageData);
            }
            return pageData;
        });
    }

    /* get the zipped up book from blob storage */
    static downloadBook(book, token) {
        const URL = `${config.API.Storage}${book.EBookPath}${book.ISBN}/${book.ISBN}.zip${token}`;
        const request = new Request(URL, { method: 'GET' });

        return (
            fetch(request)
                .then((response) => {
                    console.log('downloaded book zip');
                    if (response.status !== 200) {
                        throw response;
                    }
                    // decode the base64 encoded response
                    return response.blob();
                })
                // unzip the zip
                .then(JSZip.loadAsync)
                .then(function (zip) {
                    console.log('unzipped book zip');
                    // save the unziped files to the file system
                    return BookFS.saveBook(book.ISBN, zip);
                })
        );
    }

    /*
     * Get the zipped up speech files
     */
    static downloadSpeech(book, token) {
        const URL = `${config.API.Storage}${book.EBookPath}${book.ISBN}/audio/default/${book.ISBN}_audio.zip${token}`;
        const request = new Request(URL, { method: 'GET' });

        return (
            fetch(request)
                .then((response) => {
                    if (response.status !== 200) {
                        throw response;
                    }
                    // decode the base64 encoded response
                    return response.blob();
                })
                // unzip the zip
                .then(JSZip.loadAsync)
                .then(function (zip) {
                    console.log('unzipped speech zip');
                    // save the unziped files to the file system
                    return BookFS.saveSpeech(book.ISBN, zip);
                })
        );
    }

    static getBookItemsList(downloadedBookIDs, user) {
        let URL = `${config.API.Main}/viewer/getbookitemlist`;

        forEach(downloadedBookIDs, (bookID, index) => {
            if (index === 0) {
                URL += `?bookIDs=${bookID}`;
            } else {
                URL += `&bookIDs=${bookID}`;
            }
        });

        var request = new Request(URL, {
            method: 'GET',
            headers: UserAPI.getHeaders(user)
        });

        return fetch(request).then((response) => {
            if (response.status !== 200) {
                throw response;
            }
            return response.json();
        });
    }

    /* Get a list of books the current user has access to*/
    static getBooks(
        activePage,
        search,
        type,
        tag,
        grl,
        user,
        advancedSearchFilters
    ) {
        if (type === 'All') {
            type = '';
        }
        const {
            selectedReadingLevelTypeOption = null,
            readingMin = '',
            readingMax = '',
            spanishOnly = ''
        } = advancedSearchFilters;
        const rltype = selectedReadingLevelTypeOption
            ? selectedReadingLevelTypeOption.value
            : '';
        let URL = `${
            config.API.Main
        }/student/getbooks?page=${activePage}&search=${search}&grl=${grl}
    &type=${type}&rltype=${rltype || ''}&rlmin=${readingMin || ''}&rlmax=${
            readingMax || ''
        }&spanishonly=${spanishOnly}`;

        if (tag !== undefined && tag !== '' && tag !== 'allTags') {
            URL += `&tags=${config.typeGUID[tag]}`;
        } else {
            URL += `&tags=`;
        }

        if (
            advancedSearchFilters.tags &&
            advancedSearchFilters.tags.length > 0
        ) {
            forEach(advancedSearchFilters.tags, (value, key) => {
                URL += `&tags=${value.TagID}`;
            });
        }
        var request = new Request(URL, {
            method: 'GET',
            headers: UserAPI.getHeaders(user)
        });

        return fetch(request).then((response) => {
            if (response.status !== 200) {
                throw response;
            }
            return response.json();
        });
    }

    static addBook(bookID, user) {
        const URL = `${config.API.Main}/student/addtobookbag?bookid=${bookID}`;
        var request = new Request(URL, {
            method: 'POST',
            headers: UserAPI.getHeaders(user)
        });
        return fetch(request).then((response) => {
            if (response.status !== 200) {
                throw response;
            }
            return response.json();
        });
    }
    static removeBook(bookID, user) {
        const URL = `${config.API.Main}/student/removefrombookbag?bookid=${bookID}`;
        var request = new Request(URL, {
            method: 'POST',
            headers: UserAPI.getHeaders(user)
        });

        return fetch(request).then((response) => {
            if (response.status !== 200) {
                throw response;
            }
            return response.json();
        });
    }

    static getBookByID(bookID, user) {
        const URL = `${config.API.Main}/book/getbookbyid?bookid=${bookID}`;
        var request = new Request(URL, {
            method: 'GET',
            headers: UserAPI.getHeaders(user)
        });

        console.log('gettingbook', user, request);
        return fetch(request).then((response) => {
            if (response.status !== 200) {
                throw response;
            }
            return response.json();
        });
    }

    static searchBooks(
        page,
        search,
        type,
        tag,
        grl,
        user,
        advancedSearchFilters
    ) {
        if (type === 'All') {
            type = '';
        }
        const {
            selectedReadingLevelTypeOption = null,
            readingMin = '',
            readingMax = '',
            spanishOnly = ''
        } = advancedSearchFilters;
        const rltype = selectedReadingLevelTypeOption
            ? selectedReadingLevelTypeOption.value
            : '';
        let URL = `${
            config.API.Main
        }/book/ebooksearch?page=${page}&search=${search}&type=${type}&grl=${grl}
    &rltype=${rltype || ''}&rlmin=${readingMin || ''}&rlmax=${
            readingMax || ''
        }&spanishonly=${spanishOnly}`;
        // grabbing the tag name and assigning it as the key pair to grab the value in config
        if (tag !== undefined && tag !== '' && tag !== 'allTags') {
            URL += `&tags=${config.typeGUID[tag]}`;
        } else {
            URL += `&tags=`;
        }

        if (
            advancedSearchFilters.tags &&
            advancedSearchFilters.tags.length > 0
        ) {
            forEach(advancedSearchFilters.tags, (value, key) => {
                URL += `&tags=${value.TagID}`;
            });
        }

        var request = new Request(URL, {
            method: 'GET',
            headers: UserAPI.getHeadersUrlEncode(user)
        });
        console.log('before fetch');
        return fetch(request).then((response) => {
            if (response.status !== 200) {
                throw response;
            }
            return response.json();
        });
    }

    /*
    save bookitem
  */
    static saveBookItem(bookItem, user) {
        const URL = `${config.API.Main}/viewer/savebookitem`;
        const body = JSON.stringify({
            ...bookItem,
            Content: JSON.stringify(bookItem.Content)
        });
        var request = new Request(URL, {
            method: 'POST',
            headers: UserAPI.getHeaders(user),
            body
        });

        return fetch(request).then((response) => {
            if (response.status !== 200) {
                throw response;
            }
            return response.json();
        });
    }
    /*
    save bookitem offline
  */

    /*
    delete bookitem
  */
    static deleteBookItem(bookItem, user) {
        const URL = `${config.API.Main}/viewer/deletebookitem`;
        let body = `ID=${bookItem.ID}`;
        var request = new Request(URL, {
            method: 'POST',
            headers: UserAPI.getHeaders(user),
            body: body
        });

        return fetch(request).then((response) => {
            if (response.status !== 200) {
                throw response;
            }
            return response.json();
        });
    }

    /*
    get book items
  */
    static getBookItems(bookId, user) {
        const URL = `${config.API.Main}/viewer/getbookitems?bookID=${bookId}`;
        var request = new Request(URL, {
            method: 'GET',
            headers: UserAPI.getHeaders(user)
        });

        return fetch(request).then((response) => {
            if (response.status !== 200) {
                throw response;
            }
            return response.json();
        });
    }

    static getTeacherBookItems(data, user) {
        let passCorrectID;
        if (data.groupID) {
            passCorrectID = `&groupID=${data.groupID}`;
        } else if (data.classID) {
            passCorrectID = `&classID=${data.classID}`;
        } else {
            passCorrectID = `&studentID=${data.studentID}`;
        }

        const URL = `${config.API.Main}/viewer/getteacherbookitems?bookID=${data.book}${passCorrectID}`;
        var request = new Request(URL, {
            method: 'GET',
            headers: UserAPI.getHeaders(user)
        });

        return fetch(request).then((response) => {
            if (response.status !== 200) {
                throw response;
            }
            return response.json();
        });
    }

    /*
    get book items by student id (used by teacher token login)
  */
    static getBookItemsByStudentID(BookId, StudentId, user) {
        const URL = `${config.API.Main}/viewer/getstudentbookitems?bookID=${BookId}&studentID=${StudentId}`;
        var request = new Request(URL, {
            method: 'GET',
            headers: UserAPI.getHeaders(user)
        });

        return fetch(request).then((response) => {
            if (response.status !== 200) {
                throw response;
            }
            return response.json();
        });
    }
}
