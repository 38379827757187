import { map, pickBy, find } from 'lodash';
import * as types from '../actions/actionTypes';
import initialState from './initialState';

const normalizeBook = (rawBook) => {
    return {
        ...initialState.book,
        ...pickBy(rawBook, (property) => property !== null)
    };
};

/*
 * Book Reducer
 */
export function bookReducer(state = initialState.book, action) {
    switch (action.type) {
        case types.FETCH_BOOK_SUCCESS:
            return normalizeBook(action.book);
        case types.FETCH_BOOK_FAILED: {
            return initialState.book;
        }
        case types.LOAD_BOOKS_SUCCESS:{
            const foundBook = find(action.books, {ID: state.ID})
            if (foundBook){
                return {...state, ...normalizeBook(foundBook)}
            }
            return state;
        }

        case types.LOAD_BOOK_SUCCESS:
            return Object.assign({}, state, action.book);

        case types.FETCH_BOOKITEMS_STUDENT_SUCCESS:
            // when connecting back online, dispatch actions might have been triggered for a different book
            if (state.ID !== action.bookID) {
                return state;
            }
            return Object.assign({}, state, {
                studentBookmarks: action.bookmarks,
                studentHighlights: action.highlights,
                studentNotes: action.notes
            });

        case types.FETCH_BOOKITEMS_SUCCESS:
            // when connecting back online, dispatch actions might have been triggered for a different book
            if (state.ID !== action.bookID) {
                return state;
            }
            return Object.assign({}, state, {
                bookmarks: action.bookmarks,
                highlights: action.highlights,
                notes: action.notes
            });

        case types.SAVE_BOOKITEM_BOOKMARK_SUCCESS:
            // when connecting back online, dispatch actions might have been triggered for a different book
            if (state.ID !== action.bookID) {
                return state;
            }
            let bookmarks = [];
            if (!state.bookmarks) {
                Object.assign({}, state, {
                    bookmarks: bookmarks
                });
            }
            bookmarks = [
                ...state.bookmarks.filter(
                    (bookmark) => bookmark.ID !== action.bookmark.ID
                ),
                Object.assign({}, action.bookmark)
            ];
            return Object.assign({}, state, {
                bookmarks: bookmarks
            });

        case types.SAVE_BOOKITEM_NOTE_SUCCESS:
            // when connecting back online, dispatch actions might have been triggered for a different book
            if (state.ID !== action.bookID) {
                return state;
            }
            let notes = [];
            if (!state.notes) {
                Object.assign({}, state, {
                    notes: notes
                });
            }

            if (action.note.ID === undefined) {
                // this is for non-students who only save these notes locally - thus they never get a real ID
                notes = [
                    ...state.notes.filter(
                        (note) => note.TempID !== action.note.TempID
                    ),
                    Object.assign({}, action.note)
                ];
            } else {
                notes = [
                    ...state.notes.filter((note) => note.ID !== action.note.ID),
                    Object.assign({}, action.note)
                ];
            }
            notes.sort((a, b) => {
                return a.Page > b.Page;
            });
            return Object.assign({}, state, {
                notes: notes
            });

        case types.SAVE_BOOKITEM_HIGHLIGHT_SUCCESS:
            // when connecting back online, dispatch actions might have been triggered for a different book
            if (state.ID !== action.bookID) {
                return state;
            }
            return Object.assign({}, state, {
                highlights: action.highlights
            });

        case types.DELETE_BOOKITEM_BOOKMARK_SUCCESS:
            // when connecting back online, dispatch actions might have been triggered for a different book
            if (state.ID !== action.bookID) {
                return state;
            }
            return Object.assign({}, state, {
                bookmarks: state.bookmarks.filter(
                    (bookmark) => bookmark.ID !== action.bookmark.ID
                )
            });

        case types.DELETE_BOOKITEM_NOTE_SUCCESS:
            // when connecting back online, dispatch actions might have been triggered for a different book
            if (state.ID !== action.bookID) {
                return state;
            }

            return Object.assign({}, state, {
                notes: state.notes.filter((note) => note.ID !== action.note.ID)
            });
        // TODO do we need to support teachers deleting student notes?
        // case types.TEACHER_DELETE_BOOKITEM_NOTE_SUCCESS:
        //   if (state.ID !== action.bookID){
        //     return state;
        //   }
        //   return Object.assign({}, state, {
        //     'studentNotes': state.studentNotes.filter((note) => note.ID !== action.note.ID)
        //   });

        // case types.UPDATE_BOOKSTATUS_SUCCESS:
        //   if (state.ID !== action.bookID) {
        //     return state;
        //   } else {
        //     // we send 0 when initially opening the book and -1 when closing it.  Don't actually set this to the current page
        //     if (action.pageIndex === 0 || action.pageIndex === -1){
        //       action.pageIndex = state.currentPage ? state.currentPage : 0;
        //     }
        //     const book = Object.assign({}, state, { currentPage: action.pageIndex });
        //     return Object.assign({}, state, book);
        //   }

        case types.PURGE_TEMP_ID_NOTES:
            // when connecting back online, dispatch actions might have been triggered for a different book
            if (state.ID !== action.bookID) {
                return state;
            }
            return Object.assign({}, state, {
                notes: state.notes.filter((note) => !note.TempID)
            });

        case types.CACHE_BOOK_PAGE:
            // if (!state.cachedPages[action.page.pageKey].pageKey){
            const newCachedPage = Object.assign(
                {},
                state.cachedPages[action.page.pageKey],
                { ...action.page, pageReady: true }
            );
            return Object.assign({}, state, {
                cachedPages: {
                    ...state.cachedPages,
                    [action.page.pageKey]: newCachedPage
                }
            });
        // } else {
        // return state;
        // }
        case types.RESET_CACHED_BOOK_PAGES:
            return { ...state, cachedPages: {} };
        case types.NEXT_PAGE:
            return { ...state, currentPage: state.currentPage + 1 };
        case types.PREV_PAGE:
            return { ...state, currentPage: state.currentPage - 1 };
        case types.UPDATE_CURRENT_PAGE:
            return { ...state, currentPage: action.currentPage };
        case types.SET_BOOK_READY:
            return { ...state, bookIsReady: action.ready };
        case types.TOTAL_PAGE:
            return { ...state, pagecount: action.totalPage };
        case types.GET_SPEECH_MARKS:
            if (!state.cachedPages[action.pageKey]) {
                const newPage = Object.assign(
                    {},
                    { speechMarks: action.parsedMarks, pageReady: false }
                );
                const newCachedPages = {
                    ...state.cachedPages,
                    [action.pageKey]: newPage
                };
                return Object.assign({}, state, {
                    cachedPages: newCachedPages
                });
            } else {
                return state;
            }
        case types.USER_LOGOUT_SUCCESS:
            return initialState.book;
        case types.RESET_BOOK:
            return initialState.book;

        default:
            return state;
    }
}
/*
 * Books Reducer
 */
export function booksReducer(state = initialState.books, action) {
    switch (action.type) {
        case types.LOAD_BOOKS_SUCCESS:
            return map(action.books, normalizeBook);
        case types.ADD_BOOK_TO_BOOKBAG_SUCCESS:
            // return [...state, normalizeBook(action.book)]; // don't do anything because the user will switch back and hit search again
        case types.REMOVE_BOOK_TO_BOOKBAG_SUCCESS:
            return state.filter((book) => book.ID !== action.bookID);
        case types.UPDATE_CURRENT_PAGE:
            let newBook = state.filter((b) => {
                return b.ID === action.bookID;
            })[0];
            if (!newBook) {
                return state;
            }
            return [
                ...state.filter((b) => {
                    return b.ID !== action.bookID;
                }),
                Object.assign({}, newBook, { currentPage: action.currentPage })
            ];

        case types.USER_LOGOUT_SUCCESS:
            return initialState.books;

        default:
            return state;
    }
}

/*
 * Downloaded Books Reducer
 */
export function downloadedBooksReducer(
    state = initialState.downloadedBooks,
    action
) {
    switch (action.type) {
        case types.DOWNLOADED_BOOK_SUCCESS:
            // filter the array for this book already on the array, it it is not there then add it
            console.error('why is it downloading')
            return [
                ...state.filter((db) => {
                    return db.ID !== action.book.ID;
                }),
                Object.assign({}, normalizeBook(action.book))
            ];
        case types.LOAD_BOOKS_SUCCESS:{
            const newBooks = map(action.books, normalizeBook);
            return map(state, (book)=>{
                const foundNewBook = find(newBooks, {ID: book.ID});
                if (foundNewBook){
                    return {...book, ...foundNewBook}
                } else {
                    return book;
                }
            })
        }
        case types.DELETE_DOWNLOADED_BOOK: {
            return [...state.filter((db) => db.ISBN !== action.payload)];
        }

        case types.FETCH_BOOKITEMS_SUCCESS:
            let downloadedBook = state.filter((b) => {
                return b.ID === action.bookID;
            })[0];
            if (!downloadedBook) {
                return state;
            }
            downloadedBook = Object.assign({}, downloadedBook, {
                bookmarks: action.bookmarks,
                highlights: action.highlights,
                notes: action.notes
            });
            if (!downloadedBook.ID) {
                console.error(
                    'Error: undefined book ID in saving bookitem highlight.'
                );
                return state;
            }
            return [
                ...state.filter((b) => {
                    return b.ID !== action.bookID;
                }),
                downloadedBook
            ];

        case types.SAVE_BOOKITEM_HIGHLIGHT_SUCCESS:
            let book = state.filter((b) => {
                return b.ID === action.bookID;
            })[0];
            if (!book) {
                return state;
            }
            book = Object.assign({}, book, { highlights: action.highlights });
            if (!book.ID) {
                console.error(
                    'Error: undefined book ID in saving bookitem highlight.'
                );
                return state;
            }

            // check to see if state has any length, if not return

            return [
                ...state.filter((b) => {
                    return b.ID !== action.bookID;
                }),
                book
            ];

        case types.SAVE_BOOKITEM_NOTE_SUCCESS:
            let bookNote = state.filter((b) => {
                return b.ID === action.bookID;
            })[0];
            // console.log('book note', bookNote, action.bookID, state)
            if (!bookNote || (bookNote && !bookNote.notes)) {
                return state;
            }
            let notes = [];
            if (action.note.ID === undefined) {
                // this is for non-students who only save these notes locally - thus they never get a real ID
                notes = [
                    ...bookNote.notes.filter(
                        (note) => note.TempID !== action.note.TempID
                    ),
                    Object.assign({}, action.note)
                ];
            } else {
                notes = [
                    ...bookNote.notes.filter(
                        (note) => note.ID !== action.note.ID
                    ),
                    Object.assign({}, action.note)
                ];
            }

            notes.sort((a, b) => {
                return a.Page > b.Page;
            });
            bookNote = Object.assign({}, bookNote, { notes: notes });

            return [
                ...state.filter((b) => {
                    return b.ID !== action.bookID;
                }),
                bookNote
            ];

        case types.UPDATE_CURRENT_PAGE:
            let newBook = state.filter((b) => {
                return b.ID === action.bookID;
            })[0];
            if (!newBook) {
                return state;
            }
            return [
                ...state.filter((b) => {
                    return b.ID !== action.bookID;
                }),
                Object.assign({}, newBook, { currentPage: action.currentPage })
            ];

        case types.DELETE_BOOKITEM_BOOKMARK_SUCCESS:
            // dlBookBookmark is a downloaded book object and in this use case, we are deleting a bookmark
            let dlBookBookmark = state.filter((b) => {
                return b.ID === action.bookID;
            })[0];
            if (!dlBookBookmark) {
                return state;
            }
            Object.assign({}, dlBookBookmark, {
                bookmarks: dlBookBookmark.bookmarks.filter(
                    (bookmark) => bookmark.ID !== action.bookmark.ID
                )
            });
            if (!dlBookBookmark.ID) {
                console.error(
                    'Error: undefined book ID in saving bookitem highlight.'
                );
                return state;
            }
            // if something unexpected happens return state
            return state;

        case types.DELETE_BOOKITEM_NOTE_SUCCESS:
            // dlBookNote is a downloaded book object and in this use case, we are deleting the note
            let dlBookNote = state.filter((b) => {
                return b.ID === action.bookID;
            })[0];
            if (!dlBookNote) {
                return state;
            }

            if (action.note.ID === undefined) {
                // this is for non-students who only save these notes locally - thus they never get a real ID
                notes = [
                    ...dlBookNote.notes.filter(
                        (note) => note.TempID !== action.note.TempID
                    ),
                    Object.assign({}, action.note)
                ];
            } else {
                notes = [
                    ...dlBookNote.notes.filter(
                        (note) => note.ID !== action.note.ID
                    ),
                    Object.assign({}, action.note)
                ];
            }
            if (!dlBookNote.ID) {
                console.error(
                    'Error: undefined book ID in saving bookitem highlight.'
                );
                return state;
            }
            // if something unexpected happens, return state
            return state;

        case types.PURGE_TEMP_ID_NOTES:
            // dlPurgeTempNotes is a downloaded book object and in this use case, we are purging any temp ID's
            let dlPurgeTempNotes = state.filter((b) => {
                return b.ID === action.bookID;
            })[0];
            if (!dlPurgeTempNotes) {
                return state;
            }
            dlPurgeTempNotes = Object.assign({}, dlPurgeTempNotes, {
                notes: dlPurgeTempNotes.notes.filter((note) => !note.TempID)
            });

            return [
                ...state.filter((b) => {
                    return b.ID !== action.bookID;
                }),
                dlPurgeTempNotes
            ];

        case types.DELETE_BOOKITEM_HIGHLIGHT_SUCCESS:
            // dlBookHighlight is a downloaded book object and in this use case, we are deleting the highlight
            let dlBookHighlight = state.filter((b) => {
                return b.ID === action.bookID;
            })[0];
            if (!dlBookHighlight) {
                return state;
            }
            return Object.assign({}, dlBookHighlight, {
                highlights: dlBookHighlight.highlights
            });

        case types.USER_LOGOUT_SUCCESS:
            return initialState.downloadedBooks;

        default:
            return state;
    }
}

export function dashboardPageResultsReducer(state = 1, action) {
    switch (action.type) {
        case types.DASHBOARD_PAGE_RESULTS:
            if (action.pageResults && action.pageResults > 0) {
                return action.pageResults;
            }
            return state;
        case types.USER_LOGOUT_SUCCESS:
            return 1;
        default:
            return state;
    }
}
