const initialState = {
    completedBLMs: {},
    user: {
        ID: ''
    },
    blm: {
        projectIsReady: false
    },
    blms: [],
    books: [],
    bookbagFilters: {
        tags: [],
        selectedReadingLevelTypeOption: { value: 'grl', label: 'GRL' },
        readingMin: null,
        readingMax: null,
        spanishOnly: false
    },
    downloadedBooks: [],
    book: {
        cachedPages: {},
        currentPage: 1,
        LeftPageEven: false,
        bookIsReady: false,
        speechMarks: {}, // object of per page speechMarks arrays
        ISBN: '',
        EBookPath: '',
        bookmarks: [],
        highlights: {},
        notes: [],
        pagecount: 1,
    },
    routing: [],
    viewerMode: {
        mode: 'normal',
        data: {}
    },
    bookView: {
        pageIndex: 1,
        bookScalePercent: 1,
        projectScalePercent: 1,
        leftPageContainerWidth: 0,
        leftPageContainerHeight: 0,
        pagesVisible: 2,
        automaticUpdatedPagesVisible: false,
        activeNoteID: '',
        bookManualZooming: false,
        bookManualZoomIndex: 2,
        bookManualZoomLevel: 1,
        itemSavePending: false
    },
    dashboard: {
        showUserProfileModal: false,
        showLoggoutConfirm: false,
        showClassCodeModal: false
    },
    projectToolbar: {
        erasing: false,
        startText: false,
        drawingMode: false,
        pointer: false,
        showRichTextButtons: false,
        drawingColor: '#ff8f02'
    },
    bookToolbar: {
        erasing: false,
        allowNotes: false,
        highlighting: false,
        highlightColor: 'hl-yellow',
        underlining: false,
        striking: false,
        pointing: true,
        pagesClassName: 'pointer-mode'
    },
    dashboardPageResults: 1,
    ajaxCallsInProgress: 0,
    readium: {
        readiumClass: null,
    },
};

export default initialState;