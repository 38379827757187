export const BEGIN_AJAX_CALL = 'BEGIN_AJAX_CALL';
export const END_AJAX_CALL = 'END_AJAX_CALL';

export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAILED = 'USER_UPDATE_FAILED';
export const USER_UPDATE_PASSWORD_SUCCESS = 'USER_UPDATE_PASSWORD_SUCCESS';
export const USER_UPDATE_PASSWORD_FAILED = 'USER_UPDATE_PASSWORD_FAILED';
export const USER_ADD_CLASS_CODE_SUCCESS = 'USER_ADD_CLASS_CODE_SUCCESS';
export const USER_ADD_CLASS_CODE_FAILED = 'USER_ADD_CLASS_CODE_FAILED';
export const TOGGLE_CLASS_CODE_MODAL = 'TOGGLE_ADD_CLASS_CODE_MODAL';

export const HIGHLIGHTS_UPDATE_SUCCESS = 'HIGHLIGHTS_UPDATE'; //_SUCCESS

export const LOAD_BOOKS_SUCCESS = 'LOAD_BOOKS_SUCCESS';
export const LOAD_BOOKS_FAILED = 'LOAD_BOOKS_FAILED';

export const DASHBOARD_PAGE_RESULTS = 'DASHBOARD_PAGE_RESULTS';

export const FETCH_BOOK_SUCCESS = 'FETCH_BOOK_SUCCESS';
export const FETCH_BOOK_FAILED = 'FETCH_BOOK_FAILED';

export const LOAD_BOOK_SUCCESS = 'LOAD_BOOK_SUCCESS';

export const FETCH_BOOKITEMS_SUCCESS = 'FETCH_BOOKITEMS_SUCCESS';
export const FETCH_BOOKITEMS_FAILED = 'FETCH_BOOKITEMS_FAILED';
export const FETCH_BOOKITEMS_STUDENT_SUCCESS =
    'FETCH_BOOKITEMS_STUDENT_SUCCESS';
export const FETCH_BOOKITEMS_STUDENT_FAILED = 'FETCH_BOOKITEMS_STUDENT_FAILED';

export const SAVE_BOOKITEM_BOOKMARK_SUCCESS = 'SAVE_BOOKITEM_BOOKMARK_SUCCESS';
export const SAVE_BOOKITEM_HIGHLIGHT_SUCCESS =
    'SAVE_BOOKITEM_HIGHLIGHT_SUCCESS';
export const SAVE_BOOKITEM_NOTE_SUCCESS = 'SAVE_BOOKITEM_NOTE_SUCCESS';
export const SAVE_BOOKITEM_FAILED = 'SAVE_BOOKITEM_FAILED';

export const UPDATE_STUDENT_ITEM_SUCCESS = 'UPDATE_STUDENT_ITEM_SUCCESS';
export const UPDATE_TEMPLATE_ITEM_SUCCESS = 'UPDATE_TEMPLATE_ITEM_SUCCESS';

export const DELETE_BOOKITEM_BOOKMARK_SUCCESS =
    'DELETE_BOOKITEM_BOOKMARK_SUCCESS';
export const DELETE_BOOKITEM_HIGHLIGHT_SUCCESS =
    'DELETE_BOOKITEM_HIGHLIGHT_SUCCESS';
export const DELETE_BOOKITEM_NOTE_SUCCESS = 'DELETE_BOOKITEM_NOTE_SUCCESS';
export const DELETE_BOOKITEM_FAILED = 'DELETE_BOOKITEM_FAILED';

export const UPDATE_BOOKSTATUS_SUCCESS = 'UPDATE_BOOKSTATUS_SUCCESS';
export const UPDATE_BOOKSTATUS_FAILED = 'UPDATE_BOOKSTATUS_FAILED';

export const DOWNLOADED_BOOK_SUCCESS = 'DOWNLOADED_BOOK_SUCCESS';
export const DOWNLOADED_BOOK_FAILED = 'DOWNLOADED_BOOK_FAILED';
export const DELETE_DOWNLOADED_BOOK = 'DELETE_DOWNLOADED_BOOK';

export const DOWNLOADED_SPEECH_SUCCESS = 'DOWNLOADED_SPEECH_SUCCESS';
export const DOWNLOADED_SPEECH_FAILED = 'DOWNLOADED_SPEECH_FAILED';

export const GET_SPEECH_MARKS_LOCAL = 'GET_SPEECH_MARKS_LOCAL';
export const GET_SPEECH_MARKS = 'GET_SPEECH_MARKS';

export const RESET_BOOK = 'RESET_BOOK';
export const SET_BOOK_READY = 'SET_BOOK_READY';
export const SET_PROJECT_READY = 'SET_PROJECT_READY';

export const CACHE_BOOK_PAGE = 'CACHE_BOOK_PAGE';
export const RESET_CACHED_BOOK_PAGES = 'RESET_CACHED_BOOK_PAGES';

export const FETCH_BLM_SUCCESS = 'FETCH_BLM_SUCCESS';
export const FETCH_BLM_FAILED = 'FETCH_BLM_FAILED';

export const UPDATE_BLM_DATA_SUCCESS = 'UPDATE_BLM_DATA_SUCCESS';
export const UPDATE_BLM_DATA_FAILED = 'UPDATE_BLM_DATA_FAILED';

export const SAVE_BLMITEM_FABRIC_SUCCESS = 'SAVE_BLMITEM_FABRIC_SUCCESS';
export const SAVE_BLMITEM_FABRIC_FAILED = 'SAVE_BLMITEM_FABRIC_FAILED';

export const REMOVE_BLM_DATA_SUCCESS = 'REMOVE_BLM_DATA_SUCCESS';

export const GET_BLM_ITEMS_SUCCESS = 'GET_BLM_ITEMS_SUCCESS';
export const GET_BLM_ITEMS_FAILED = 'GET_BLM_ITEMS_FAILED';

export const LOAD_BLMS_SUCCESS = 'LOAD_BLMS_SUCCESS';
export const LOAD_BLMS_FAILED = 'LOAD_BLMS_FAILED';

export const EMPTY_BLMS_SUCCESS = 'EMPTY_BLMS_SUCCESS';

export const SELECTED_BLM_SUCCESS = 'SELECTED_BLM_SUCCESS';

export const UPDATE_BLM_STATUS_SUCCESS = 'UPDATE_BLM_STATUS_SUCCESS';
export const UPDATE_BLM_STATUS_FAILED = 'UPDATE_BLM_STATUS_FAILED';

export const BLM_DRAFT_SAVED_SUCCESS = 'BLM_DRAFT_SAVED_SUCCESS';

export const UPDATE_MODE_SUCCESS = 'UPDATE_MODE_SUCCESS';
export const BOOK_TOOLBAR_UPDATE_MODE = 'BOOK_TOOLBAR_UPDATE_MODE';

export const GET_COMPLETED_BLMS_SUCCESS = 'GET_COMPLETED_BLMS_SUCCESS';
export const GET_COMPLETED_BLMS_FAILED = 'GET_COMPLETED_BLMS_FAILED';

export const GET_BLM_STATUS_SUCCESS = 'GET_BLM_STATUS_SUCCESS';
export const GET_BLM_STATUS_FAILED = 'GET_BLM_STATUS_FAILED';

export const GET_TEACHERS_BLM_COMMENT_SUCCESS =
    'GET_TEACHERS_BLM_COMMENT_SUCCESS';
export const GET_TEACHERS_BLM_COMMENT_FAILED =
    'GET_TEACHERS_BLM_COMMENT_FAILED';

export const SAVE_TEACHERS_COMMENT_SUCCESS = 'SAVE_TEACHERS_COMMENT_SUCCESS';
export const SAVE_TEACHERS_COMMENT_FAILED = 'SAVE_TEACHERS_COMMENT_FAILED';

export const SAVE_ADVANCED_SEARCH_FILTERS = 'SAVE_ADVANCED_SEARCH_FILTERS';
export const CLEAR_ADVANCED_SEARCH_FILTERS = 'CLEAR_ADVANCED_SEARCH_FILTERS';

export const ADD_BOOK_TO_BOOKBAG_SUCCESS = 'ADD_BOOK_TO_BOOKBAG_SUCCESS';
export const ADD_BOOK_TO_BOOKBAG_FAILED = 'ADD_BOOK_TO_BOOKBAG_FAILED';
export const REMOVE_BOOK_TO_BOOKBAG_SUCCESS = 'REMOVE_BOOK_TO_BOOKBAG_SUCCESS';
export const REMOVE_BOOK_TO_BOOKBAG_FAILED = 'REMOVE_BOOK_TO_BOOKBAG_FAILED';
export const PURGE_TEMP_ID_NOTES = 'PURGE_TEMP_ID_NOTES';
export const UPDATE_CURRENT_PAGE = 'UPDATE_CURRENT_PAGE';

export const TOGGLE_USER_PROFILE_MODAL = 'TOGGLE_USER_PROFILE_MODAL';
export const TOGGLE_LOGGOUT_CONFIRM_MODAL = 'TOGGLE_LOGGOUT_CONFIRM_MODAL';

// BLM TOOLBAR
export const TOGGLE_BLM_TOOLBAR_ERASER = 'TOGGLE_BLM_TOOLBAR_ERASER';
export const TOOLBAR_ERASER_OFF = 'TOOLBAR_ERASER_OFF';
export const TOOLBAR_ERASER_ON = 'TOOLBAR_ERASER_ON';

export const TOOLBAR_DRAWING_MODE_ON = 'TOOLBAR_DRAWING_MODE_ON';
export const TOOLBAR_POINTER_ON = 'TOOLBAR_POINTER_ON';
export const TOOLBAR_ADD_TEXT_ON = 'TOOLBAR_ADD_TEXT_ON';
export const SHOW_RICH_TEXT_BUTTONS = 'SHOW_RICH_TEXT_BUTTONS';

export const NEXT_PAGE = 'NEXT_PAGE';
export const PREV_PAGE = 'PREV_PAGE';
export const SCALE_PERCENT = 'SCALE_PERCENT';
export const FIRST_PAGE = 'FIRST_PAGE';
export const LAST_PAGE = 'LAST_PAGE';
export const TOTAL_PAGE = 'TOTAL_PAGE';

// Viewer  / BookView
export const UPDATE_LEFT_PAGE_CONTAINER = 'UPDATE_LEFT_PAGE_CONTAINER';
export const UPDATE_BOOK_SCALE_PERCENT = 'UPDATE_BOOK_SCALE_PERCENT';
export const UPDATE_PROJECT_SCALE_PERCENT = 'UPDATE_PROJECT_SCALE_PERCENT';
export const UPDATE_PAGES_VISIBLE = 'UPDATE_PAGES_VISIBLE';
export const AUTOMATIC_UPDATE_PAGES_VISIBLE = 'AUTOMATIC_UPDATE_PAGES_VISIBLE';
export const RESET_VIEW = 'RESET_VIEW';
export const BOOK_TOOLBAR_POINTING = 'BOOK_TOOLBAR_POINTING';
export const BOOK_TOOLBAR_NOTES = 'BOOK_TOOLBAR_NOTES';
export const BOOK_TOOLBAR_HIGHLIGHTING = 'BOOK_TOOLBAR_HIGHLIGHTING';
export const BOOK_TOOLBAR_ERASING = 'BOOK_TOOLBAR_ERASING';
export const BOOK_TOOLBAR_UNDERLINING = 'BOOK_TOOLBAR_UNDERLINING';
export const BOOK_TOOLBAR_STRIKING = 'BOOK_TOOLBAR_STRIKING';
export const BOOK_TOOLBAR_MOVING = 'BOOK_TOOLBAR_MOVING';
export const SET_ACTIVE_NOTE = 'SET_ACTIVE_NOTE';
export const INCREASE_BOOK_ZOOM = 'INCREASE_BOOK_ZOOM';
export const DECREASE_BOOK_ZOOM = 'DECREASE_BOOK_ZOOM';
export const UPDATE_PENDING_ITEM = 'UPDATE_PENDING_ITEM';
export const BOOK_UPDATE_AUDIO = 'BOOK_UPDATE_AUDIO';
export const READIUM_INITIALIZED = 'READIUM_INITIALIZED';
