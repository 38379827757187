import * as React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { hashHistory } from 'react-router';
import UserProfileForm from '../common/UserProfileForm';
import CommonModal from '../common/CommonModal';
import { Nav, Navbar, NavItem } from 'react-bootstrap';

import {
    userUpdateProfile,
    userUpdatePW,
    userLogout,
    toggleClassCodeModal
} from '../../actions/userActions';
import {
    toggleUserProfileModal,
    toggleLoggoutConfirmModal
} from '../../actions/dashboard/dashboardActions';

import constants from '../../constants/constants';
import UserAPI from '../../api/userAPI';
import { StyledNavbar } from '../../constants/styledComponents';
import ClassCodeModal from './ClassCodeModal';

const FontAwesome = require('react-fontawesome');

class BookBagHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: this.props.user.First,
            lastName: this.props.user.Last,
            password: '',
            confirmPassword: '',
            loginID: this.props.user.LoginID,
            logoutWarning:
                'All of your downloaded books will be removed and you will have to redownload them. Are you sure you want to continue?'
        };
        this.theme = constants.themeProvider.activeTheme;
        this.logo = this.theme.logo;
    }

    componentDidMount() {
        this.setState({ logoutWarning: '' });
    }

    closeLoggoutConfirmModal = () => {
        this.setState({ showLoggoutConfirmModal: false });
    };

    handleLoggout = () => {
        this.props.userLogout();
        hashHistory.push('/login');
    };

    handleSelect = (e) => {
        switch (e) {
            case '1':
                this.props.toggleUserProfileModal();
                break;
            case '2':
                this.signOut();
                break;
            case '3':
                this.props.toggleClassCodeModal();
                break;
            default:
        }
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    signOut = () => {
        // prevent user from logging out if there is no internet connection
        if (!this.props.isOnline) {
            toastr.error(
                `You must be connected to the internet before logging out.`,
                `Warning`,
                constants.toastrWarningOptions
            );
            return;
        }
        this.props.toggleLoggoutConfirmModal();
    };

    handleSubmit = () => {
        if (!this.props.isOnline) {
            toastr.error(
                `You must be connected to the internet before updating your profile or changing your password.`,
                `No Internet Connection`,
                {
                    closeButton: true,
                    showAnimation: 'animated fadeInDown'
                }
            );
            return;
        }
        if (
            this.state.firstName !== '' &&
            this.state.lastName !== '' &&
            this.state.loginID !== ''
        ) {
            const firstName = this.state.firstName;
            const lastName = this.state.lastName;
            const loginID = this.state.loginID;
            this.props.toggleUserProfileModal();

            this.props
                .userUpdateProfile(
                    this.props.user,
                    firstName,
                    lastName,
                    loginID
                )
                .then(() => {
                    toastr.success(
                        `Updated profile info.`,
                        `Success`,
                        constants.toastrSuccessOptions
                    );
                })
                .catch((error) => {
                    console.error('Error updating profile', error);
                    toastr.error(
                        `Unable to update profile.  ${error.statusText}`,
                        `Error`,
                        constants.toastrErrorOptions
                    );
                });
        }

        if (
            this.state.password === this.state.confirmPassword &&
            this.state.password !== ''
        ) {
            const password = this.state.password;
            this.props
                .userUpdatePW(this.props.user, password)
                .then(() => {
                    toastr.success(
                        `Updated password.`,
                        `Success`,
                        constants.toastrSuccessOptions
                    );
                })
                .catch((error) => {
                    console.error('Error updating password', error);
                    toastr.error(
                        `Unable to update password.  ${error.statusText}`,
                        `Error`,
                        constants.toastrErrorOptions
                    );
                });
            this.setState({ password: '', confirmPassword: '' });
            this.props.toggleUserProfileModal();
        }
    };
    buildUserProfileForm = () => {
        return (
            <UserProfileForm
                handleChange={this.handleChange}
                forms={[
                    {
                        id: 'firstName',
                        name: 'firstName',
                        value: this.state.firstName,
                        placeholder: 'First Name',
                        type: 'text'
                    },
                    {
                        id: 'lastName',
                        name: 'lastName',
                        value: this.state.lastName,
                        placeholder: 'Last Name',
                        type: 'text'
                    },
                    {
                        id: 'loginID',
                        name: 'loginID',
                        value: this.state.loginID,
                        placeholder: 'Login ID (i.e username or email)',
                        type: 'text'
                    },
                    {
                        id: 'password1',
                        name: 'password',
                        placeholder: 'Password',
                        type: 'text'
                    },
                    {
                        id: 'confirmPassword',
                        name: 'confirmPassword',
                        placeholder: 'Confirm Password',
                        type: 'text'
                    }
                ]}
            />
        );
    };

    render() {
        return (
            <div className="book-bag-header">
                <StyledNavbar
                    css={{
                        'border-bottom-color': this.theme.primaryBackground
                    }}
                >
                    {/* <Navbar> */}
                    <Navbar.Brand className="header-logo">
                        <img src={this.logo} alt="" />
                    </Navbar.Brand>
                    {/* </Navbar> */}
                    <Navbar.Collapse className="justify-content-end">
                        <Nav
                            className="justify-content-end"
                            onSelect={this.handleSelect}
                        >
                            {this.props.user && this.props.user.School && (
                                <NavItem className="main-header-item">
                                    <Nav.Link disabled>
                                        {this.props.user.School.Name}
                                    </Nav.Link>
                                </NavItem>
                            )}
                            {this.props.user && this.props.user.School && (
                                <NavItem className="main-header-item">
                                    <Nav.Link disabled>|</Nav.Link>
                                </NavItem>
                            )}
                            {UserAPI.isStudent(this.props.user.RoleID) && (
                                <NavItem className="main-header-item" title="add class or group">
                                    <Nav.Link eventKey={3}>
                                    <FontAwesome name="plus" />
                                        {' '}Add Class/Group
                                    </Nav.Link>
                                </NavItem>
                            )}
                            {UserAPI.isStudent(this.props.user.RoleID) && (

                                <NavItem className="main-header-item" title="edit profile">
                                    <Nav.Link eventKey={1}>
                                    <FontAwesome name="user" />
                                        {' '}{this.props.user.First}{' '}
                                        {this.props.user.Last}
                                    </Nav.Link>
                                </NavItem>
                                
                            )}

                            <NavItem
                                className="main-header-item"
                                title="Logout"
                            >
                                <Nav.Link eventKey={2}>
                                    <FontAwesome name="sign-out" /> Logout
                                </Nav.Link>
                            </NavItem>
                        </Nav>
                    </Navbar.Collapse>
                </StyledNavbar>

                <ClassCodeModal addCode={this.props.addCode}></ClassCodeModal>

                <CommonModal
                    name="userProfile"
                    className="common-modal static-modal-update-user-profile"
                    bsSize="small"
                    title={`Hi ${
                        this.state.firstName + ' ' + this.state.lastName
                    }, need to update your profile?`}
                    children={this.buildUserProfileForm()}
                    modalVisable={this.props.showUserProfileModal}
                    cancelText="Cancel"
                    submitText="Save"
                    cancel={this.props.toggleUserProfileModal}
                    submit={this.handleSubmit}
                />
                <CommonModal
                    name="loggoutConfirm"
                    className="common-modal"
                    bsSize="small"
                    title={`Are you sure you want to logout?`}
                    children={this.state.logoutWarning}
                    modalVisable={this.props.showLoggoutConfirmModal}
                    cancelText="Cancel"
                    submitText="Logout"
                    cancel={this.props.toggleLoggoutConfirmModal}
                    submit={this.handleLoggout}
                />
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        user: state.user,
        showUserProfileModal: state.dashboard.showUserProfileModal,
        showLoggoutConfirmModal: state.dashboard.showLoggoutConfirmModal
    };
}

export default connect(mapStateToProps, {
    userUpdateProfile,
    userUpdatePW,
    userLogout,
    toggleUserProfileModal,
    toggleLoggoutConfirmModal,
    toggleClassCodeModal
})(BookBagHeader);
