import * as types from './actionTypes';

export const updateLeftPageContainer = (width, height) => ({
    type: types.UPDATE_LEFT_PAGE_CONTAINER,
    width,
    height
});

export const updateBookScalePercent = (scale) => ({
    type: types.UPDATE_BOOK_SCALE_PERCENT,
    scale
});

export const updateProjectScalePercent = (scale) => ({
    type: types.UPDATE_PROJECT_SCALE_PERCENT,
    scale
});

export const updatePagesVisible = (pagesVisible) => ({
    type: types.UPDATE_PAGES_VISIBLE,
    pagesVisible
});

export const automaticUpdatePagesVisible = (pagesVisible) => ({
    type: types.AUTOMATIC_UPDATE_PAGES_VISIBLE,
    pagesVisible
});

export const resetBookView = () => ({
    type: types.RESET_VIEW
});

export const setActiveNote = (noteID) => ({
    type: types.SET_ACTIVE_NOTE,
    noteID
});

// if the zoom level is 100 then turn on automatic zoom
// if it is not 100 then turn on manual zoom and set the manual zoom level by increasing or decreasing the index of the bookZoomLevels array
// export const increaseBookZoom = (e) => {
//     return function (dispatch, getState) {
//         e.preventDefault();
//         dispatch({type: types.INCREASE_BOOK_ZOOM});
//     }
// };

// export const decreaseBookZoom = (e) => {
//     return function (dispatch, getState) {
//         e.preventDefault();
//         dispatch({type: types.DECREASE_BOOK_ZOOM});
//     }
// };

export const increaseBookZoom = () => ({
    type: types.INCREASE_BOOK_ZOOM
});

export const decreaseBookZoom = () => ({
    type: types.DECREASE_BOOK_ZOOM
});
export const updatePendingItem = (status) => ({
    type: types.UPDATE_PENDING_ITEM,
    status
});
