// NotesPopover is responsible for displaying the note icon and displaying the popover

import * as React from 'react';

import {
    FormControl,
    FormGroup,
    OverlayTrigger,
    Popover
} from 'react-bootstrap';

import { StyledButton } from '../../constants/styledComponents';
import class_note_icon from '../../images/class_note_icon.png';
import constants from '../../constants/constants';
import group_note_icon from '../../images/group_note_icon.png';
import student_note_icon from '../../images/student_note_icon.png';
import teacher_note_icon from '../../images/teacher_note_icon.png';

class NotePopover extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.note.Content.value || '',
            show: false
        };
        this.theme = constants.themeProvider.activeTheme;
    }

    componentDidMount() {
        if (
            this.props.activeNoteID === this.props.note.ID ||
            this.props.activeNoteID === this.props.note.TempID
        ) {
            this.setState({ show: true });
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.activeNoteID !== prevProps.activeNoteID) {
            if (
                this.props.activeNoteID === this.props.note.ID ||
                this.props.activeNoteID === this.props.note.TempID
            ) {
                this.setState({ show: true });
            } else {
                this.setState({ show: false });
            }
        }
    }

    onSubmit = (e) => {
        if (e) {
            e.preventDefault();
        }
        const note = this.props.note;
        const newNote = {
            ...note,
            Content: { ...note.Content, value: this.state.value }
        };
        this.props.saveNote(newNote);
        this.props.setActiveNote('');
    };

    cancelNote = () => {
        this.props.setActiveNote('');
        // delete the note if the user never saved any content
        if (this.props.note.Content.value === '') {
            this.props.deleteNote(this.props.note);
        }
    };

    handleChange = (e) => {
        this.setState({ value: e.target.value });
    };

    getPopover = (note) => {
        let popoverClassName = '';
        let disableNote = false;
        let hideFromStudent = '';

        if (note.Type === 4) {
            popoverClassName = 'teacher-student-popover-note';
        } else if (note.Type === 5) {
            popoverClassName = 'teacher-class-popover-note';
        } else if (note.Type === 6) {
            popoverClassName = 'teacher-group-popover-note';
        } else if (note.Type === 3) {
            popoverClassName = 'student-popover-note';
        }
        if (this.props.isStudent) {
            if (note.Type === 5 || note.Type === 4 || note.Type === 6) {
                disableNote = true;
                hideFromStudent = 'hide';
            }
        }
        const cancelClassName = `btn-popover popover-cancel`;
        const deleteClassName = `btn-popover popover-delete ${hideFromStudent}`;
        const saveClassName = `btn-popover popover-submit ${hideFromStudent}`;

        return (
            <Popover
                title="Note"
                id="note-popover"
                className={popoverClassName}
            >
                <form onSubmit={this.onSubmit}>
                    <FormGroup controlId="formControlsTextarea">
                        <FormControl
                            as="textarea"
                            placeholder="Type your note"
                            disabled={disableNote}
                            value={this.state.value}
                            onChange={this.handleChange}
                            rows={4}
                        />
                    </FormGroup>
                    <div className="popover-footer">
                        <StyledButton
                            className={cancelClassName}
                            onClick={() => {
                                this.cancelNote(this.props.note);
                            }}
                            type="button"
                            role="button"
                        >
                            Cancel
                        </StyledButton>
                        <StyledButton
                            css={this.theme.warningButtonStyle}
                            className={deleteClassName}
                            onClick={() => {
                                this.props.deleteNote(this.props.note);
                            }}
                            type="button"
                            role="button"
                        >
                            Delete
                        </StyledButton>
                        <StyledButton
                            css={this.theme.secondaryButtonStyle}
                            className={saveClassName}
                            type="submit"
                            role="button"
                            disabled={this.props.loading}
                        >
                            Save
                        </StyledButton>
                    </div>
                </form>
            </Popover>
        );
    };
    handleToggle = () => {
        const noteID = this.props.note.ID || this.props.note.TempID;
        this.props.setActiveNote(noteID);
    };

    render() {
        const note = this.props.note;
        const offsetX = note.Content.offsetX;
        const offsetY = note.Content.offsetY;
        const ID = !!note.ID ? note.ID : note.TempID;
        let note_icon;
        if (note.Type === 3) {
            note_icon = student_note_icon;
        } else if (note.Type === 4) {
            note_icon = teacher_note_icon;
        } else if (note.Type === 5) {
            note_icon = class_note_icon;
        } else if (note.Type === 6) {
            note_icon = group_note_icon;
        }
        let placement = 'top';
        if (note.Content.offsetY < 240) {
            // 190 is the height of the popover
            placement = 'bottom';
        }
        return (
            <OverlayTrigger
                trigger="click"
                show={this.state.show}
                onToggle={this.handleToggle}
                placement={placement}
                overlay={this.getPopover(note)}
            >
                <div
                    key={ID}
                    className={'note note-' + ID}
                    style={{
                        position: 'absolute',
                        top: offsetY,
                        left: offsetX,
                        transform: `scale(${1 / this.props.pageScalePercent})`,
                        zIndex: 3,
                        transformOrigin: 'top left'
                    }}
                >
                    <img alt="" src={note_icon} style={{ width: 20 }} />
                </div>
            </OverlayTrigger>
        );
    }
}

export default NotePopover;
