/*
 * Eventually the BookView component will only be responsible for
 */

import 'rangy/lib/rangy-textrange';
import 'rangy/lib/rangy-serializer';
import 'rangy/lib/rangy-selectionsaverestore';
import 'rangy/lib/rangy-highlighter';
import 'rangy/lib/rangy-classapplier';

// import 'react-jplayer/src/less/skins/sleek.less';
// Styles Play/Pause/Mute etc when icons (<i />) are used for them
// import 'react-jplayer/src/less/controls/iconControls.less';

import { viewerModes } from '../../constants/viewerModes';
import EpubView from './EpubView';
import React, { Component } from 'react';
import {
    automaticUpdatePagesVisible,
    decreaseBookZoom,
    increaseBookZoom,
    resetBookView,
    setActiveNote,
    updateBookScalePercent,
    updateLeftPageContainer,
    updatePagesVisible,
    updatePendingItem,
    updateProjectScalePercent
} from '../../actions/bookViewActions';
import {
    cacheBookPage,
    deleteBookItem,
    downloadSpeech,
    getBookByID,
    getBookItems,
    getBookItemsByStudentID,
    getBookPage,
    getBookProperties,
    searchBookBagBooks,
    getSpeechMarks,
    getTeacherBookItems,
    nextPage,
    prevPage,
    resetCachedBookPages,
    saveBookItem,
    setBookReady,
    updateBookStatus,
    updateCurrentPage,
} from '../../actions/bookActions';
import {
    emptyBLMs,
    getAssignedBLMs,
    getBLMByID,
    getBLMItems,
    getBLMProperties,
    getBLMStatus,
    getStudentsBLMItems,
    getTeachersComment,
    getTemplateItems,
    setProjectReady
} from '../../actions/blmActions';
import { hashHistory, withRouter } from 'react-router';
import {
    manualAjaxEnd,
    manualAjaxStart
} from '../../actions/ajaxStatusActions';
import {
    startErasing,
    startHighlighting,
    startMoving,
    startNotes,
    startPointing,
    startStriking,
    startUnderlining
} from '../../actions/bookToolbarActions';

import BlmAPI from '../../api/blmAPI';
import CommonModal from '../common/CommonModal';
import Drawer from './Drawer';
import { HeaderContainer } from '../header/HeaderContainer';
import Loading from '../common/Loading';
import Pages from './Pages';
import Toolbar from './Toolbar';
import UserAPI from '../../api/userAPI';
import config from '../../api/config';
import { connect } from 'react-redux';
import constants from '../../constants/constants';
import { debounce } from 'lodash';
import findSimilarMarkups from '../../utilities/MarkupMergerUtility';
import rangy from 'rangy/lib/rangy-core';
import { removeQuery } from '../../vendor/utils-router';
import { toastr } from 'react-redux-toastr';
import { userLogout } from '../../actions/userActions';
import { requireSignIn } from '../../routes';
import $ from 'jquery';
import {
    BOOK_FIRST_PAGE,
    BOOK_LAST_PAGE,
    BOOK_NEXT_PAGE,
    BOOK_PREV_PAGE
} from './events';
import { viewerDrawerTypeEnum } from '../../models-enums';

window.rangy = rangy;

class BookView extends Component {
    highlights;
    activeTimeout;
    activeWarningTimeout;

    constructor(props) {
        super(props);
        this.state = {
            highlighterLeft: null,
            highlighterRight: null,
            EPubUrl: '',
            blmHtml: {},
            viewerSettings: {
                showDrawer: false,
                showDrawerHighlightsButton: true,
                showDrawerNotesButton: true,
                drawerType: viewerDrawerTypeEnum.notes,
                highlightingMouseDown: false,
                header: { visible: false, class: 'fa fa-angle-double-down' },
                showToolbar: true,
                showHeader: true,
                highlightingPageNumber: 1,
                loadedProjectAssignmentID: '' // keep track of what Project Assignment the blmData belongs too so we know when to re-load it
            },
            showSaveProjectConfirm: false,
            saveProjectSubmit: () => {
                console.error('saveProjectModal function not replaced');
            }
        };
        this.pagesRef = React.createRef();

        this.superDebouncedMarkupUpdate = debounce(this.updateMarkups, 8000);
        this.debouncedMarkupUpdate = debounce(this.updateMarkups, 1000);

        // lets store moseMove on the BookView class because we are changing it very quickly as the mouse moves.
        this.mouseMove = 0;

        // let speechMarkRaw = constants.testSpeechMarkRaw;
        // this.speechMarksData = speechMarkRaw.split(/\n/);
        this.highlightedSpeechMarks = [];
        this.speechMarkIndex = 0;
    }

    componentDidMount() {
        console.info('bookview mounted');
        if (requireSignIn(this.props.user, this.props.location)) {
            return;
        }

        // initialize all rangy stuff
        rangy.init();
        this.setState({
            highlighterRight: this.createHighlighter(),
            highlighterLeft: this.createHighlighter()
        });
        // open the header for testing
        // this.toggleHeader();

        /*
         * Initial Viewer Settings
         */
        let initialViewerSettings = {};

        // should we show the header?
        initialViewerSettings.showHeader = UserAPI.canAccessBook(
            this.props.user.RoleID
        );

        // load list of BLMs and Books?
        if (UserAPI.isStudent(this.props.user.RoleID)) {
            // this.initBooks(); // TODO fix the action in here... it expects a lot of params
            this.initBLMs();
        } else {
            // load blm if you are not a student
            if (this.props.blmMode) {
                if (
                    this.props.viewerMode ===
                    viewerModes.MODE_TEACHER_STUDENT_BLM
                ) {
                    this.loadBLM(
                        this.props.projectAssignmentID,
                        this.props.blmID
                    ).then(() => {
                        this.props.setProjectReady(true);
                        $('#pages').fadeTo('fast', 1.0);
                        // if blmID and No bookID then we will only display a single page blm
                        if (!this.props.bookID) {
                            this.props.updatePagesVisible(1);
                        }
                    });
                } else {
                    this.loadBLM('', this.props.blmID).then(() => {
                        this.props.setProjectReady(true);
                        $('#pages').fadeTo('fast', 1.0);
                        // if blmID and No bookID then we will only display a single page blm
                        if (!this.props.bookID) {
                            this.props.updatePagesVisible(1);
                        }
                    });
                }
            }
            if (
                this.props.viewerMode ===
                    viewerModes.MODE_TEACHER_CLASS_NOTES ||
                this.props.viewerMode === viewerModes.MODE_TEACHER_GROUP_NOTES
            ) {
                initialViewerSettings.showDrawerHighlightsButton = false;
            }
        }

        initialViewerSettings.hideDrawer = this.shouldHideDrawer();

        this.updateViewerSettings(initialViewerSettings, () => {
            console.log('updated initial viewer settings');

            // Load Book?
            if (this.props.bookID && this.props.bookID.length) {
                if (this.props.book.ID !== this.props.bookID) {
                    this.props.resetCachedBookPages();
                    this.loadBook(this.props.bookID).then(() => {
                        setTimeout(() => {
                            this.props.setBookReady(true);
                            // check if the left page is supposed to be even
                            this.goToPage(this.props.currentPage, false, false);
                        }, 100);
                        $('#pages').fadeTo('fast', 1.0);
                    });
                    return;
                }
                this.loadBook(this.props.bookID).then(() => {
                    setTimeout(() => {
                        this.props.setBookReady(true);
                        // check if the left page is supposed to be even
                        this.goToPage(this.props.currentPage, false, false);
                    }, 100);
                    $('#pages').fadeTo('fast', 1.0);
                });
            }
        });

        // listen for active user
        // document.addEventListener(
        //     'visibilitychange',
        //     this.handleVisibilityChange,
        //     false
        // );
        // this.resetActiveTimeout();
        this.setupEventHandlers();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.bookIsZooming !== this.props.bookIsZooming) {
            if (this.props.bookIsZooming) {
                this.props.startMoving();
            } else {
                this.props.startPointing(); // this is a little strange to zoom to normal and then it switches to pointer, but cant think of simple solution
            }
        }
        // if the pages visible changes we need to check if the left page is supposed to be even
        // unless the book or the blm is not ready yet
        if (
            prevProps.pagesVisible !== this.props.pagesVisible &&
            (this.props.blm.projectIsReady || this.props.book.bookIsReady)
        ) {
            this.goToPage(this.props.book.currentPage);
        }

        // TODO can we improve this flow by grouping the things to do when a book or blm loads here?
        // if (prevProps.blm.projectIsReady !== this.props.blm.projectIsReady && this.props.blm.projectIsReady){
        //   $("#pages").fadeTo("fast", 1.0);
        // }
        // if (prevProps.book.bookIsReady !== this.props.book.bookIsReady && this.props.book.bookIsReady){
        //   $("#pages").fadeTo("fast", 1.0);
        // }

        if (prevProps.book.ID && !this.props.book.ID) {
            console.log(
                'book has been reset in bookView, redirecting to bookbag'
            );
            this.goToBag();
        }
    }

    componentWillUnmount() {
        setTimeout(() => {
            this.removeAllHighlights();
        }, 100);
        if (this.props.blms.length > 0) {
            this.props.emptyBLMs();
        }
        this.superDebouncedMarkupUpdate.cancel();
        this.debouncedMarkupUpdate.cancel();
        // document.removeEventListener(
        //     'visibilitychange',
        //     this.handleVisibilityChange
        // );
        // if (this.activeTimeout) {
        //     clearTimeout(this.activeTimeout);
        // }
        // if (this.activeWarningTimeout) {
        //     clearTimeout(this.activeWarningTimeout);
        // }
        // if (this.handleTimeoutCloseBook) {
        //     clearTimeout(this.handleTimeoutCloseBook);
        // }
        this.removeEventHandlers();
    }

    setupEventHandlers() {
        // listen for renewedAzureToken
        document.addEventListener(
            'renewedAzureToken',
            this.handleRenewedAzureToken
        );
        document.addEventListener('keyup', this.handleKeyPress, false);
    }

    removeEventHandlers() {
        document.removeEventListener(
            'renewedAzureToken',
            this.handleRenewedAzureToken
        );
        document.removeEventListener('keyup', this.handleKeyPress, false);
    }

    handleKeyPress = ({ key }) => {
        key && key === 'ArrowRight' && this.nextPage();
        key && key === 'ArrowLeft' && this.prevPage();
    };

    /*
     * shouldHideDrawer
     * don't show the left drawer in resource mode or when viewing an ePub
     */
    shouldHideDrawer = () => {
        return (
            this.props.viewerMode === viewerModes.MODE_RESOURCE ||
            this.props.book.IsEPub === true
        );
    };

    handleRenewedAzureToken = () => {
        console.log('handling renewed Azure token');

        // if( config.System === 'web' && this.props.isOnline){
        //   this.props.resetCachedBookPages();
        //   this.getBookHTMLPages(this.props.book, this.props.currentPage, true);
        // } else if (config.System !== 'web' && this.props.isOnline){

        if (this.props.isOnline) {
            // if on a device we need to re-download the entire book
            this.props.resetCachedBookPages();
            this.loadBook(this.props.bookID).then(() => {
                setTimeout(() => {
                    this.props.setBookReady(true);
                    // check if the left page is supposed to be even
                    this.goToPage(this.props.currentPage);
                }, 100);
                $('#pages').fadeTo('fast', 1.0);
            });
        } else {
            toastr.error(
                `No internet connection.`,
                `Error Loading Book`,
                constants.toastrErrorOptions
            );
        }
    };

    // handleVisibilityChange = () => {
    //     if (document.hidden) {
    //         this.activeTimeout = setTimeout(
    //             this.handleTimeoutWarning,
    //             constants.closeBookTimeout
    //         );
    //         console.log('view hidden');
    //     } else {
    //         console.log('view visible');
    //         clearTimeout(this.activeTimeout);
    //         clearTimeout(this.activeWarningTimeout);
    //     }
    // };

    // handleTimeoutWarning = () => {
    //     toastr.warning(
    //         `Still there?`,
    //         `We will be closing the book in 30 seconds due to inactivity`,
    //         {
    //             ...constants.toastrWarningOptions,
    //             id: 'countdownWarning',
    //             timeOut: constants.closeBookWarning,
    //             onCloseButtonClick: this.resetActiveTimeout
    //         }
    //     );
    //     this.activeWarningTimeout = setTimeout(
    //         this.handleTimeoutCloseBook,
    //         constants.closeBookWarning
    //     );
    //     clearTimeout(this.activeTimeout);
    // };

    // handleTimeoutCloseBook = () => {
    //     clearTimeout(this.activeWarningTimeout);
    //     this.props.updateBookStatus(
    //         this.props.book.ID,
    //         this.props.book.currentPage,
    //         this.props.book.pagecount,
    //         true,
    //         this.props.blmMode
    //     );
    //     this.closeBookView();
    //     toastr.warning(
    //         '',
    //         "We have closed the book due to inactivity. Don't worry, we put in a bookmark for ya.",
    //         { ...constants.toastrWarningOptions, timeOut: 0 }
    //     );
    // };

    resetActiveTimeout = () => {
        console.log('resetting countdown timeout');
        // clearTimeout(this.activeTimeout);
        // clearTimeout(this.activeWarningTimeout);
        // this.activeTimeout = setTimeout(
        //     this.handleTimeoutWarning,
        //     constants.closeBookTimeout
        // );
        // // toastr.remove('countdownWarning')  // not sure why this did not work.
        // toastr.removeByType('warning');
    };

    /*
     * function to call from child components to update viewerSettings
     * newViewerSettings = some subset of the viewer settings
     * object that you want to update.
     * callback - setState is asynchronus, so I am allowing an optional callback
     * in case we need to do something AFTER the update completes
     */
    updateViewerSettings = (newViewerSettings, callback) => {
        // let now = new Date().getTime();
        // console.error('UPDATING VIEWER SETTINGS: old, new', now ,this.state.viewerSettings, newViewerSettings );

        let viewerSettings = Object.assign(
            {},
            this.state.viewerSettings,
            newViewerSettings
        );
        return this.setState({ viewerSettings }, callback);
    };

    /*
     * Gets an array of books
     */
    initBooks = () => {
        if (this.props.books.length === 0) {
            // check if we are online
            if (!this.props.isOnline) {
                // if we do not have books and we are offline, we need to show an error
                console.warn('unable to load bookview while offline');
                toastr.error(
                    `No internet connection.`,
                    `Error Loading Book`,
                    constants.toastrErrorOptions
                );
                return;
            }
            this.props.searchBookBagBooks();
        }
    };

    initBLMs = () => {
        console.log('initializing BLMs');
        if (!this.props.isOnline) {
            console.log('offline, skipping getAssigndBlms');
            return;
        }
        this.props
            .getAssignedBLMs(this.props.bookID, this.props.user)
            .then((blms) => {
                // Load BLM?
                if (this.props.projectAssignmentID && this.props.blmMode) {
                    this.loadBLM(
                        this.props.projectAssignmentID,
                        this.props.blmID
                    );

                    // if blmID and No bookID then we will only display a single page blm
                    // commented out for now as a student never views a blm by itself
                    // if (!this.props.bookID) {
                    //   initialViewerSettings.pagesVisible = 1;
                    // }
                }
            })
            .catch((error) => {
                console.error('Error searching for blm', error);
                if (this.props.isOnline) {
                    toastr.error(
                        'Error searching for blm',
                        `Error`,
                        constants.toastrErrorOptions
                    );
                }
            });
    };

    /*
  ***************************************** RANGY HIGHLIGHTER ***************************************************
  /*

  /*
  * create Rangy highlighter objects for the various markup types and colors
  */
    createHighlighter = () => {
        var newHL = rangy.createHighlighter(this.pagesRef.current, 'TextRange');
        newHL.addClassApplier(rangy.createClassApplier('hl-yellow'));
        newHL.addClassApplier(rangy.createClassApplier('hl-teal'));
        newHL.addClassApplier(rangy.createClassApplier('hl-green'));
        newHL.addClassApplier(rangy.createClassApplier('hl-orange'));
        newHL.addClassApplier(rangy.createClassApplier('hl-underline'));
        newHL.addClassApplier(rangy.createClassApplier('hl-strikethrough'));
        return newHL;
    };

    removeAllHighlights = () => {
        this.state.highlighterLeft.removeAllHighlights();
        this.state.highlighterRight.removeAllHighlights();
    };

    /*
     * pass in which side then make sure all selections are safe before saving them
     * we have to have two highlighters because this saves all the highlights for the highlighter.
     * this function is performance intensive and blocking
     */
    serializePageMarkups = (side) => {
        let self = this;
        let serializedText = this.state[`highlighter${side}`].serialize({
            serializeHighlightText: true
        });
        /* if we find a " or a -webkit-transform throw an error */
        if (
            serializedText.indexOf('"') !== -1 &&
            serializedText.indexOf('-webkit-transform') !== -1
        ) {
            console.error('unable to serialize markup', serializedText);
            alert('Error selecting text, please try again');
            self.state[`highlighter${side}`].unhighlightSelection();
            throw new Error('unable to serialize text');
        } else {
            return serializedText;
        }
    };

    /*
     ****************************************************** BOTTOM TOOLBAR *********************************************
     */
    startNotes = (e) => {
        this.resetActiveTimeout();
        if (e) {
            e.preventDefault();
        }
        if (
            !this.props.isOnline &&
            !UserAPI.isStudent(this.props.user.RoleID)
        ) {
            // if we do not have books and we are offline, we need to show an error
            console.warn('unable to create note while offline');
            toastr.error(
                `No internet connection.`,
                `Error Saving Note`,
                constants.toastrErrorOptions
            );
            return;
        }
        this.props.startNotes(true);
    };
    startHighlighter = (e, hlClass) => {
        this.resetActiveTimeout();
        // if this is a touch  then we highlight what is selected then turn off the highlight mode.
        // as of 6/2018 we are no longer doing touch highlight mode
        // if (e.type === 'touchend'){
        //   this.touchHighlight(e, hlClass);
        // } else {
        this.clickHighlight(e, hlClass);
        // }
    };

    // this function is being depricated
    touchHighlight = (e, hlClass) => {
        this.resetActiveTimeout();
        console.log('touch highlight');
        e.preventDefault();

        // make sure they selected some text first
        if (window.getSelection().type === 'None') {
            toastr.warning(
                `When using touch, please select text first.`,
                ``,
                constants.toastrWarningOptions
            );
            return;
        }
        this.doTouchHighlight(hlClass);
    };

    clickHighlight = (e, hlClass) => {
        this.resetActiveTimeout();
        console.log('started click highlighter ' + hlClass);
        this.props.startHighlighting(true, hlClass);
    };

    startEraser = () => {
        this.resetActiveTimeout();
        console.log('started eraser');
        this.props.startErasing(true);
    };

    startPointer = () => {
        this.resetActiveTimeout();
        console.log('started pointer');
        this.props.startPointing();
    };

    /*
     * Book Navigation  (first, last, next, previous)
     */
    firstPage = (e) => {
        this.resetActiveTimeout();
        if (e) e.preventDefault();
        this.goToPage(1);
        document.dispatchEvent(new Event(BOOK_FIRST_PAGE));
    };

    lastPage = (e) => {
        this.resetActiveTimeout();
        if (e) e.preventDefault();
        this.goToPage(this.props.book.totalPage);
        document.dispatchEvent(new Event(BOOK_LAST_PAGE));
        // if (this.props.currentPage !== this.props.book.pagecount) {
        //   const pi = this.props.book.pagecount - this.props.pagesVisible + 1;
        //   this.goToPage(pi);
        // }
    };

    nextPage = (e) => {
        this.resetActiveTimeout();
        if (e) e.preventDefault();
        
        let pagesToChange = 1;
        
        if (!this.props.blmMode && this.props.pagesVisible === 2) {
            pagesToChange = 2;
        }
        
        if (this.props.currentPage >= this.props.totalPage) {
            return;
        }
        this.goToPage(this.props.currentPage + pagesToChange);
        document.dispatchEvent(new Event(BOOK_NEXT_PAGE));
    };

    prevPage = (e) => {
        this.resetActiveTimeout();
        if (e) e.preventDefault();
        let pagesToChange = 1;

        if (!this.props.blmMode && this.props.pagesVisible === 2) {
            pagesToChange = 2;
        }

        if (this.props.currentPage <= 0) {
            return;
        }
        this.goToPage(this.props.currentPage - pagesToChange);
        document.dispatchEvent(new Event(BOOK_PREV_PAGE));
    };

    /*
     ************************************************* CLICK SWIPE AND DRAG LISTENERS *****************************************
     */

    // TODO create a get relative position util function.  accepts pageW, pageH, layerX, layerY, offsetX, offsetY, scaleX, scaleY then returns offsetX and offsetY

    /*
     * respond to page taps.
     */
    pagesTapped = (e, pageNumber) => {
        this.resetActiveTimeout();
        // console.log('page tapped: ', e.type);
        if (
            e.target.className.includes('tappable') ||
            e.target.parentNode.className.includes('tappable')
        ) {
            this.wordTapped(e, pageNumber);
        }

        // on mobile Safari we use the e.originalEvent.layerX (with react e.nativeEvent.layerX) from the touch event, but on Chrome, touch event's don't have this.
        // we don't need it on desktop browsers because they will trigger a click event right after the touch event.
        if (e.type === 'touchend' && !e.nativeEvent.layerX) {
            return;
        }
    };

    updateMarkups = (whichSide, pid) => {
        this.updateHighlights(this.serializePageMarkups(whichSide), pid);
        rangy.getSelection().removeAllRanges(); // this only slightly helps to be debounced and we call it unnecessarily when erasing
        this.props.updatePendingItem(false);
    };

    /*
     * respond to word taps
     */
    wordTapped = (e, pageNumber) => {
        this.resetActiveTimeout();
        // console.log('word tapped', e.type);
        // if (config.System === 'mobile'){
        //   this.props.manualAjaxStart();
        // }
        // console.log($(e.currentTarget).text(), e);
        // console.log(this.state.highlighter);
        // console.log($('.pages .left').attr('id'));
        let erasing = this.props.bookToolbar.erasing;
        const pid = `page${pageNumber}`;
        const whichSide =
            this.props.book.currentPage === pageNumber ? 'Left' : 'Right';

        if (this.props.bookToolbar.highlighting) {
            /*
             * Before we added user-select: all to the .t class we had to manually highlight the
             * characters before applying the highlights with the code below:
             */
            // let sel = rangy.getSelection();
            // sel.selectCharacters(e.target, 0, e.target.textContent.length);

            /*
             * find similar markups and merge them in order to help iOS users tap to highlight
             */
            findSimilarMarkups(
                e.target,
                whichSide,
                pid,
                this.props.bookToolbar.highlightColor
            );

            // console.log('word tapped, highlighting selection', e.target.textContent.length, sel);
            this.state[`highlighter${whichSide}`].highlightSelection(
                this.props.bookToolbar.highlightColor,
                {
                    containerElementId: pid
                }
            );

            // this is blocking rendering the view, so we wait for view to show highlight before serializing
            // on iOS we need a much longer delay
            this.props.updatePendingItem(true);
            // if (config.System === 'mobile'){
            //   this.superDebouncedMarkupUpdate(whichSide, pid);
            // } else {
            this.debouncedMarkupUpdate(whichSide, pid);
            // }
        } else if (this.props.bookToolbar.erasing) {
            /*
             * since a word was tapped, we must manually
             * highlight the characters before applying highlights. only if it is not Mobile
             * we do not support highlighting a single word on mobile.  when on mobile we are erasing the entire selection.
             */
            // var sel = rangy.getSelection();
            // sel.selectCharacters(e.target, 0, e.target.textContent.length);
            this.state[`highlighter${whichSide}`].unhighlightSelection();

            // this is blocking rendering the view, so we wait for view to show highlight before serializing
            // on iOS we need a much longer delay
            this.props.updatePendingItem(true);
            // if (config.System === 'mobile'){
            //   this.superDebouncedMarkupUpdate(whichSide, pid);
            // } else {
            this.debouncedMarkupUpdate(whichSide, pid);
            // }

            // turn off erasing
            erasing = false;
        }
        this.updateViewerSettings({ highlighting: false }, () => {
            // if it was a touch event and we just erased something then automatically turn off erasing
            // commented out while we are only doing single tap markups
            if (e.type === 'touchend' && !erasing) {
                // this.startPointer();
            }
        });
        this.props.manualAjaxEnd();
    };

    /*
     * respond to mouse down
     */
    onMouseDown = (e, pageNumber) => {
        this.updateViewerSettings({
            highlightingMouseDown: true,
            highlightingPageNumber: pageNumber
        });
        this.mouseMove = 0;
    };

    /*
     * respond to mouse up
     */
    onMouseUp = (e, pageNumber) => {
        const pid = `page${pageNumber}`;
        const whichSide =
            this.props.book.currentPage === pageNumber ? 'Left' : 'Right';
        var sel = rangy.getSelection();
        if (
            this.props.bookToolbar.highlighting &&
            this.state.viewerSettings.highlightingMouseDown &&
            this.mouseMove > 1
        ) {
            this.state[`highlighter${whichSide}`].highlightSelection(
                this.props.bookToolbar.highlightColor,
                {
                    containerElementId: pid
                }
            );
            this.updateHighlights(this.serializePageMarkups(whichSide), pid);
            // remove the selection from view
            sel.removeAllRanges();
        } else if (
            this.props.bookToolbar.erasing &&
            this.state.viewerSettings.highlightingMouseDown &&
            this.mouseMove > 5
        ) {
            this.state[`highlighter${whichSide}`].unhighlightSelection();
            this.updateHighlights(this.serializePageMarkups(whichSide), pid);
        }
        this.updateViewerSettings({ highlightingMouseDown: false });
        this.mouseMove = 0;
    };

    onMouseMove = (e) => {
        this.mouseMove++;
    };

    /*
     * doTouchHighlight highlights the text after a touch devices has highlighted a piece of text and then chosen the highlight color
     */
    doTouchHighlight = (highlightClass) => {
        // get the current selection
        var sel = rangy.getSelection();
        const pid = `page${this.state.viewerSettings.highlightingPageNumber}`;
        const whichSide =
            this.props.book.currentPage ===
            this.state.viewerSettings.highlightingPageNumber
                ? 'Left'
                : 'Right';
        this.state[`highlighter${whichSide}`].highlightSelection(
            highlightClass,
            {
                containerElementId: pid
            }
        );
        this.updateHighlights(this.serializePageMarkups(whichSide), pid);
        // remove the selection from view
        sel.removeAllRanges();
        this.startPointer();

        // workaround the iOS bug that does not remove the highlight automatically
        $('.pages .left').click();
    };
    /*
     * save the markups to the server by sending all the highlights and the page id (pid) example: "page13"
     */
    updateHighlights = (highlight, pid) => {
        const newHighlights = Object.assign(
            {},
            this.props.book.highlights.Content,
            { [pid]: highlight }
        );
        const bookData = Object.assign(
            {},
            {
                Type: 2,
                Content: newHighlights,
                bookID: this.props.book.ID,
                LastDownloadedFromServer:
                    this.props.book.highlights.LastDownloadedFromServer
            }
        );
        this.props
            .saveBookItem(
                bookData,
                this.props.user,
                this.props.viewerMode,
                this.props.book.ID
            )
            .then((bi) => {
                console.log('saved book item');
            })
            .catch((error) => {
                console.error('Error saving book item', error);
                toastr.error(
                    'Unable to save markup.  Please try again or contact support.',
                    `Error Saving`,
                    constants.toastrErrorOptions
                );
            });
    };

    /*
     ************************************************* DRAWER *******************************************
     */
    goToNote = (note) => {
        this.resetActiveTimeout();
        const noteID = note.ID || note.TempID;
        this.goToPage(note.Page, () => {
            // give the page time to fade in
            setTimeout(() => {
                this.props.setActiveNote(noteID);
            }, 200);
        });
    };

    openDrawer = (drawerType = viewerDrawerTypeEnum.notes) => {
        this.resetActiveTimeout();
        const showDrawer = !this.state.viewerSettings.showDrawer;
        if (drawerType !== this.state.viewerSettings.drawerType) {
            this.updateViewerSettings({
                showDrawer: true,
                drawerType: drawerType
            });
        } else {
            this.updateViewerSettings({
                showDrawer: showDrawer,
                drawerType: drawerType
            });
        }
    };
    closeDrawer = () => {
        this.resetActiveTimeout();
        //close the drawer only if it is open this way we do not call updateViewerSettings too often
        if (this.state.viewerSettings.showDrawer) {
            this.updateViewerSettings({ showDrawer: false });
        }
    };

    /*
     ****************************************************** HEADER *************************************************
     */

    toggleHeader = () => {
        this.resetActiveTimeout();
        const self = this;
        if (this.state.viewerSettings.header.visible) {
            // TODO refactor to CSS animations
            $('.page-header').animate({ top: '-60px' }, 500);
            $('.toggle').animate({ top: '-25px' }, 500);
            self.updateViewerSettings({
                header: { visible: false, class: 'fa fa-angle-double-down' }
            });
        } else {
            $('.page-header').animate({ top: '0px' }, 500);
            $('.toggle').animate({ top: '35px' }, 500);
            self.updateViewerSettings({
                header: { visible: true, class: 'fa fa-angle-double-up' }
            });
        }
    };

    /*
     * toggle BLM mode
     */

    toggleBLMMode = (blmMode = false) => {
        this.resetActiveTimeout();
        if (this.props.itemSavePending && blmMode === false) {
            this.setState({
                showSaveProjectConfirm: true,
                saveProjectSubmit: () => {
                    removeQuery('blmID');
                    removeQuery('projectAssignmentID');
                    this.props.updatePendingItem(false);
                }
            });
        } else {
            this.props.updatePendingItem(false);
            if (blmMode === false) {
                removeQuery('blmID');
                removeQuery('projectAssignmentID');
            }
        }
    };

    closeBookView = () => {
        // students or .... are able to go to the bookbag, everyone else is logged out
        if (this.props.itemSavePending) {
            this.setState({
                showSaveProjectConfirm: true,
                saveProjectSubmit: () => {
                    this.props.updatePendingItem(false);
                    this.closeBookView();
                    this.setState({ showSaveProjectConfirm: false });
                }
            });
            return;
        }
        this.props.resetBookView();
        this.props.startPointing();
        this.props.setBookReady(false);
        this.props.setProjectReady(false);
        $('#pages').fadeTo('fast', 0.0);

        if (
            UserAPI.isStudent(this.props.user.RoleID) ||
            UserAPI.isGeneric(this.props.user.RoleID)
        ) {
            this.goToBag();
        } else {
            this.props.userLogout();
            hashHistory.replace('/');
        }
    };

    goToBag = () => {
        // TODO @jfbloom22 why do we empty blms?
        if (!this.props.itemSavePending) {
            this.props.emptyBLMs();
        }
        // let the API know user closed the book by passing -1 in as the page index
        this.props
            .updateBookStatus(
                this.props.book.ID,
                -1,
                this.props.book.pagecount,
                false,
                this.props.blmMode
            )
            .then((resp) => {
                console.log('updated book status - close book');
            })
            .catch((err) => {
                console.error('Error updating book status - close book', err);
                // I don't think we want to show an error if this does not work
                // toastr.error(`Please try again or contact support`, `Error Changing Page`, {
                //   closeButton: true,
                //   showAnimation: 'animated fadeInDown'
                // });
            });

        hashHistory.push({
            pathname: '/bag',
            query: this.props.location.query
        });
    };
    /*
     *
     * update the URL, close the drawer, change to the most recent book page
     * TODO going to need to do this for BLM, they will grab a BLM id
     */
    changeBook = (book) => {
        this.resetActiveTimeout();
        if (this.props.itemSavePending) {
            this.setState({
                showSaveProjectConfirm: true,
                saveProjectSubmit: () => {
                    this.changeBook(book);
                    this.setState({ showSaveProjectConfirm: false });
                    this.props.updatePendingItem(false);
                }
            });
            return;
        }
        if (book.IsExternal) {
            // open the book in the Lerner viewer, not in the DiBS viewer
            const externalURL = `${config.API.Main}/book/openexternalviewer?bookID=${book.ID}`;
            var win = window.open(externalURL, '_blank');
            win.focus();
            return;
        }
        this.props.setProjectReady(false);
        this.props.setBookReady(false);
        this.setState({ pages: {} }, () => {
            const query = { ...this.props.location.query, bookID: book.ID };
            hashHistory.replace({
                pathname: `/viewer`,
                query: query
            });
            this.closeDrawer();
            this.loadBook(book.ID).then(() => {
                this.toggleBLMMode(false);
                setTimeout(() => {
                    this.props.setBookReady(true);
                }, 100);
                $('#pages').fadeTo('fast', 1.0);
                this.initBLMs();
            });
        });
    };

    changeBlm = (projectAssignmentID, blmID) => {
        this.resetActiveTimeout();
        this.closeDrawer();
        this.props.setProjectReady(false);
        this.loadBLM(projectAssignmentID, blmID).then(() => {
            this.props.setProjectReady(true);
        }); // TODO this will get removed when we add some listeners in did receive props
    };

    /*
     **************************************************** GENERAL VIEWER FUNCTIONS *******************************************
     */

    fadeOut = (duration, cb) => {
        $('#pages').fadeTo(duration, 0.01, cb);
        // TODO verify that we do not need to handle fading out and in better.
        setTimeout(() => {
            $('#pages').fadeTo('fast', 1.0);
        }, 300);
    };
    /*
     * to support images that span two pages, we make sure we are displaying even or odd pages on the left
     * if left page is supposed to be even, if viewing two pages, if not BLM mode, if pi is odd then subtract one
     * if left page is supposed to be odd, and pi is not odd, then subtract one
     */
    checkLeftPageEven = (pi) => {
        function isOdd(num) {
            return num % 2;
        }
        if (
            this.props.book.LeftPageEven &&
            this.props.pagesVisible === 2 &&
            !this.props.blmMode &&
            isOdd(pi)
        ) {
            return pi - 1;
        } else if (
            !this.props.book.LeftPageEven &&
            this.props.pagesVisible === 2 &&
            !this.props.blmMode &&
            !isOdd(pi)
        ) {
            return pi - 1;
        } else {
            return pi;
        }
    };

    checkIfPageExists = (pi) => {
        // if the user tries to view a page and has a letter in the search, it will reject the search
        let re = /^\d+$/;
        if (!re.test(pi)) {
            return false;
        }
        // if it exceeds the page count
        // if it is 0 and we are viewing a single page or in blm mode
        // if it is -1
        if (
            pi > this.props.book.pagecount ||
            (pi <= 0 &&
                (this.props.pagesVisible === 1 || this.props.blmMode)) ||
            pi < 0
        ) {
            return false;
        }
        return true;
    };

    /*
     * go to a specific page
     */
    goToPage = (pi, cb, shouldCheckLeftPageEven = true) => {
        this.resetActiveTimeout();
        // only check the left page even if it is not the the initial load of the book
        if (shouldCheckLeftPageEven) {
            pi = this.checkLeftPageEven(pi); // make sure the correct page is displaying on the left
        }

        if (!this.checkIfPageExists(pi)) {
            if (!pi || (pi && pi === 0 && this.props.pagesVisible === 1)) {
                pi = 1;
            } else {
                toastr.error(
                    `Page does not exist`,
                    `The page you are looking for does not exist.`,
                    constants.toastrErrorOptions
                );
                return;
            }
        }

        if (pi === this.props.currentPage)
            if (!!cb) {
                return cb();
            } else {
                return;
            }
        // close any notes that happen to be open
        this.props.setActiveNote('');
        if (!!this.state.highlighterRight) {
            this.state.highlighterRight.removeAllHighlights();
        }
        if (!!this.state.highlighterLeft) {
            this.state.highlighterLeft.removeAllHighlights();
        }
        const self = this;
        self.fadeOut(200, function () {
            // update the current page number that we are on
            self.props.updateCurrentPage(pi, self.props.book.ID);
            self.props
                .updateBookStatus(
                    self.props.book.ID,
                    pi,
                    self.props.book.pagecount,
                    false,
                    self.props.blmMode
                )
                .catch((err) => {
                    console.warn('Error updating book status', err);
                });
            // sometimes we go to page 0 in order to display an even page on the left side, don't try to get the html
            if (pi !== 0) {
                self.getBookHTMLPages(self.props.book, pi, true);
            } else if (
                pi === 0 &&
                self.props.pagesVisible === 2 &&
                !self.props.blmMode
            ) {
                self.getBookHTMLPages(self.props.book, 1, true);
            }

            if (!!cb) {
                window.setTimeout(() => {
                    return cb();
                }, 750);
            }
        });
    };

    /*
     * Load the selected book
     * step 1 of 5 in loading a book page
     */
    loadBook = (bookID) => {
        $('#pages').fadeTo('fast', 0.0);
        return this.props
            .getBookByID(bookID, this.props.user)
            .then((book) => {
                console.log(
                    `opening book: ISBN: ${book.ISBN}, Title: ${book.Title}, ID: ${book.ID}`
                );
                if (book.IsEPub){
                    const httpPrefix = config.Debug ? 'http://' : 'https://';
                    this.setState({EPubUrl: `${httpPrefix}${window.location.host}${book.EBookPath}/${book.ISBN}`})
                    return Promise.resolve(true);
                }
                return this.getBookParts(book);
            })
            .catch((error) => {
                console.error('error loading book', error);
                let message = `We encountered an error loading the book.`;
                if (error.message) {
                    message = error.message;
                }
                this.closeBookView();
                if (error.status === 403) {
                    this.handleInvalidSession();
                    return;
                } else {
                    toastr.error(
                        `Error`,
                        message,
                        constants.toastrErrorOptions
                    );
                }
            });
    };

    /*
     * step 2 of 5 get the book
     * a book has 3 parts, the properties.json file, HTML pages, and book items
     */
    getBookParts = (book) => {
        let data = {};
        // start step 3
        return this.props
            .getBookProperties(book, this.props.user.AzureToken) // get book properties.json (part 1 of 3)
            .then((bookObj) => {
                // bookReducer UPDATE_BOOK_STATUS_SUCCESS deals with this logic, don't return this since we don't care if it fails
                this.props
                    .updateBookStatus(
                        bookObj.ID,
                        0,
                        bookObj.pagecount,
                        false,
                        this.props.blmMode
                    )
                    .then((resp) => {})
                    .catch((err) => {
                        console.error('Error updating book status', err);
                        // do not display an error if updating the status fails
                    });
                // we are returning the book obj along with the properties
                // start step 4a
                this.getBookHTMLPages(bookObj, this.props.currentPage, true); // get book html pages (part 2 of 3)

                // start step 4b - get student book items if user is a teacher viewing student book
                // MODE: teacher token to view a students items and pull up teacher items with that book
                // action: getTeacherBookItems shows the teacher the items they have made
                // teachers will not have downloaded books as they load in with a token (requiring internet support)
                if (
                    this.props.viewerMode ===
                    viewerModes.MODE_TEACHER_VIEW_STUDENT_BOOK
                ) {
                    Object.assign(data, {
                        book: book.ID,
                        classID: this.props.location.query.classID,
                        groupID: this.props.location.query.groupID,
                        studentID: this.props.location.query.studentID
                    });
                    let promises = [];
                    promises.push(
                        this.props.getBookItemsByStudentID(
                            book.ID,
                            this.props.location.query.studentID,
                            this.props.user
                        )
                    );
                    promises.push(
                        this.props.getTeacherBookItems(
                            data,
                            this.props.user,
                            book.ID
                        )
                    );
                    return Promise.all(promises);

                    // MODE: teacher token to view a group or class and pull up their teacher items with that class or group
                } else if (
                    this.props.viewerMode ===
                        viewerModes.MODE_TEACHER_GROUP_NOTES ||
                    this.props.viewerMode ===
                        viewerModes.MODE_TEACHER_CLASS_NOTES
                ) {
                    Object.assign(data, {
                        book: book.ID,
                        classID: this.props.location.query.classID,
                        groupID: this.props.location.query.groupID
                    });
                    return this.props.getTeacherBookItems(
                        data,
                        this.props.user,
                        book.ID
                    );

                    // MODE: teacher has a token to view a students blm, also grab items for the student
                } else if (
                    this.props.viewerMode ===
                    viewerModes.MODE_TEACHER_STUDENT_BLM
                ) {
                    Object.assign(data, {
                        book: book.ID,
                        classID: this.props.location.query.classID,
                        groupID: this.props.location.query.groupID,
                        StudentID: this.props.location.query.studentID
                    });
                    let promises = [];
                    promises.push(
                        this.props.getBookItemsByStudentID(
                            book.ID,
                            this.props.location.query.studentID,
                            this.props.user
                        )
                    );
                    promises.push(
                        this.props.getTeacherBookItems(
                            data,
                            this.props.user,
                            book.ID
                        )
                    );
                    return Promise.all(promises);
                } else if (UserAPI.isStudent(this.props.user.RoleID)) {
                    // TODO 2/16/18 determine this is online
                    // start step 4c - get the book items and set them in the redux store
                    // action: getBookItems shows the student the items they have made in the book
                    return this.props
                        .getBookItems(
                            book.ID,
                            this.props.user,
                            this.props.downloadedBooks
                        )
                        .then(() => {
                            // if offline and there are no highlights and no notes - then show an error. TODO add support for doing markups in multiple books while offline
                            // if (!this.props.isOnline && !this.props.book.highlights.Content && this.state.notes.length === 0 ){
                            //   this.updateViewerSettings({showToolbar: false});
                            //   throw {message: 'Please connect to the internet and re-open this book before doing any markups.'};
                            // }
                        }); // get book items (part 3 of 3)
                    // TODO 2/16/18 create another if statment determining if the user is a student and we are offline -->
                    // Load from downloaded books in redux where we have book items
                } else if (UserAPI.isGeneric(this.props.user.RoleID)) {
                    // console.log('not getting book items when a generic user.')
                    return Promise.resolve(true);
                } else if (this.props.viewerMode === viewerModes.MODE_GENERIC) {
                    // console.log('not getting book items when in generic mode.')
                    return Promise.resolve(true);
                } else if (
                    this.props.viewerMode === viewerModes.MODE_RESOURCE
                ) {
                    console.log('resource mode');
                    return Promise.resolve(true);
                } else {
                    return Promise.reject({
                        message: `not getting book items for this user: ${this.props.user.LoginID}`
                    });
                }
            });
    };

    /*
     * step 4a grab the HTML assets for the page passed in (left page) plus the nextpage (right page)
     * then wait 1.5 seconds and cache the next 2 pages, wait 2 seconds and cache the previous 2 pages
     *
     */
    getBookHTMLPages = (book, pi, cache) => {
        if (book.IsEPub) {
            return;
        }
        const lIndex = pi; // left page index
        const lpageKey = `page${lIndex}`; // left page key
        const rIndex = lIndex + 1; // right page index we manually add 1 to get the right pi
        const rpageKey = `page${rIndex}`; // right page key
        const pageIndexToCache = lIndex + this.props.pagesVisible;
        const previousPageIndexToCache = lIndex - this.props.pagesVisible;
        const pageIndexToCacheTwo = lIndex + this.props.pagesVisible * 2;

        // if left page does not already exist in state, then grab it from the server
        if (!this.props.book.cachedPages[lpageKey]) {
            this.getBookHTMLPage(lpageKey, book, lIndex);
        }
        // if right page does not already exist in state and the right page index is less then or equal to the total pages in the book
        // then grab the book page for the right side
        // TODO how does this work when we are in single page view?
        if (
            !this.props.book.cachedPages[rpageKey] &&
            rIndex <= book.pagecount
        ) {
            this.getBookHTMLPage(rpageKey, book, rIndex);
        }

        /*
         * should we cache and is the page index less than the total pages in the book
         * if yes and yes then cache them
         */
        if (cache && lIndex < book.pagecount - 1) {
            setTimeout(() => {
                this.getBookHTMLPages(this.props.book, pageIndexToCache, false);
            }, 1500);
        }

        /*
         * should we cache and is the page index more than 2
         * this caches the previous two pages
         */
        if (cache && lIndex > 2) {
            setTimeout(() => {
                this.getBookHTMLPages(
                    this.props.book,
                    previousPageIndexToCache,
                    false
                );
            }, 2500);
        }

        /*
         * should we cache and is the page index less than 4 pages ahead of the current index and less than the total pages in the book
         * this caches up to 4 pages ahead of the current viewer page index
         */
        if (
            cache &&
            lIndex - this.props.currentPage < 4 &&
            pageIndexToCacheTwo <= book.pagecount - 1
        ) {
            setTimeout(() => {
                this.getBookHTMLPages(
                    this.props.book,
                    pageIndexToCacheTwo,
                    false
                );
            }, 2000);
        }
    };

    /*
     * Get the assets for a single book HTML page and store them in cachedPages
     */
    getBookHTMLPage = (pageKey, bookObj, index) => {
        if (index === 0) {
            this.props.cacheBookPage({
                pageKey,
                pageHTML: '<div class="blank-page"> </div>',
                pageNumber: 0,
                width: bookObj.bounds[0][0],
                height: bookObj.bounds[0][1]
            });
            return;
        }
        // since the book pages are so temporary we do not store them in Redux
        return this.props
            .getSpeechMarks(bookObj, index, this.props.user.AzureToken, pageKey)
            .then(() => {
                return this.props
                    .getBookPage(
                        this.props.book,
                        index,
                        this.props.user.AzureToken,
                        pageKey
                    )
                    .then((pageHTML) => {
                        // console.info('retrieved book page', pageData);
                        if (!pageHTML) {
                            console.error('failed to process page', pageHTML);
                            throw new Error({
                                message: 'failed to process page'
                            });
                        }
                        this.props.cacheBookPage({
                            pageKey,
                            pageHTML,
                            pageNumber: index,
                            width: bookObj.bounds[index - 1][0],
                            height: bookObj.bounds[index - 1][1]
                        });
                    });
            })
            .catch((error) => {
                // check if we are online
                if (!this.props.isOnline) {
                    // if we do not have books and we are offline, we need to show an error
                    console.warn('unable to load page while offline');
                    toastr.error(
                        `No internet connection.`,
                        `Error Loading Book Page`,
                        constants.toastrErrorOptions
                    );
                    return;
                }
                console.error('Error trying to get book page', error);
                if (error.status === 403) {
                    this.handleInvalidSession();
                } else {
                    toastr.error(
                        `We encountered an error getting the book page.`,
                        `Error Getting Page`,
                        constants.toastrErrorOptions
                    );
                }
            });
    };

    loadBLM = (projectAssignmentID, blmID) => {
        // if the projectAssignmentID is empty, the current object is the blmID or vice versa
        // For example: blmCreator does not have a projectAssignmentID, only a blmID
        // A student does not use a blmID only a projectAssignmentID
        let currentObjectID;
        if (this.props.itemSavePending) {
            this.setState({
                showSaveProjectConfirm: true,
                saveProjectSubmit: () => {
                    this.loadBLM(projectAssignmentID, blmID);
                    removeQuery('blmID');
                    this.setState({ showSaveProjectConfirm: false });
                    this.props.updatePendingItem(false);
                }
            });
            return Promise.reject({ message: 'not saved' });
        }

        const query = {
            ...this.props.location.query,
            blmID,
            projectAssignmentID
        };
        hashHistory.replace({
            pathname: `/viewer`,
            query: query
        });

        // projectAssignmentID is empty here for a creator
        if (projectAssignmentID === '') {
            currentObjectID = blmID;
        } else {
            currentObjectID = projectAssignmentID;
        }
        return this.getBLMObject(currentObjectID)
            .then((blm) => {
                // need to not fade out if we have already loaded the blm
                if (
                    this.props.projectAssignmentID &&
                    this.props.blm.projectAssignmentID !==
                        blm.projectAssignmentID
                ) {
                    this.fadeOut(600, () => {});
                    return this.getBLMParts(blm);
                } else {
                    return this.getBLMParts(blm);
                }
            })
            .catch((err) => {
                console.error('unable to load BLM', err);
                toastr.error(
                    ``,
                    `Error Loading BLM`,
                    constants.toastrErrorOptions
                );
                throw err;
            });
    };

    getBLMObject = (projectAssignmentID) => {
        let blm = this.props.blm;

        // if the book is already in redux as the active book (this happens after token login and page refresh)
        // For a student, we check to see if projectAssignmentID equals the paID on the blm
        // For an admin making a blm, we do not have a projectAssignmentID, so we pass a blmID
        // For a teacher, we do not have a projectAssignmentID on the blm but we have it in props
        if (this.props.viewerMode === viewerModes.MODE_TEACHER_STUDENT_BLM) {
            if (this.props.projectAssignmentID === projectAssignmentID) {
                return Promise.resolve(blm);
            }
        } else {
            if (
                blm.projectAssignmentID === projectAssignmentID ||
                blm.ID === projectAssignmentID
            ) {
                return Promise.resolve(blm);
            }
        }

        blm = this.props.blms.filter((b) => {
            return b.projectAssignmentID === projectAssignmentID;
        })[0];
        if (blm) {
            return Promise.resolve(blm);
        } else {
            // console.error('unable to find the project in the array of projects');
            // return reject(false);
            // TODO enable downloaded BLMs ????
            // if we are on mobile or chrome then try to find it in the array of downloaded blms
            // if (config.System === 'chrome' || config.System === 'mobile'){
            //   blm = this.props.downloadedBlms.filter((b) => { return b.ID === blmID })[0];
            //   if (blm){
            //     console.log('have blm in downloadedBooks');
            //     resolve(blm);
            //   }
        }
        if (this.props.isOnline) {
            // we are online so grab it from the API
            return this.props
                .getBLMByID(this.props.blmID, this.props.user)
                .then((result) => {
                    // this.loadBook(bookSaved);
                    // book = result;
                    return result;
                });
        } else {
            return Promise.reject(false);
        }
    };

    getBLMParts = (blm) => {
        return this.props
            .getBLMProperties(blm, this.props.user.AzureToken) // step 1, get blm properties
            .then((blmObj) => {
                if (!this.props.blmMode) {
                    this.toggleBLMMode(true);
                }
                this.getBlmHtml(blmObj); // step 2, get blm html

                if (UserAPI.isStudent(this.props.user.RoleID)) {
                    return this.props
                        .getBLMStatus(blm, this.props.user)
                        .then(() => {
                            return this.props.getTeachersComment(
                                blm.projectAssignmentID,
                                blm.TeacherID,
                                this.props.user
                            );
                        })
                        .then(() => {
                            return this.props.getBLMItems(
                                blmObj.ID,
                                blm.projectAssignmentID,
                                this.props.user
                            );
                        })
                        .then(() => {
                            this.updateViewerSettings({
                                loadedProjectAssignmentID:
                                    blm.projectAssignmentID
                            });
                        });
                }

                // MODE: teacher has token to view a students blm, this call grabs the students blm items
                if (
                    this.props.viewerMode ===
                    viewerModes.MODE_TEACHER_STUDENT_BLM
                ) {
                    return this.props
                        .getStudentsBLMItems(
                            blm.ID,
                            this.props.projectAssignmentID,
                            this.props.location.query.studentID,
                            this.props.user
                        )
                        .then(() => {
                            this.updateViewerSettings({
                                loadedProjectAssignmentID:
                                    blm.projectAssignmentID
                            });
                        });

                    // MODE: teacher has token to create a blm template. They find any items they have previously made with the template
                } else if (
                    this.props.viewerMode ===
                    viewerModes.MODE_ADMIN_CREATE_TEMPLATE
                ) {
                    return this.props
                        .getTemplateItems(blmObj.ID, this.props.user)
                        .then(() => {
                            this.updateViewerSettings({
                                loadedProjectAssignmentID:
                                    blm.projectAssignmentID
                            });
                        });
                }
            })
            .catch((error) => {
                console.error('Error getting BLM', error);
                if (error.status === 403) {
                    this.handleInvalidSession();
                } else {
                    toastr.error(
                        `We encountered an error loading the BLM.`,
                        `Error Loading BLM`,
                        constants.toastrErrorOptions
                    );
                }
            });
    };
    handleInvalidSession = () => {
        toastr.warning(
            `Trying to fix an issue with the book.`,
            `Please wait a moment`,
            constants.toastrWarningOptions
        );
    };

    getBlmHtml = (blm) => {
        // console.log(blm, 'BLM getting html');
        // passing in blm, 1 (because there is always only 1 page of the BLM, and the azure token)
        BlmAPI.getBlmPageRemote(blm, 1, this.props.user.AzureToken)
            .then((blmPage) => {
                this.setState({ blmHtml: blmPage });
            })
            .catch((error) => {
                // check if we are online
                if (!this.props.isOnline) {
                    // if we do not have books and we are offline, we need to show an error
                    console.warn('unable to load BLM while offline');
                    toastr.error(
                        `No internet connection.`,
                        `Error Loading BLM`,
                        constants.toastrErrorOptions
                    );
                    return;
                }
                console.error('Error trying to get BLM', error);
                if (error.status === 403) {
                    this.handleInvalidSession();
                } else {
                    toastr.error(
                        `We encountered an error getting the BLM.`,
                        `Error Getting BLM`,
                        constants.toastrErrorOptions
                    );
                }
            });
    };

    render() {
        if (requireSignIn(this.props.user, this.props.location)) {
            return null;
        }
        let books;
        if (this.props.isOnline) {
            books = this.props.books;
        } else {
            books = this.props.downloadedBooks;
        }
        return (
            <div>
                {this.props.book.IsEPub && this.state.EPubUrl && (
                    <div style={{ position: 'relative', height: '90vh', marginTop: '45px'}}>
                        <EpubView
                            url={this.state.EPubUrl}
                            location={{ index: this.props.currentPage }}
                            locationChanged={(epubcifi) =>
                                console.log(epubcifi)
                            }
                            tocChanged={(toc) => console.log(toc)}
                            closeBookView={this.closeBookView}
                            // audioChanged={this.props.updateBookAudioUrl}
                        />
                    </div>
                )}
                {this.props.book.IsEPub === false && (
                    <Pages
                        ref={this.pagesRef}
                        user={this.props.user}
                        book={this.props.book}
                        blmMode={this.props.blmMode}
                        highlighterRight={this.state.highlighterRight}
                        highlighterLeft={this.state.highlighterLeft}
                        pagesVisible={this.props.pagesVisible}
                        nextPage={this.nextPage}
                        prevPage={this.prevPage}
                        onMouseDown={this.onMouseDown}
                        onMouseUp={this.onMouseUp}
                        onMouseMove={this.onMouseMove}
                        wordTapped={this.wordTapped}
                        pagesTapped={this.pagesTapped}
                        closeBookView={this.closeBookView}
                        blmHtml={this.state.blmHtml}
                        blm={this.props.blm}
                        location={this.props.location}
                        exitBlmMode={this.toggleBLMMode}
                        viewerSettings={this.state.viewerSettings}
                        isOnline={this.props.isOnline}
                        updateCurrentPage={this.props.updateCurrentPage}
                        updateLeftPageContainer={
                            this.props.updateLeftPageContainer
                        }
                        leftPageContainerWidth={
                            this.props.leftPageContainerWidth
                        }
                        leftPageContainerHeight={
                            this.props.leftPageContainerHeight
                        }
                        bookScalePercent={this.props.bookScalePercent}
                        updateBookScalePercent={
                            this.props.updateBookScalePercent
                        }
                        updateProjectScalePercent={
                            this.props.updateProjectScalePercent
                        }
                        projectScalePercent={this.props.projectScalePercent}
                        updatePagesVisible={this.props.updatePagesVisible}
                        automaticUpdatePagesVisible={
                            this.props.automaticUpdatePagesVisible
                        }
                        bookToolbar={this.props.bookToolbar}
                        resetActiveTimeout={this.resetActiveTimeout}
                        viewerMode={this.props.viewerMode}
                    />
                )}
                <div className="container-fluid modal-container">
                    {this.state.viewerSettings.showHeader && (
                        <HeaderContainer
                            toggleHeader={this.toggleHeader}
                            toggle={this.state.viewerSettings.header}
                            changeBook={this.changeBook}
                            openBLM={this.openBLM}
                            changeBlm={this.changeBlm}
                        />
                    )}

                    <Drawer
                        user={this.props.user}
                        book={this.props.book}
                        currentPage={this.props.currentPage}
                        goTo={this.goToNote}
                        goToPage={this.goToPage}
                        show={this.state.viewerSettings.showDrawer}
                        close={this.closeDrawer}
                        type={this.state.viewerSettings.drawerType}
                        location={this.props.location}
                        openDrawer={this.openDrawer}
                        blmMode={this.props.blmMode}
                        hideDrawer={this.state.viewerSettings.hideDrawer}
                        showHighlightsButton={
                            this.state.viewerSettings.showDrawerHighlightsButton
                        }
                    />
                    <Loading show={this.props.loading} />
                </div>
                <Toolbar
                    user={this.props.user}
                    book={this.props.book}
                    nextPage={this.nextPage}
                    prevPage={this.prevPage}
                    firstPage={this.firstPage}
                    lastPage={this.lastPage}
                    startHighlighter={this.startHighlighter}
                    startEraser={this.startEraser}
                    startPointer={this.startPointer}
                    startNotes={this.startNotes}
                    openDrawer={this.openDrawer}
                    viewerMode={this.props.viewerMode}
                    viewerSettings={this.state.viewerSettings}
                    goToPage={this.goToPage}
                    currentPage={this.props.book.currentPage || 0}
                    blmMode={this.props.blmMode}
                    pagesVisible={this.props.pagesVisible}
                    bookToolbar={this.props.bookToolbar}
                    increaseBookZoom={this.props.increaseBookZoom}
                    decreaseBookZoom={this.props.decreaseBookZoom}
                    bookIsZooming={this.props.bookIsZooming}
                    resetActiveTimeout={this.resetActiveTimeout}
                />
                <CommonModal
                    name="saveProjectConfirm"
                    className="common-modal"
                    bsSize="small"
                    title={`Are you sure?`}
                    children={
                        'You have unsaved work, please wait a moment then try again.'
                    }
                    modalVisable={this.state.showSaveProjectConfirm}
                    cancelText="Cancel"
                    submitText="Do not save"
                    cancel={() => {
                        this.setState({ showSaveProjectConfirm: false });
                    }}
                    submit={this.state.saveProjectSubmit}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.user,
        highlights: state.highlights,
        book: state.book,
        books: state.books,
        completedBLMs: state.completedBLMs,
        blms: state.blms,
        blm: state.blm,
        blmProperties: state.blmProperties,
        isOnline: state.offlineQueue.isOnline && navigator.onLine,
        loading: state.ajaxCallsInProgress > 0,
        downloadedBooks: state.downloadedBooks,
        currentPage: state.book.currentPage,
        totalPage: state.book.pagecount,
        leftPageContainerWidth: state.bookView.leftPageContainerWidth,
        leftPageContainerHeight: state.bookView.leftPageContainerHeight,
        bookScalePercent: state.bookView.bookScalePercent,
        projectScalePercent: state.bookView.projectScalePercent,
        blmMode: !!(
            ownProps.location.query.blmID &&
            ownProps.location.query.blmID.length > 0
        ),
        pagesVisible: state.bookView.pagesVisible,
        bookID: ownProps.location.query.bookID,
        viewerMode: ownProps.location.query.viewerMode,
        projectAssignmentID: ownProps.location.query.projectAssignmentID,
        blmID: ownProps.location.query.blmID,
        bookToolbar: state.bookToolbar,
        bookIsZooming: state.bookView.bookManualZoomLevel !== 1,
        itemSavePending: state.bookView.itemSavePending
    };
};

export default connect(mapStateToProps, {
    userLogout,
    getBookProperties,
    getBookItems,
    getBookItemsByStudentID,
    getTeacherBookItems,
    getBookPage,
    getSpeechMarks,
    searchBookBagBooks,
    saveBookItem,
    deleteBookItem,
    updateBookStatus,
    getAssignedBLMs,
    getBLMProperties,
    getBLMStatus,
    getTeachersComment,
    emptyBLMs,
    getBLMItems,
    getStudentsBLMItems,
    getTemplateItems,
    getBookByID,
    getBLMByID,
    manualAjaxStart,
    manualAjaxEnd,
    updateCurrentPage,
    nextPage,
    prevPage,
    cacheBookPage,
    updateLeftPageContainer,
    updateBookScalePercent,
    updateProjectScalePercent,
    updatePagesVisible,
    resetBookView,
    automaticUpdatePagesVisible,
    setBookReady,
    setProjectReady,
    startNotes,
    startPointing,
    startHighlighting,
    startErasing,
    startUnderlining,
    startStriking,
    setActiveNote,
    increaseBookZoom,
    decreaseBookZoom,
    startMoving,
    downloadSpeech,
    updatePendingItem,
    resetCachedBookPages,
})(withRouter(BookView));
