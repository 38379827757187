import { actions } from 'react-jplayer';

export const pause = (id) => {
    return function (dispatch, getState) {
        dispatch(actions.pause(id));
    };
};

export const stop = (id, media) => {
    return function (dispatch, getState) {
        dispatch(actions.pause(id, 0));
    };
};

export const setMedia = (id, media) => {
    return function (dispatch, getState) {
        dispatch(actions.setMedia(id, media));
    };
};

export const play = () => {
    return function (dispatch, getState) {
        if (getState().jPlayers.AudioPlayer.paused) {
            dispatch(actions.play('AudioPlayer'));
        } else {
            dispatch(actions.pause('AudioPlayer'));
        }
    };
};
