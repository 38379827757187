/*
 * This reducer contains all the visual state of the viewer
 */

import * as types from '../actions/actionTypes';
import initialState from './initialState';
import constants from '../constants/constants';

export default function bookViewReducer(state = initialState.bookView, action) {
    // dispatch all redux events as document events - probably a bad idea
    document.dispatchEvent(new CustomEvent(action.type, {detail: action}))
    switch (action.type) {
        case types.USER_LOGOUT_SUCCESS:
            return initialState.bookView;
        case types.RESET_VIEW:
            return initialState.bookView;
        case types.UPDATE_BOOK_SCALE_PERCENT:
            return { ...state, bookScalePercent: action.scale };
        case types.UPDATE_PROJECT_SCALE_PERCENT:
            return { ...state, projectScalePercent: action.scale };
        case types.UPDATE_LEFT_PAGE_CONTAINER:
            return {
                ...state,
                leftPageContainerWidth: action.width,
                leftPageContainerHeight: action.height
            };
        case types.UPDATE_PAGES_VISIBLE:
            return { ...state, pagesVisible: action.pagesVisible };
        case types.AUTOMATIC_UPDATE_PAGES_VISIBLE:
            if (state.automaticUpdatedPagesVisible) {
                return state;
            }
            return {
                ...state,
                pagesVisible: action.pagesVisible,
                automaticUpdatedPagesVisible: true
            };

        case types.BOOK_TOOLBAR_UPDATE_MODE:
            return { ...state, bookToolbar: action.bookToolbar };
        case types.SET_ACTIVE_NOTE:
            return { ...state, activeNoteID: action.noteID };
        case types.INCREASE_BOOK_ZOOM:
            const newZoomLevelIndex = state.bookManualZoomIndex + 1;
            const newZoomLevel = constants.bookZoomLevels[newZoomLevelIndex];
            if (newZoomLevel === undefined) {
                return state;
            }
            if (
                newZoomLevelIndex === initialState.bookView.bookManualZoomIndex
            ) {
                return {
                    ...state,
                    bookManualZooming: false,
                    bookManualZoomIndex: newZoomLevelIndex,
                    bookManualZoomLevel: newZoomLevel / 100
                };
            } else {
                return {
                    ...state,
                    bookManualZooming: true,
                    bookManualZoomIndex: newZoomLevelIndex,
                    bookManualZoomLevel: newZoomLevel / 100
                };
            }
        case types.DECREASE_BOOK_ZOOM:
            const newZoomLevelIndexD = state.bookManualZoomIndex - 1;
            const newZoomLevelD = constants.bookZoomLevels[newZoomLevelIndexD];
            if (newZoomLevelD === undefined) {
                return state;
            }
            if (
                newZoomLevelIndexD === initialState.bookView.bookManualZoomIndex
            ) {
                return {
                    ...state,
                    bookManualZooming: false,
                    bookManualZoomIndex: newZoomLevelIndexD,
                    bookManualZoomLevel: newZoomLevelD / 100
                };
            } else {
                return {
                    ...state,
                    bookManualZooming: true,
                    bookManualZoomIndex: newZoomLevelIndexD,
                    bookManualZoomLevel: newZoomLevelD / 100
                };
            }
        case types.UPDATE_PENDING_ITEM:
            return { ...state, itemSavePending: action.status };

        // case types.BOOK_UPDATE_AUDIO:
        //     return { ...state, audioUrl: action.audioUrl}

        default:
            return state;
    }
}
