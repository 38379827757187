import React from "react";
import { Button } from "react-bootstrap";

export function EpubAudio({ status, setStatus, show }) {
  if (show === false){
    return null;
  }
  return (
    <div style={{ zIndex: 99, position: "absolute", left: 0, bottom: 80 }}>
      <Button onClick={() => setStatus(!status)}>
        {status ? "Pause" : "Play"}
      </Button>
    </div>
  );
}
