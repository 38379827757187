import * as types from './actionTypes';
import { beginAjaxCall } from './ajaxStatusActions';
import BookAPI from '../api/bookAPI';
import UserAPI from '../api/userAPI';
import { forEach, uniqBy, find } from 'lodash';
import config from '../api/config';
import moment from 'moment';
import BookFS from '../api/bookFS';
import { debouncedCheckSession } from './userActions';
import { toastr } from 'react-redux-toastr';
import constants from '../constants/constants';
import initialState from '../reducers/initialState';

export const initialDashboardQuery = {
    tag: 'allTags',
    grl: '',
    type: 'All',
    activePage: 1,
    search: '',
    searchMode: 'local'
};

export const searchBookBagBooks = (
    isFiltersActive = false,
    query = initialDashboardQuery
) => {
    return (dispatch, getState) => {
        const {
            offlineQueue,
            books,
            downloadedBooks,
            user,
            bookbagFilters
        } = getState();
        if (!offlineQueue.isOnline || !navigator.onLine) {
            return;
        }
        const { activePage, search, type, tag, grl } = query;
        console.log('query', query);
        if (!query.searchMode || query.searchMode === 'local') {
            // if we have local books already and there are no search params, then don't show loading
            let showLoading = true;
            if (books.length > 0 && !isFiltersActive) {
                showLoading = false;
            }

            // when we are loading local books, we have them in redux already, we don't show loading.
            if (showLoading) dispatch(beginAjaxCall());
            return BookAPI.getBooks(
                activePage,
                search,
                type,
                tag,
                grl,
                user,
                bookbagFilters
            )
                .then((response) => {
                    if (response === null) {
                        response = [0, []];
                    }
                    const books = normalizeBookObjects(response[1], true);
                    const pageResults = response[0];
                    dispatch({
                        type: types.DASHBOARD_PAGE_RESULTS,
                        pageResults
                    });
                    dispatch({ type: types.LOAD_BOOKS_SUCCESS, books });
                    // dispatch({
                    //     type: "LOAD_BOOKS_SUCCESS",
                    //     books: [{
                    //         "Publisher": null,
                    //         "ID": "ddf6b481-770f-49d3-9c30-130ebaa08654",
                    //         "PublisherID": "2471b447-a506-4162-8e19-0939ee8d03f6",
                    //         "ISBN": "Chickens",
                    //         "Title": "#02 Made for Each Other",
                    //         "Description": "Tom Stone stepped into Seward High and into Maria McBride's life like a bolt of lightning. He's the perfect guy for Maria—nice, smart, and well-built. There's just one problem: his family. Tom's father is the town's new funeral director, and business is booming. The bodies are piling up thick and fast in Persephone Falls, Alaska, so Dr. Stone keeps Tom up late at night working in the funeral home. And it's clear that Dr. Stone and his creepy assistant, Graves, don't want Maria around. Maria knows Tom was made for her. She's determined to find out what Dr. Stone has against her. When Tom refuses to stand up to his father, Maria begins to stitch together the clues...and finds out that the Stones are into recycling in ways she never could have imagined.",
                    //         // "EBookUrl": "http://localhost:8080/books/ePUBS/Crabtree/test/unzipped/Chickens",
                    //         // "EBookUrl": "https://dibs-viewer-dev.herokuapp.com/books/ePUBS/Crabtree/test/unzipped/Chickens",
                    //         "EBookUrl": "books/Chickens", // for testing with npm start
                    //         "EBookPath": "/books/",
                    //         "GuidedReadingLevel": "",
                    //         "OfficialImage": "https://s3.amazonaws.com/dibsuploads/945fbd2e-e308-4bf2-a7d2-2f56a95a202f.jpg",
                    //         "ThumbnailImage": null,
                    //         "PreviewImage": null,
                    //         "FrontPhoto": null,
                    //         "BackPhoto": null,
                    //         "IsFiction": true,
                    //         "IsTeacherGuideAvailable": false,
                    //         "IsCustomTeacherGuideAvailable": false,
                    //         "IsApproved": true,
                    //         "IsExternal": false,
                    //         "ATOS": null,
                    //         "LEX": null,
                    //         "LeftPageEven": true,
                    //         "IsAudioAvailable": true,
                    //         "AudioVoices": null,
                    //         "FileUpdateDate": "1900-01-01T00:00:00",
                    //         "ProductURL": null,
                    //         "Series": null,
                    //         "FormatType": 0,
                    //         "CreateTime": "2016-02-09T03:50:31.493",
                    //         "UpdateTime": "2016-03-09T16:32:16.043",
                    //         "RequestAccountID": null,
                    //         "CurrentPage": 22,
                    //         "BLMs": null,
                    //         "IsAudioEnabled": true,
                    //         "IsStudentAdded": true,
                    //         "IsEPub": true
                    //     } ]
                    // });
                })
                .then(() => {
                    if (!isFiltersActive && downloadedBooks.length > 0) {
                        dispatch(
                            checkForUpdatedBookFiles(
                                books,
                                downloadedBooks,
                                user.AzureToken
                            )
                        );
                    } else {
                        return Promise.resolve(true);
                    }
                })
                .catch((error) => {
                    dispatch({ type: types.LOAD_BOOKS_FAILED, error });
                    console.error('Error loading books in bookbag', error);
                    if (error.status === 403) {
                        this.props.handleInvalidSession();
                    } else {
                        toastr.error(
                            `Error`,
                            'Unable to load books.  Please try again or contact support.',
                            constants.toastrErrorOptions
                        );
                    }
                    throw error;
                });
        } else if (query.searchMode === 'remote') {
            dispatch(beginAjaxCall());
            return BookAPI.searchBooks(
                activePage,
                search,
                type,
                tag,
                grl,
                user,
                bookbagFilters
            )
                .then((response) => {
                    if (response === null) {
                        response = [0, []];
                    }
                    const pageResults = response[0];
                    const books = normalizeBookObjects(response[1], false);
                    dispatch({ type: types.LOAD_BOOKS_SUCCESS, books });
                    dispatch({
                        type: types.DASHBOARD_PAGE_RESULTS,
                        pageResults
                    });
                    // books.pageResults = pgResult
                    // return books;
                })
                .catch((error) => {
                    dispatch({ type: types.LOAD_BOOKS_FAILED, error });
                    // this.setState({ loading: false });
                    // TODO for download books, could we get a specific error code if we are offline?
                    console.error('hit this error searching for books', error);
                    if (error.status === 403) {
                        this.props.handleInvalidSession();
                    } else {
                        toastr.error(
                            'Error searching for books',
                            `Error`,
                            constants.toastrErrorOptions
                        );
                    }
                    throw error;
                });
        }
    };
};

/*
 * compare the last updatedDate in downloadedBooks to books.  if books is newer, then re-download the book. FileUpdateDate
 */
export function checkForUpdatedBookFiles(books, downloadedBooks, AzureToken) {
    return function (dispatch, getState) {
        // console.log('checking for updated book files', JSON.stringify(downloadedBooks))
        forEach(downloadedBooks, (downloadedBook) => {
            const book = find(books, { ID: downloadedBook.ID });
            // console.log('book:', book.ID, 'downloadedBook:', downloadedBook.ID);
            if (
                book &&
                downloadedBook &&
                moment(downloadedBook.FileUpdateDate).isBefore(
                    book.FileUpdateDate
                )
            ) {
                // if (moment(downloadedBook.FileUpdateDate).isBefore("2000-01-01T00:00:00")){  // to test redownloading books
                console.log('new book files available, redownloading book');
                dispatch(downloadBook(book, true));
            }
        });
    };
}

/*
 * Add a book to the bookbag
 */
export function addBook(book) {
    return function (dispatch, getState) {
        const { user } = getState();
        return BookAPI.addBook(book.ID, user)
            .then((bool) => {
                if (bool) {
                    toastr.success(
                        `Successfully added!`,
                        `Success`,
                        constants.toastrSuccessOptions
                    );
                    const normalizedBook = normalizeBookObjects(
                        [book],
                        true
                    )[0];
                    dispatch({
                        type: types.ADD_BOOK_TO_BOOKBAG_SUCCESS,
                        normalizedBook
                    });
                } else {
                    toastr.warning(
                        `Looks like you have this book already`,
                        `Oops!`,
                        constants.toastrWarningOptions
                    );
                }
            })
            .catch((error) => {
                console.error('Error adding book', error);
                toastr.error(
                    `Error Loading Book.  ${error.statusText}`,
                    `Error`,
                    constants.toastrErrorOptions
                );
            });
    };
}

/*
 * Remove a book from the bookbag
 */
export function removeBook(bookID, bookIsbn) {
    return function (dispatch, getState) {
        const { user } = getState();
        return BookAPI.removeBook(bookID, user)
            .then((bool) => {
                if (bool) {
                    dispatch({
                        type: types.REMOVE_BOOK_TO_BOOKBAG_SUCCESS,
                        bookID
                    });
                    dispatch(deleteDownloadedBook(bookIsbn))
                } else {
                    toastr.error(
                        `Unable to remove book.`,
                        `Error`,
                        constants.toastrWarningOptions
                    );
                }
            })
            .catch((error) => {
                console.error('Error removing book', error);
                toastr.error(
                    `Unable to Remove Book.  ${error.statusText}`,
                    `Error`,
                    constants.toastrErrorOptions
                );
            });
    };
}

/*
 * get the book data by it's ID from redux, downloadBooks, books, or the API
 */
export function getBookByID(bookID, user) {
    return function (dispatch, getState) {
        let book = getState().book;
        
        // 1) if the book is already in redux as the active book (this happens after token login and page refresh)
        if (book.ID === bookID) {
            return Promise.resolve(book);
        }
        // 2) search downloadedBooks, downloadedBooks should have bookItems
        book = getState().downloadedBooks.filter((b) => {
            return b.ID === bookID;
        })[0];
        if (book) {
            dispatch({ type: types.LOAD_BOOK_SUCCESS, book });
            return Promise.resolve(book);
        }
        // 3) search books
        book = getState().books.filter((b) => {
            return b.ID === bookID;
        })[0];
        if (book) {
            dispatch({ type: types.LOAD_BOOK_SUCCESS, book });
            return Promise.resolve(book);
        }
        
        // 4) get it from the API
        // only users who are not a student should get here.  We do not keep track of page numbers or support offline bookitems
        if (getState().offlineQueue.isOnline && navigator.onLine) {
            dispatch(beginAjaxCall());
            return BookAPI.getBookByID(bookID, user)
                .then((book) => {
                    dispatch({ type: types.FETCH_BOOK_SUCCESS, book });
                    return book;
                })
                .catch((error) => {
                    dispatch({ type: types.FETCH_BOOK_FAILED, error });

                    throw error;
                });
        } else {
            return Promise.reject(
                'unable to get book object.  Please check your internet connection and try again'
            );
        }
    };
}

const normalizeBook = (book, pagesVisible, dispatch) => {
    const isOdd = (num) => {
        return num % 2;
    };
    const originalCurrentPage = book.CurrentPage || initialState.book.CurrentPage;
    let CurrentPage;
    if (book.LeftPageEven && pagesVisible === 2 && isOdd(originalCurrentPage)) {
        CurrentPage = originalCurrentPage - 1;
    } else if (
        !book.LeftPageEven &&
        pagesVisible === 2 &&
        !isOdd(originalCurrentPage)
    ) {
        CurrentPage = originalCurrentPage - 1;
    } else {
        CurrentPage = originalCurrentPage;
    }
    if (config.Debug) {
        book = { ...book, IsAudioEnabled: true, IsAudioAvailable: true }; // helping test when audio is not enabled
    }

    // if the book page is wider than it is tall, switch to single page view automatically
    const bookPageWidth = book.bounds[0][0];
    const bookPageHeight = book.bounds[0][1];
    if (bookPageWidth > bookPageHeight) {
        dispatch({
            type: types.AUTOMATIC_UPDATE_PAGES_VISIBLE,
            pagesVisible: 1
        });
    }
    return { ...book, CurrentPage };
};

/*
 * Get all the book items for the books the user has downloaded to their device
 * This helps us make sure their book items are up to date before the user goes offline
 */
export function getBookItemsList(downloadedBooks, user) {
    let bookIDs = [];
    forEach(downloadedBooks, (book) => {
        bookIDs.push(book.ID);
    });
    return function (dispatch, getState) {
        return BookAPI.getBookItemsList(bookIDs, user)
            .then((bookItems) => {
                forEach(bookItems, (bi) => {
                    bi.Content = JSON.parse(bi.Content);
                    dispatchBookItem(dispatch, bi, bi.BookID);
                });
            })
            .catch((err) => {
                dispatch({ type: types.FETCH_BOOKITEMS_FAILED, err });
                console.error('failed to fetch getBookItemsList');
                throw err;
            });
    };
}

/*
 * pass in the bookObj from the Books reducer or the DownloadedBooks reducer, as well as the Azure token
 * get get properties.json file from the file system or Azure
 * TODO improve hasBook by also checking downloadedBooks - this will help us verify that a file exists and that if finished downloading
 */
export function getBookProperties(bookObj, token) {
    return function (dispatch, getState) {
        const { pagesVisible } = getState().bookView;
        if (bookObj.IsEpub){
            return Promise.resolve(bookObj);
        }
        return BookFS.hasBook(bookObj).then((hasBook) => {
            const bookDownloaded = getState().downloadedBooks.find(
                (b) => b.ISBN === bookObj.ISBN
            );
            if (hasBook && bookDownloaded && bookDownloaded.bounds) {
                const book = normalizeBook(
                    bookDownloaded,
                    pagesVisible,
                    dispatch
                );
                dispatch({ type: types.FETCH_BOOK_SUCCESS, book }); // update the book in the book reducer
                dispatch({ type: types.DOWNLOADED_BOOK_SUCCESS, book }); // update the book in the downloadedBooksReducer
                return book;
            } else {
                if (!bookObj.EBookPath || !bookObj.ISBN || !token) {
                    dispatch({ type: types.FETCH_BOOK_FAILED });
                    throw new Error(
                        `missing book info path: ${bookObj.EBookPath} ISBN: ${bookObj.ISBN} token: ${token}`
                    );
                }
                // if the book has been downloaded, but the files are no longer there, re-download it
                if (hasBook === false && bookDownloaded){
                    return dispatch(downloadBook(bookDownloaded, true))
                }
                // show a special warning if we get here, we are offline and the book has been downloaded
                if (navigator.onLine === false && bookDownloaded){
                    throw new Error('The download of this book has been removed.  Please connect to the internet and re-download this book.')
                }
                return BookAPI.getBookProperties(bookObj, token)
                    .then((bookProperties) => {
                        const book = normalizeBook(
                            { ...bookObj, ...bookProperties },
                            pagesVisible,
                            dispatch
                        );
                        dispatch({ type: types.FETCH_BOOK_SUCCESS, book }); // update the book in the book reducer
                        // update the book in the downloadedBooksReducer if we have it downloaded
                        if (bookDownloaded){
                            dispatch({type: types.DOWNLOADED_BOOK_SUCCESS, book });  
                        }
                        return book;
                    })
                    .catch((err) => {
                        if (err.status === 403) {
                            debouncedCheckSession(getState().user, dispatch);
                        }
                        dispatch({ type: types.FETCH_BOOK_FAILED, err });
                        throw err;
                    });
            }
        });
    };
}

/*
 * get the page from local storage or remote?
 */
export function getBookPage(book, index, token, pageKey) {
    return function (dispatch, getState) {
        return BookFS.hasBook(book).then((hasBook) => {
            // console.log('checking for book page', hasBook, book.ISBN)
            if (hasBook) {
                return BookAPI.getBookPageLocal(book, index, pageKey);
            } else {
                return BookAPI.getBookPageRemote(
                    book,
                    index,
                    token,
                    pageKey
                ).catch((err) => {
                    if (err.status === 403) {
                        debouncedCheckSession(getState().user, dispatch);
                        throw err;
                    } else {
                        throw err;
                    }
                });
            }
        });
    };
}

/*
 * Download the book
 * download the book, then speechmarks, then book properties
 */
export function downloadBook(book, forceDownload = false) {
    return function (dispatch, getState) {
        return BookFS.hasBook(book).then((hasBook) => {
            if (hasBook && forceDownload === false) {
                console.log('have book, returning true');
                return Promise.resolve(true);
            } else {
                dispatch(beginAjaxCall());
                return BookAPI.downloadBook(book, getState().user.AzureToken)
                    .then(() => {
                        dispatch({ type: types.DOWNLOADED_BOOK_SUCCESS, book });
                        return dispatch(downloadSpeech(book)).then(()=>{
                            return dispatch(getBookProperties(book, getState().user.AzureToken))
                        });
                    })
                    .catch((error) => {
                        console.error('error downloading book', error);
                        let message =
                            'Error downloading book.  Please contact support or try again.';
                        if (error.status === 403) {
                            debouncedCheckSession(getState().user, dispatch);
                        }
                        if (error.status === 404) {
                            message = `Book not available for offline viewing (404). Please contact support or try again`;
                        }
                        toastr.error(
                            `Error`,
                            message,
                            constants.toastrErrorOptions
                        );
                        dispatch({ type: types.DOWNLOADED_BOOK_FAILED });
                        return Promise.reject(error);
                    });
            }
        });
    };
}

export function deleteDownloadedBook(isbn) {
    return function (dispatch, getState) {
        toastr.confirm(
            'Are you sure you want to remove this downloaded book?',
            {
                okText: 'Remove Download',
                cancelText: 'Cancel',
                onOk: () => {
                    BookFS.removeBook(isbn)
                        .then(() => {
                            console.log('removed downloaded book', isbn);
                        })
                        .catch((error) => {
                            console.error(
                                'ignoring error removing book',
                                error
                            );
                        });
                    dispatch({
                        type: types.DELETE_DOWNLOADED_BOOK,
                        payload: isbn
                    });
                },
                onCancel: () => {}
            }
        );
    };
}

export function downloadSpeech(book) {
    return function (dispatch, getState) {
        if (!book.IsAudioAvailable) {
            // TODO switch this to check !book.IsAudioEnabled || !book.IsAudioAvailable
            return Promise.resolve(true);
        } else {
            const speechDirectory = `books/${book.ISBN}/audio`;
            return BookFS.hasDirectory(speechDirectory).then((hasFile) => {
                if (hasFile) {
                    return true;
                } else {
                    dispatch(beginAjaxCall());
                    return BookAPI.downloadSpeech(
                        book,
                        getState().user.AzureToken
                    )
                        .then(() => {
                            dispatch({
                                type: types.DOWNLOADED_SPEECH_SUCCESS,
                                book
                            });
                        })
                        .catch((error) => {
                            if (error.status === 403) {
                                debouncedCheckSession(
                                    getState().user,
                                    dispatch
                                );
                                dispatch({
                                    type: types.DOWNLOADED_SPEECH_FAILED
                                });
                            }
                            dispatch({ type: types.DOWNLOADED_SPEECH_FAILED });
                            return Promise.reject(error);
                        });
                }
            });
        }
    };
}

/*
 * get audio for text to speech
 * TODO improve how we hanlde successfully getting the speechmark files, but not the audio files.  Curently if this happens
 * when the user clicks the play button we log an error but we show nothing to the user.
 */
export function getSpeechAudio(book, index, voice, token) {
    return function (dispatch, getState) {
        return BookFS.hasBook(book).then((hasBook) => {
            if (hasBook) {
                const speechDirectory = `books/${book.ISBN}/audio`;
                return BookFS.hasDirectory(speechDirectory).then((hasFile) => {
                    if (hasFile) {
                        return BookFS.getFileUrl(
                            book.ISBN,
                            `audio/default/${index}.mp3`
                        );
                    } else {
                        // if we don't have the file locally, try to get it from the server
                        const root = `${config.API.Storage}${book.EBookPath}${book.ISBN}/`;
                        return `${root}audio/${voice}/${index}.mp3${token}`;
                    }
                });
            } else {
                const root = `${config.API.Storage}${book.EBookPath}${book.ISBN}/`;
                return `${root}audio/${voice}/${index}.mp3${token}`;
            }
        });
    };
}
/*
 * getSpeechMarks determins if speechMarks is enabled then gets the marks locally or remotely
 */
export function getSpeechMarks(book, index, token, pageKey) {
    return function (dispatch, getState) {
        if (book.IsEpub){
            return Promise.resolve(true);
        }
        if (!book.IsAudioAvailable) {
            // test speechmarks - TODO switch this to check !book.IsAudioEnabled || !book.IsAudioAvailable
            // if audio is Not enabled, simply return true
            return Promise.resolve(true);
        } else {
            return BookFS.hasBook(book).then((hasBook) => {
                if (hasBook) {
                    return getSpeechMarksLocal(book, index).then((marks) => {
                        const parsedMarks = parseSpeechMarks(marks);
                        dispatch({
                            type: types.GET_SPEECH_MARKS,
                            parsedMarks,
                            pageKey
                        });
                    });
                } else {
                    return BookAPI.getSpeechMarksRemote(
                        book,
                        index,
                        token
                    ).then((marks) => {
                        const parsedMarks = parseSpeechMarks(marks);
                        // not including "SUCCESS" on end of dispatch type because we do not want to block the user
                        dispatch({
                            type: types.GET_SPEECH_MARKS,
                            parsedMarks,
                            pageKey
                        });
                    });
                }
            });
        }
    };
}

/*
 * utility function to normalize book objects
 */
function normalizeBookObjects(books, isInBookBag) {
    const flattenedBooks = books.map((book) => {
        return { ...book, ...book.Book, isInBookBag };
    });
    return uniqBy(flattenedBooks, 'ID');
    // const CurrentPage = book.CurrentPage || 1;
    // return {...book, CurrentPage}
}
/*
 * utility function to get the speechMarksData from filesystem
 */
function getSpeechMarksLocal(book, index) {
    return BookFS.getBookFileEntry(book.ISBN, `audio/default/${index}.marks`)
        .then((result) => {
            return result;
        })
        .catch((error) => {
            console.error('failed to get marks for:', book.ISBN, index);
            return Promise.resolve([]); // return an empty speechMarks for this page
        });
}
/*
 * utility function to parse the speech marks into proper JSON
 */
function parseSpeechMarks(marks) {
    // console.log('marks', marks)
    if (marks.length <= 0) {
        return marks;
    } else {
        const speechMarksArray = marks.trim().split(/\n/);
        return speechMarksArray.map((mark) => {
            return JSON.parse(mark);
        });
    }
}

/*
 * utility function to identify a single bookItem and dispatch it to the correct action
 */
function dispatchBookItem(dispatch, bi, bookID) {
    let currentDate = moment.utc().format();
    bi = Object.assign(bi, { LastDownloadedFromServer: currentDate });
    if (!bi) {
        throw new Error('missing book item');
    }
    switch (bi.Type) {
        case 1:
            dispatch({
                type: types.SAVE_BOOKITEM_BOOKMARK_SUCCESS,
                bookmark: bi,
                bookID
            });
            break;
        case 2:
            dispatch({
                type: types.SAVE_BOOKITEM_HIGHLIGHT_SUCCESS,
                highlights: bi,
                bookID
            });
            break;
        case 3:
            dispatch({
                type: types.SAVE_BOOKITEM_NOTE_SUCCESS,
                note: bi,
                bookID
            });
            break;
        case 4:
            // TODO should we save this offline?
            dispatch({
                type: types.SAVE_BOOKITEM_NOTE_SUCCESS,
                note: bi,
                bookID
            });
            break;
        case 5:
            dispatch({
                type: types.SAVE_BOOKITEM_NOTE_SUCCESS,
                note: bi,
                bookID
            });
            break;
        case 6:
            dispatch({
                type: types.SAVE_BOOKITEM_NOTE_SUCCESS,
                note: bi,
                bookID
            });
            break;
        default:
            console.error('received invalid bookItem.Type', bi);
    }
    return bi;
}

/*
 * utility function to parse the bookItems and return an object with the bookItems split into the appropriate object
 */
function parseBookItems(bookItems, user, bookID) {
    let currentDate = moment.utc().format();
    let highlights = {};
    let notes = [];
    let bookmarks = [];
    bookItems.sort((a, b) => {
        return a.Page - b.Page;
    });
    forEach(bookItems, (bi) => {
        let Content = {};
        try {
            Content = JSON.parse(bi.Content);
        } catch (error) {
            console.error('unable to parse book item', bi.Content);
            return;
        }
        bi = Object.assign(bi, {
            LastDownloadedFromServer: currentDate,
            Content
        });
        // if(UserAPI.isStudent(user.RoleID)) {  // commenting this out - TODO need to test opening books as different user types
        switch (bi.Type) {
            case 1:
                bookmarks.push(bi);
                break; // 1 = bookmark
            case 2:
                highlights = bi;
                break; // 2 = markup
            case 3:
                notes.push(bi);
                break; // 3 = student note
            case 4:
                notes.push(bi);
                break; // 4 = teacher note to student
            case 5:
                notes.push(bi);
                break; // 5 = teacher note to class
            case 6:
                notes.push(bi);
                break; // 6 = teacher note to group
            default:
                console.error('unknown bookitem type', bi);
        }
    });
    return { highlights, notes, bookmarks, bookID };
}

/*
 * Get the bookItems.
 * Pass in the bookID, user, and downloadedBooks
 * if we are offline, then return the bookItems from the downloadedBooks
 */
export function getBookItems(bookID, user, downloadedBooks) {
    return function (dispatch, getState) {
        // if (!getState().offlineQueue.isOnline || !navigator.onLine) {
        let downloadedBook = downloadedBooks.filter((book) => {
            return book.ID === bookID;
        })[0];
        if (!downloadedBook) {
            if (navigator.onLine === false) {
                throw new Error(
                    'Missing book in downloadedBooks, please connect to the internet.'
                );
            }
            dispatch(beginAjaxCall());
            return BookAPI.getBookItems(bookID, user)
                .then((bookItems) => {
                    const parsedBookItems = parseBookItems(
                        bookItems,
                        user,
                        bookID
                    );
                    //update the bookItems in the book reducer and the downloadedBooks reducer
                    dispatch({
                        type: types.FETCH_BOOKITEMS_SUCCESS,
                        ...parsedBookItems
                    });
                    return bookItems;
                })
                .catch((error) => {
                    dispatch({ type: types.FETCH_BOOKITEMS_FAILED, error });
                    throw error;
                });
        } else {
            dispatch({
                type: types.FETCH_BOOKITEMS_SUCCESS,
                bookmarks: downloadedBook.bookmarks,
                highlights: downloadedBook.highlights,
                notes: downloadedBook.notes,
                bookID: bookID
            }); // updates the bookItems in the book reducer and the downloadedBooks reducer
            return Promise.resolve('OFFLINE');
        }

        // } else {
        //   dispatch(beginAjaxCall());
        //   return BookAPI.getBookItems(bookID, user)
        //   .then((bookItems) => {
        //     const parsedBookItems = parseBookItems(bookItems, user, bookID);
        //     //update the bookItems in the book reducer and the downloadedBooks reducer
        //     dispatch({ type: types.FETCH_BOOKITEMS_SUCCESS, ...parsedBookItems });
        //     return bookItems;
        //   })
        //   .catch((error) => {
        //     dispatch({ type: types.FETCH_BOOKITEMS_FAILED, error });
        //     throw error;
        //   });
        // }
    };
}

export function getBookItemsByStudentID(bookID, StudentID, user) {
    return function (dispatch, getState) {
        if (!getState().offlineQueue.isOnline || !navigator.onLine) {
            return Promise.resolve('OFFLINE');
        }
        dispatch(beginAjaxCall());
        return BookAPI.getBookItemsByStudentID(bookID, StudentID, user)
            .then((bookItems) => {
                const parsedBookItems = parseBookItems(bookItems, user, bookID);
                dispatch({
                    type: types.FETCH_BOOKITEMS_STUDENT_SUCCESS,
                    ...parsedBookItems
                });
                return bookItems;
            })
            .catch((error) => {
                dispatch({ type: types.FETCH_BOOKITEMS_STUDENT_FAILED, error });
                throw error;
            });
    };
}

// We call this when a teacher gets a token to leave a note for a class or a group
// This is the TEACHERS items, meaning if they made a comment, they can what they
// have previously made when they come back in the future.

export function getTeacherBookItems(data, user, bookID) {
    return function (dispatch, getState) {
        if (!getState().offlineQueue.isOnline || !navigator.onLine) {
            return Promise.resolve('OFFLINE');
        }
        dispatch(beginAjaxCall());
        return BookAPI.getTeacherBookItems(data, user)
            .then((bookItems) => {
                let currentDate = moment.utc().format();
                let notes = [];
                bookItems.sort((a, b) => {
                    return a.Page > b.Page;
                });
                forEach(bookItems, (bi) => {
                    bi = Object.assign(bi, {
                        LastDownloadedFromServer: currentDate,
                        Content: JSON.parse(bi.Content)
                    });
                    if (bi.Type === 4) {
                        notes.push(bi);
                    } else if (bi.Type === 5) {
                        notes.push(bi);
                    } else if (bi.Type === 6) {
                        notes.push(bi);
                    }
                });
                dispatch({
                    type: types.FETCH_BOOKITEMS_SUCCESS,
                    notes,
                    bookID
                });
                return bookItems;
            })
            .catch((error) => {
                dispatch({ type: types.FETCH_BOOKITEMS_FAILED, error });
                throw error;
            });
    };
}
// Switch the if statments to case statments
export function saveBookItem(bookItem, user, viewerMode, bookID) {
    return function (dispatch, getState) {
        // TODO add lastBookItemChanges
        let currentDate = moment.utc().format();
        Object.assign(bookItem, { LastBookItemChange: currentDate });
        if (!getState().offlineQueue.isOnline || !navigator.onLine) {
            let saveBookID = bookID;
            dispatch({
                type: 'OFFLINE_BOOKITEM_SAVE',
                payload: {
                    action: 'saveBookItem',
                    args: [bookItem, user, viewerMode, saveBookID],
                    promise: {}
                },
                meta: {
                    queueIfOffline: true
                }
            });
            // Lets be optimistic and assume things will go well once we come back online!
            dispatchBookItem(dispatch, bookItem, saveBookID);
            return Promise.resolve('OFFLINE');
        }
        // Online.  this is the api call that persists bi-data bi-data = highlights and notes etc
        // If not a student, user should not be able to save highlights to the API
        // Checking if the roles are correct to save this item
        if (
            UserAPI.isStudent(user.RoleID) ||
            UserAPI.canAccessBook(user.RoleID, viewerMode)
        ) {
            // dispatch(beginAjaxCall()); // we probably do not want to block the user when saving bookItems
            return BookAPI.saveBookItem(bookItem, user)
                .then((bi) => {
                    bi.Content = JSON.parse(bi.Content);
                    const newBookItem = Object.assign({}, bookItem, bi);
                    dispatchBookItem(dispatch, newBookItem, bookID);
                    // dispatch({ type: types.PURGE_TEMP_ID_NOTES, bookID });
                })
                .catch((error) => {
                    dispatch({ type: types.SAVE_BOOKITEM_FAILED, error });
                    throw error;
                });
        } else {
            // only persist the book item locally
            dispatchBookItem(dispatch, bookItem, bookID);
            return Promise.resolve(bookItem);
        }
    };
}

export function deleteBookItem(bookItem, user, bookID) {
    return function (dispatch, getState) {
        if (!getState().offlineQueue.isOnline || !navigator.onLine) {
            dispatch({
                type: 'OFFLINE_BOOKITEM_DELETE',
                payload: {
                    action: 'deleteBookItem',
                    args: [bookItem, user, bookID],
                    promise: {}
                },
                meta: {
                    queueIfOffline: true
                }
            });
            // Lets be optimistic and assume things will go well once we come back online!
            if (bookItem.Type === 1) {
                dispatch({
                    type: types.DELETE_BOOKITEM_BOOKMARK_SUCCESS,
                    bookmark: bookItem,
                    bookID
                });
            } else if (bookItem.Type === 2) {
                console.error(
                    'we should not delete highlights, only save updated versions'
                );
            } else if (bookItem.Type === 3) {
                dispatch({
                    type: types.DELETE_BOOKITEM_NOTE_SUCCESS,
                    note: bookItem,
                    bookID
                });
            } else if (bookItem.Type === 4) {
                dispatch({
                    type: types.DELETE_BOOKITEM_NOTE_SUCCESS,
                    note: bookItem,
                    bookID
                });
            } else if (bookItem.Type === 5) {
                dispatch({
                    type: types.DELETE_BOOKITEM_NOTE_SUCCESS,
                    note: bookItem,
                    bookID
                });
            } else if (bookItem.Type === 6) {
                dispatch({
                    type: types.DELETE_BOOKITEM_NOTE_SUCCESS,
                    note: bookItem,
                    bookID
                });
            }
            return Promise.resolve('OFFLINE');
        }
        // dispatch(beginAjaxCall());  don't bother showing loading
        // TODO need to support deleting book items while offline and maybe for non student users
        return BookAPI.deleteBookItem(bookItem, user)
            .then((res) => {
                if (bookItem.Type === 1) {
                    dispatch({
                        type: types.DELETE_BOOKITEM_BOOKMARK_SUCCESS,
                        bookmark: bookItem,
                        bookID
                    });
                } else if (bookItem.Type === 2) {
                    console.error(
                        'we should not delete highlights, only save updated versions'
                    );
                } else if (bookItem.Type === 3) {
                    dispatch({
                        type: types.DELETE_BOOKITEM_NOTE_SUCCESS,
                        note: bookItem,
                        bookID
                    });
                } else if (bookItem.Type === 4) {
                    dispatch({
                        type: types.DELETE_BOOKITEM_NOTE_SUCCESS,
                        note: bookItem,
                        bookID
                    });
                } else if (bookItem.Type === 5) {
                    dispatch({
                        type: types.DELETE_BOOKITEM_NOTE_SUCCESS,
                        note: bookItem,
                        bookID
                    });
                } else if (bookItem.Type === 6) {
                    dispatch({
                        type: types.DELETE_BOOKITEM_NOTE_SUCCESS,
                        note: bookItem,
                        bookID
                    });
                }
                return res;
            })
            .catch((error) => {
                dispatch({ type: types.DELETE_BOOKITEM_FAILED, error });
                throw error;
            });
    };
}

/*
 * bookTimeout : boolean = if this book was closed by timeout
 */
export function updateBookStatus(
    bookID,
    pageIndex,
    totalPages,
    bookTimeout,
    blmMode
) {
    return function (dispatch, getState) {
        const { offlineQueue, bookView, user } = getState();
        const pageView =
            bookView.pagesVisible === 1 ||
            (bookView.pagesVisible === 2 && blmMode)
                ? 1
                : 2;
        if (!offlineQueue.isOnline || !navigator.onLine) {
            dispatch({
                type: 'OFFLINE_BOOKITEM_SAVE',
                payload: {
                    action: 'updateBookStatus',
                    args: [
                        bookID,
                        pageIndex,
                        totalPages,
                        pageView,
                        bookTimeout,
                        user
                    ],
                    promise: {}
                },
                meta: {
                    queueIfOffline: true
                }
            });
            // Lets be optimistic and assume things will go well once we come back online!
            dispatch({
                type: types.UPDATE_BOOKSTATUS_SUCCESS,
                bookID,
                pageIndex,
                totalPages
            });
            return Promise.resolve('OFFLINE');
        }
        // dispatch(beginAjaxCall());
        return UserAPI.updateBookStatus(
            bookID,
            pageIndex,
            totalPages,
            pageView,
            bookTimeout,
            user
        )
            .then((resp) => {
                dispatch({
                    type: types.UPDATE_BOOKSTATUS_SUCCESS,
                    bookID,
                    pageIndex,
                    totalPages
                });
                return resp;
            })
            .catch((error) => {
                dispatch({ type: types.UPDATE_BOOKSTATUS_FAILED, error });
                throw error;
            });
    };
}

export const updateCurrentPage = (currentPage, bookID) => ({
    type: types.UPDATE_CURRENT_PAGE,
    currentPage,
    bookID
});
export const nextPage = () => ({
    type: types.NEXT_PAGE
});

export const prevPage = () => ({
    type: types.PREV_PAGE
});

export const setTotalPage = (totalPage) => ({
    type: types.TOTAL_PAGE,
    totalPage,
});

export const cacheBookPage = (page) => ({
    type: types.CACHE_BOOK_PAGE,
    page
});

// export const resetBook = () => ({
//   type: types.RESET_BOOK
// });
export const setBookReady = (ready) => ({
    type: types.SET_BOOK_READY,
    ready
});

export const resetCachedBookPages = () => ({
    type: types.RESET_CACHED_BOOK_PAGES
});
// export const updateBookAudioUrl = (audioUrl) => ({
//     type: types.BOOK_UPDATE_AUDIO,
//     audioUrl
// });
