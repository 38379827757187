import React from 'react';
import { connect } from 'react-redux';
import config from '../../api/config';
import {
    saveAdvancedSearchFilter,
    clearAdvancedSearchFilter
} from '../../actions/bookbagFiltersActions';
import { Col, Row, FormGroup, FormControl, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import debounce from 'throttle-debounce/debounce';
import CommonModal from '../common/CommonModal';
import { MultiSelect } from 'react-selectize';
import { reject } from 'lodash';
import UserAPI from '../../api/userAPI';
import Select from 'react-select';

class AdvancedSearchModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showAdvancedSearch: false,
            advancedSearch: ''
        };
        this.debouncedAdvancedSearch = debounce(800, this.doAdvancedSearch);
    }

    clearAdvancedSearch = () => {
        this.props.clearAdvancedSearchFilter();
        this.props.cancel();
    };
    handleReadingLevelChange = (selectedReadingLevelTypeOption) => {
        this.props.saveAdvancedSearchFilter({ selectedReadingLevelTypeOption });
    };

    advancedSearchForm() {
        return (
            <div>
                <Row>
                    <Col xs={12}>
                        <p>
                            {this.state.loading && (
                                <label
                                    htmlFor="schools-autocomplete"
                                    className="schools-autocomplete-text"
                                >
                                    Searching for tags...{' '}
                                    <i className="fa fa-refresh fa-spin fa-fw" />
                                </label>
                            )}
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <FormGroup>
                            <MultiSelect
                                placeholder="Start typing Keyword/TAGS"
                                ref="select"
                                options={this.state.advancedSearchResults}
                                search={this.state.advancedSearch}
                                values={this.props.bookbagFilters.tags}
                                onValuesChange={(value) => {
                                    this.props.saveAdvancedSearchFilter({
                                        tags: value
                                    });
                                }}
                                closeOnSelect={true}
                                onSearchChange={(advancedSearch) => {
                                    if (
                                        this.state.advancedSearch ===
                                        advancedSearch
                                    )
                                        return;
                                    this.setState({ advancedSearch });

                                    if (advancedSearch.length > 0) {
                                        this.setState({ loading: true });
                                        if (!!this.req) {
                                            this.req.abort();
                                        }
                                        this.handleAdvancedSearchChange(
                                            advancedSearch
                                        );
                                    }
                                }}
                                uid={(item) => {
                                    return item.TagID;
                                }}
                                filterOptions={(options, search) => {
                                    return options;
                                }}
                                renderOption={(item) => {
                                    return (
                                        <div
                                            className="simple-option"
                                            style={{ fontSize: 12 }}
                                        >
                                            <div>
                                                <span
                                                    style={{
                                                        fontWeight: 'bold'
                                                    }}
                                                >
                                                    {item.name}
                                                </span>
                                                <span>
                                                    {'@' +
                                                        item.Name +
                                                        ' (' +
                                                        item.TagCount +
                                                        ')'}
                                                </span>
                                            </div>
                                        </div>
                                    );
                                }}
                                renderValue={(item) => {
                                    return (
                                        <div className="simple-value">
                                            <span
                                                onClick={() => {
                                                    let rejectedSearch = reject(
                                                        this.props
                                                            .bookbagFilters,
                                                        (search) => {
                                                            return (
                                                                search.TagID ===
                                                                item.TagID
                                                            );
                                                        }
                                                    );
                                                    this.props.saveAdvancedSearchFilter(
                                                        rejectedSearch
                                                    );
                                                }}
                                            >
                                                x
                                            </span>

                                            <span
                                                style={{ fontWeight: 'bold' }}
                                            >
                                                {item.name}
                                            </span>
                                            <span>
                                                {'@' +
                                                    item.Name +
                                                    ' (' +
                                                    item.TagCount +
                                                    ')'}
                                            </span>
                                        </div>
                                    );
                                }}
                                renderNoResultsFound={(value, search) => {
                                    return (
                                        <div
                                            className="no-results-found"
                                            style={{ fontSize: 13 }}
                                        >
                                            {typeof this.req === 'undefined' &&
                                            this.state.advancedSearch.length ===
                                                0
                                                ? 'type a few characters to kick off remote search'
                                                : 'No results found'}
                                        </div>
                                    );
                                }}
                            />
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col xs={6}>
                        <FormGroup>
                            <Select
                                options={[
                                    { label: 'ATOS', value: 'atos' },
                                    { label: 'GRL', value: 'grl' },
                                    { label: 'Lexile', value: 'lexile' }
                                ]}
                                onChange={this.handleReadingLevelChange}
                                value={
                                    this.props.bookbagFilters
                                        .selectedReadingLevelTypeOption
                                }
                                placeholder="Select Reading Level"
                                classNamePrefix="react-select"
                                isClearable={true}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={3}>
                        <FormGroup
                            controlId="readingMin"
                            // bsSize="sm"
                            // validationState={this.state.formValidations.loginID.validationState}
                        >
                            <FormControl
                                type="text"
                                name="readingMin"
                                placeholder="min"
                                value={this.props.bookbagFilters.readingMin}
                                onChange={(e) =>
                                    this.props.saveAdvancedSearchFilter({
                                        readingMin: e.target.value
                                    })
                                }
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={3}>
                        <FormGroup
                            controlId="readingMax"
                            // bsSize="sm"
                            // validationState={this.state.formValidations.loginID.validationState}
                        >
                            <FormControl
                                type="text"
                                name="readingMax"
                                placeholder="max"
                                value={this.props.bookbagFilters.readingMax}
                                onChange={(e) =>
                                    this.props.saveAdvancedSearchFilter({
                                        readingMax: e.target.value
                                    })
                                }
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <FormGroup
                            controlId="spanishOnly"
                            // bsSize="sm"
                            // validationState={this.state.formValidations.loginID.validationState}
                        >
                            <Form.Check
                                type="checkbox"
                                name="spanishOnly"
                                defaultChecked={
                                    this.props.bookbagFilters.spanishOnly
                                }
                                onChange={(e) =>
                                    this.props.saveAdvancedSearchFilter({
                                        spanishOnly: e.target.checked
                                    })
                                }
                                label="Spanish Only"
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        );
    }

    doAdvancedSearch(value) {
        UserAPI.getAdvancedSearch(value, this.props.user)
            .then((advancedSearchResults) => {
                this.setState({
                    advancedSearchResults: advancedSearchResults,
                    loading: false
                });
            })
            .catch((error) => {
                this.setState({ loading: false });
                console.error(error, 'Error with advanced search');
            });
    }

    handleAdvancedSearchChange(value) {
        this.debouncedAdvancedSearch(value);
    }
    render() {
        return (
            <div className="advanced-search-modal">
                <CommonModal
                    className="common-modal"
                    title={`Advanced Search`}
                    children={this.advancedSearchForm()}
                    modalVisable={this.props.show}
                    cancelText="Clear & Close"
                    submitText="Done"
                    cancel={this.clearAdvancedSearch}
                    submit={this.props.cancel}
                />
            </div>
        );
    }
}

AdvancedSearchModal.propTypes = {
    show: PropTypes.bool.isRequired,
    cancel: PropTypes.func.isRequired
};

function mapStateToProps(state, ownProps) {
    return {
        user: state.user,
        bookbagFilters: state.bookbagFilters,
        loading: state.ajaxCallsInProgress > 0,
        isOnline: state.offlineQueue.isOnline && navigator.onLine
    };
}

export default connect(mapStateToProps, {
    saveAdvancedSearchFilter,
    clearAdvancedSearchFilter
})(AdvancedSearchModal);
