import React, { Component, ReactElement } from 'react';
import { connect } from 'react-redux';
import defaultStyles from './EpubStyles';
import { EpubAudio } from './EpubAudio';
import {
    BOOK_FIRST_PAGE,
    BOOK_LAST_PAGE,
    BOOK_NEXT_PAGE,
    BOOK_PREV_PAGE
} from './events';
import {
    nextPage,
    prevPage,
    setTotalPage,
    updateCurrentPage
} from '../../actions/bookActions';
import { debounce } from 'lodash';
import * as types from '../../actions/actionTypes';
import { Dispatch } from 'redux';
import { IinitialState } from '../../reducers';
import { Ibook } from '../../models';
import { StyledButton } from '../../constants/styledComponents';
import constants from '../../constants/constants';
import { updatePagesVisible } from '../../actions/bookViewActions';

declare namespace ReadiumSDK {
    const Events: {
        CONTENT_DOCUMENT_LOADED: 'ContentDocumentLoaded';
        CONTENT_DOCUMENT_LOAD_START: 'ContentDocumentLoadStart';
        CONTENT_DOCUMENT_UNLOADED: 'ContentDocumentUnloaded';
        FXL_VIEW_RESIZED: 'FXLViewResized';
        MEDIA_OVERLAY_STATUS_CHANGED: 'MediaOverlayStatusChanged';
        MEDIA_OVERLAY_TTS_SPEAK: 'MediaOverlayTTSSpeak';
        MEDIA_OVERLAY_TTS_STOP: 'MediaOverlayTTSStop';
        PAGINATION_CHANGED: 'PaginationChanged';
        PLUGINS_LOADED: 'PluginsLoaded';
        READER_INITIALIZED: 'ReaderInitialized';
        READER_VIEW_CREATED: 'ReaderViewCreated';
        READER_VIEW_DESTROYED: 'ReaderViewDestroyed';
        SETTINGS_APPLIED: 'SettingsApplied';
    };
}

interface EpubViewOwnProps {
    url: string;
    loadingView: ReactElement;
    // audioChanged: (audio: string) => string;
    styles: { viewHolder: React.CSSProperties; view: React.CSSProperties };
    handleKeyPress: () => void;
    handleTextSelected: () => void;
}

interface Istate {
    isLoaded: boolean;
    playingMediaOverlay: boolean;
    mediaOverlayPlayable: boolean;
    readium: any;
}

interface EpubViewStateProps {
    readiumClass: any;
    book: Ibook;
    closeBookView: () => void;
    updatePagesVisible: (pages: number) => void;
}

interface EpubViewDispatchProps {
    setTotalPage: (totalPage: number) => void;
    updateCurrentPage: (pageNumber: number) => void;
}

type Iprops = EpubViewOwnProps & EpubViewDispatchProps & EpubViewStateProps;

class EpubView extends Component<Iprops, Istate> {
    static defaultProps = {
        loadingView: null,
        locationChanged: null,
        tocChanged: null,
        styles: defaultStyles,
        renditionOptions: {},
        epubInitOptions: {}
    };
    private readium: any;
    private debouncedSetState: any;
    private theme: any;

    constructor(props: Iprops) {
        super(props);
        this.state = {
            isLoaded: false,
            playingMediaOverlay: false,
            mediaOverlayPlayable: false,
            readium: {}
        };
        this.debouncedSetState = debounce(this.setState, 800).bind(this);
        this.theme = constants.themeProvider.activeTheme;
    }

    componentDidMount() {
        this.initBook();
        this.setEventHandler();
    }

    initBook() {
        const { readiumClass: RediumClass } = this.props;

        if (!RediumClass) {
            return;
        }
        const readerOptions = {
            needsFixedLayoutScalerWorkAround: false,
            // el:"#viewport",
            el: '#readium-id'
            // annotationCSSUrl: prefix + "/annotations.css",
            // mathJaxUrl: "/MathJax.js"
        };
        const readiumOptions = {
            // jsLibRoot: "../build-output/",
            cacheSizeEvictThreshold: undefined,
            useSimpleLoader: true, // false so we can load ZIP'ed EPUBs
            openBookOptions: {}
        };
        const readium = new RediumClass(readiumOptions, readerOptions);
        const openPageRequest = { idref: 'cover', elementCfi: '/0' }; //{idref: bookmark.idref, elementCfi: bookmark.contentCFI};

        this.readium = readium;

        readium.reader.on(
            ReadiumSDK.Events.PAGINATION_CHANGED,
            (pageChangeData: any) => {
                console.log('PAGE CHANGED:', pageChangeData);
                const { spineItem } = pageChangeData;
                this.props.updateCurrentPage(
                    spineItem.index ? spineItem.index + 1 : 0
                );
                console.log(
                    readium.reader.isMediaOverlayAvailable(),
                    readium.reader.isPlayingMediaOverlay()
                );
                this.debouncedSetState({
                    playingMediaOverlay:
                        !!readium.reader.isPlayingMediaOverlay(),
                    mediaOverlayPlayable:
                        readium.reader.isMediaOverlayAvailable()
                });
            }
        );

        readium.reader.on(
            ReadiumSDK.Events.MEDIA_OVERLAY_STATUS_CHANGED,
            (event: any) => {
                console.log('MEDIA OVERLAY CHANGED:', event);
                if (event.isPlaying != null) {
                    this.debouncedSetState({
                        playingMediaOverlay: event.isPlaying
                    });
                }
            }
        );
        readium.reader.on(ReadiumSDK.Events.FXL_VIEW_RESIZED, (event: any) => {
            console.log('view resized', readium.reader.getViewScale());
        });

        // Comment in the appropriate URL for Testing Epub
        // const ebookURL = 'books/Chickens';
        // const ebookURL = "http://localhost:8080/books/ePUBS/Crabtree/test/unzipped/Chickens";
        // const ebookURL = 'https://dibs-viewer-dev.herokuapp.com/books/ePUBS/Crabtree/test/unzipped/Chickens'
        if (!this.props.url) {
            return;
        }
        const ebookURL = this.props.url;
        readium.openPackageDocument(
            ebookURL,
            (packageDocument: any, options: any) => {
                if (!packageDocument) {
                    console.error('ERROR OPENING EBOOK: ');
                }
                const totalPage = packageDocument.spineLength();
                this.props.setTotalPage(totalPage);
                // console.log('readium reader', readium.reader);

                // change these values to affec the default state of the application's preferences at first-run.
                var defaultSettings = {
                    fontSize: 100,
                    fontSelection: 0, //0 is the index of default for our purposes.
                    syntheticSpread: 'auto',
                    scroll: 'auto',
                    columnGap: 60,
                    columnMaxWidth: 550,
                    columnMinWidth: 400
                };

                this.updateSettings(defaultSettings);
            },
            openPageRequest
        );
    }

    updatePagesVisble = (event: any) => {
        if (
            event.detail &&
            typeof event.detail.pagesVisible === 'number' &&
            event.detail.pagesVisible === 1
        ) {
            this.updateSettings({ syntheticSpread: 'single', scroll: 'auto' });
        } else {
            this.updateSettings({ syntheticSpread: 'double', scroll: 'auto' });
        }
    };

    updateSettings = (readerSettings: any) => {
        if (this.readium.reader) {
            this.readium.reader.updateSettings(readerSettings);
        }
    };

    componentWillUnmount() {
        // this.book = this.rendition = this.prevPage = this.nextPage = null;
        this.removeEventHandler();
    }

    componentDidUpdate(prevProps: Iprops) {
        if (
            prevProps.url !== this.props.url ||
            prevProps.readiumClass !== this.props.readiumClass
        ) {
            this.initBook();
        }
    }

    setEventHandler() {
        document.addEventListener(BOOK_NEXT_PAGE, this.nextPage);
        document.addEventListener(BOOK_PREV_PAGE, this.prevPage);
        document.addEventListener(BOOK_FIRST_PAGE, this.firstPage);
        document.addEventListener(BOOK_LAST_PAGE, this.lastPage);
        document.addEventListener(
            types.UPDATE_PAGES_VISIBLE,
            this.updatePagesVisble
        );
        document.addEventListener(
            types.INCREASE_BOOK_ZOOM,
            this.increaseBookZoom
        );
        document.addEventListener(
            types.DECREASE_BOOK_ZOOM,
            this.decreaseBookZoom
        );
    }

    removeEventHandler() {
        document.removeEventListener(BOOK_NEXT_PAGE, this.nextPage);
        document.removeEventListener(BOOK_PREV_PAGE, this.prevPage);
        document.removeEventListener(BOOK_FIRST_PAGE, this.firstPage);
        document.removeEventListener(BOOK_LAST_PAGE, this.lastPage);
        document.removeEventListener(
            types.UPDATE_PAGES_VISIBLE,
            this.updatePagesVisble
        );
        document.removeEventListener(
            types.INCREASE_BOOK_ZOOM,
            this.increaseBookZoom
        );
        document.removeEventListener(
            types.DECREASE_BOOK_ZOOM,
            this.decreaseBookZoom
        );
    }

    nextPage = () => {
        if (!this.readium) {
            return;
        }
        this.readium.reader.openPageNext();
    };

    prevPage = () => {
        this.readium && this.readium.reader.openPagePrev();
    };

    firstPage = () => {
        if (!this.readium) {
            return;
        }
        this.readium.reader.openPageIndex(0);
    };

    lastPage = () => {
        if (!this.readium) {
            return;
        }
        const lastSpine = this.readium.reader.spine().last();
        this.readium.reader.openPageIndex(lastSpine.index);
    };

    increaseBookZoom = (): void => {
        const currentScale = this.readium.reader.getViewScale();
        const newScale = (currentScale + 10) / 100;
        this.readium.reader.setZoom({ scale: newScale, style: 'user' });
    };
    decreaseBookZoom = (): void => {
        const currentScale = this.readium.reader.getViewScale();
        const newScale = (currentScale - 10) / 100;
        this.readium.reader.setZoom({ scale: newScale, style: 'user' });
    };

    updateMediaOverlayPlaying(status: boolean) {
        if (!this.state.mediaOverlayPlayable) {
            this.setState({
                playingMediaOverlay: false
            });
            return;
        }

        if (status) {
            this.readium.reader.playMediaOverlay();
        } else {
            this.readium.reader.pauseMediaOverlay();
        }
    }

    render() {
        const { styles } = this.props;
        return (
            <>
                <StyledButton
                    variant="link"
                    className="close-book"
                    css={this.theme.primaryIconOnlyButtonStyle}
                    onClick={() => this.props.closeBookView()}
                >
                    <i className="fa fa-times" aria-hidden="true"></i>
                </StyledButton>
                <div className="pages-visible-buttons">
                    <StyledButton
                        className="item icon-btn"
                        css={this.theme.primaryButtonStyle}
                        role="button"
                        onClick={() => this.props.updatePagesVisible(1)}
                    >
                        <i className="fa fa-file-o" aria-hidden="true"></i>
                    </StyledButton>
                    <StyledButton
                        className="item icon-btn"
                        css={this.theme.primaryButtonStyle}
                        role="button"
                        onClick={() => this.props.updatePagesVisible(2)}
                    >
                        <i className="fa fa-columns" aria-hidden="true"></i>
                    </StyledButton>
                </div>
                <div style={styles.viewHolder} id="readium-id"></div>
                <EpubAudio
                    status={this.state.playingMediaOverlay}
                    setStatus={this.updateMediaOverlayPlaying.bind(this)}
                    show={
                        true // temporarily force them to show this.props.book.IsAudioAvailable && this.props.book.IsAudioEnabled
                    }
                ></EpubAudio>
            </>
        );
    }
}

const mapStateToProps = (state: IinitialState) => ({
    readiumClass: state.readium.readiumClass,
    book: state.book
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    nextPage: () => dispatch(nextPage()),
    prevPage: () => dispatch(prevPage()),
    updatePagesVisible: (pages: number) => dispatch(updatePagesVisible(pages)),
    setTotalPage: (totalPage: number) => dispatch(setTotalPage(totalPage)),
    updateCurrentPage: (pageNumber: number) =>
        dispatch(updateCurrentPage(pageNumber))
});

export default connect(mapStateToProps, mapDispatchToProps)(EpubView);
