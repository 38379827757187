import * as React from 'react';

import {
    Button,
    Col,
    Dropdown,
    FormControl,
    FormGroup,
    Row
} from 'react-bootstrap';
import { StyledButton, StyledDiv } from '../../constants/styledComponents';

import CommonModal from '../common/CommonModal';
import FontAwesome from 'react-fontawesome';
import constants from '../../constants/constants';
import icon_eraser from '../../images/icon_eraser.png';
import icon_hl_green from '../../images/icon_hl_green.png';
import icon_hl_orange from '../../images/icon_hl_orange.png';
import icon_hl_teal from '../../images/icon_hl_teal.png';
import icon_hl_yellow from '../../images/icon_hl_yellow.png';
import icon_pen from '../../images/icon_pen.png';
import icon_pointer from '../../images/icon_pointer.png';
import move_icon from '../../images/move_icon.png';
import { Ibook, IbookToolbar, Iuser, IviewerSettings } from '../../models';
import { viewerModes } from '../../constants/viewerModes';

interface Iprops {
    lastPage: ()=>void;
    firstPage: ()=>void;
    nextPage: ()=>void;
    prevPage: ()=>void;
    user: Iuser;
    book: Ibook;
    startHighlighter: (e: any, color: string)=>void;
    startEraser: ()=>void;
    startPointer: ()=>void;
    startNotes: ()=>void;
    openDrawer: ()=>void;
    viewerSettings: IviewerSettings;
    currentPage: number;
    blmMode: boolean;
    pagesVisible: number;
    bookToolbar: IbookToolbar;
    decreaseBookZoom: ()=>void;
    increaseBookZoom: ()=>void;
    resetActiveTimeout: ()=>void;
    viewerMode?: string;
    goToPage: (pageNumber: number, callback?: ()=>void, shouldCheckLeftPageEven?: boolean)=>void;
    bookIsZooming: boolean;
}
interface Istate {
    showGoToPageModal: boolean;
            goToPage: string;
            showBookMarkupTools: boolean;
}
const theme = constants.themeProvider.activeTheme;

class Toolbar extends React.Component<Iprops, Istate> {
    private startedHighlighting = false;

    constructor(props: Iprops) {
        super(props);

        this.state = {
            showGoToPageModal: false,
            goToPage: '',
            showBookMarkupTools: this.shouldShowMarkupTools()
                
        };
        this.startedHighlighting = false;
    }
/**
 * @return {boolean}
 */
    shouldShowMarkupTools = (): boolean => {
        const hasCorrectViewerMode =  this.props.viewerMode !==
        viewerModes.MODE_TEACHER_VIEW_STUDENT_BOOK &&
    this.props.viewerMode !==
        viewerModes.MODE_TEACHER_CLASS_NOTES &&
    this.props.viewerMode !==
        viewerModes.MODE_TEACHER_GROUP_NOTES &&
    this.props.viewerMode !==
        viewerModes.MODE_TEACHER_STUDENT_BLM &&
    this.props.viewerMode !== viewerModes.MODE_RESOURCE;

    const {IsEPub} = this.props.book;
    return hasCorrectViewerMode && IsEPub === false;
    }

    startHL = (e: any, color: string) => {
        // console.log('starting hl', e.type)
        /*
         * stopPropegation rather than preventDefault because preventDefault on iOS leaves the selected text highlighted
         * and we do not want the text to remain highlighted.  Then you can't see the color of the highlight.
         * Unfortunately only dong stopPropagation does not prevent this from being clicked twice... so we have a timeout.
         * after further testing apparently we do not need to preventDefault here...  Will keep this around for a bit.
         */
        // if (this.startedHighlighting) return;
        // this.startedHighlighting = true;
        // setTimeout(() => {
        //   this.startedHighlighting = false;
        // }, 300)

        // if (e.type === 'touchend'){
        // e.stopPropagation();
        // }
        // e.preventDefault();

        this.props.startHighlighter(e, color);
    };
    closeGoToPageModal = () => {
        this.setState({ showGoToPageModal: false });
    };
    openGoToPageModal = (e: any) => {
        this.props.resetActiveTimeout();
        e.preventDefault();
        this.setState({ showGoToPageModal: true });
    };

    submitGoToPage = () => {
        this.props.resetActiveTimeout();
        this.setState(
            {
                showGoToPageModal: false
            },
            () => {
                this.props.goToPage(parseInt(this.state.goToPage, 10));
                this.setState({ goToPage: '' });
            }
        );
    };
    toggleColors = (isOpen: boolean, e: any) => {
        this.props.resetActiveTimeout();
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }
    };

    handleChange = (e: any) => {
        this.setState({
            goToPage: e.target.value
        });
    };

    inputForm = () => {
        return (
            <Row>
                <Col md={12} className="dibs-form">
                    <FormGroup>
                        <FormControl
                            type="tel"
                            placeholder="Go to page"
                            value={this.state.goToPage}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                </Col>
            </Row>
        );
    };

    render() {
        let icon_hl = icon_hl_yellow;
        let toolbarStyle, pointerIcon;
        if (this.props.bookToolbar.highlightColor === 'hl-green') {
            icon_hl = icon_hl_green;
        } else if (this.props.bookToolbar.highlightColor === 'hl-teal') {
            icon_hl = icon_hl_teal;
        }
        if (this.props.bookToolbar.highlightColor === 'hl-orange') {
            icon_hl = icon_hl_orange;
        }
        if (this.props.viewerSettings.showToolbar) {
            toolbarStyle = 'toolbar row';
        } else {
            toolbarStyle = 'toolbar row d-none';
        }
        if (this.props.bookIsZooming) {
            pointerIcon = move_icon;
        } else {
            pointerIcon = icon_pointer;
        }
        const bookToolbar = this.props.bookToolbar;
        const pointerClassName = bookToolbar.pointing
            ? 'item  hl-pointer active'
            : 'item  hl-pointer';
        const highlighterClassName =
            bookToolbar.highlighting &&
            bookToolbar.highlightColor !== 'hl-strikethrough' &&
            bookToolbar.highlightColor !== 'hl-underline'
                ? `item-child btn btn-light dropdown-toggle hl-highlight active`
                : 'item-child btn btn-light dropdown-toggle hl-highlight';
        const strikeClassName =
            bookToolbar.highlighting &&
            bookToolbar.highlightColor === 'hl-strikethrough'
                ? 'item icon-btn hl-strike active'
                : 'item icon-btn hl-strike';
        const underlineClassName =
            bookToolbar.highlighting &&
            bookToolbar.highlightColor === 'hl-underline'
                ? 'item icon-btn hl-under active'
                : 'item icon-btn hl-under';
        const eraseClassName = bookToolbar.erasing
            ? 'item hl-erase active'
            : 'item hl-erase';
        let notesClassName = bookToolbar.allowNotes
            ? 'item hl-notes active'
            : 'item hl-notes';
        if (this.props.book.IsEPub){
            notesClassName = 'hide'
        }
        // hide the bottom toolbar if in single page Project mode
        if (this.props.blmMode && this.props.pagesVisible === 1) {
            return null;
        }
        return (
            <StyledDiv
                className={toolbarStyle}
                css={{ 'border-top-color': theme.primaryBackground }}
            >
                <div className="col-md-5 left-tools">
                    <div className="iconTools">
                        {this.state.showBookMarkupTools && (
                            <span>
                                <Button
                                    className={pointerClassName}
                                    onClick={this.props.startPointer}
                                    variant="light"
                                    role="button"
                                    type="button"
                                    title="Pointer Tool"
                                >
                                    <img alt="" src={pointerIcon} />
                                </Button>
                                <Dropdown
                                    id="dropdown-custom-menu"
                                    className="dropup item"
                                    onToggle={(isOpen, e) =>
                                        this.toggleColors(isOpen, e)
                                    }
                                    drop="up"
                                >
                                    <Dropdown.Toggle
                                        className={highlighterClassName}
                                    >
                                        <img alt="" src={icon_hl} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdown-menu toolbar-menu">
                                        <Dropdown.Item
                                            className="hl-yellow btn"
                                            onClick={(e) => {
                                                this.startHL(e, 'hl-yellow');
                                            }}
                                            onTouchEnd={(e: any) => {
                                                this.startHL(e, 'hl-yellow');
                                            }}
                                        />
                                        <Dropdown.Item
                                            className="hl-orange btn"
                                            onClick={(e) => {
                                                this.startHL(e, 'hl-orange');
                                            }}
                                            onTouchEnd={(e: any) => {
                                                this.startHL(e, 'hl-orange');
                                            }}
                                        />
                                        <Dropdown.Item
                                            className="hl-green btn"
                                            onClick={(e) => {
                                                this.startHL(e, 'hl-green');
                                            }}
                                            onTouchEnd={(e: Event) => {
                                                this.startHL(e, 'hl-green');
                                            }}
                                        />
                                        <Dropdown.Item
                                            className="hl-teal btn"
                                            onClick={(e) => {
                                                this.startHL(e, 'hl-teal');
                                            }}
                                            onTouchEnd={(e: any) => {
                                                this.startHL(e, 'hl-teal');
                                            }}
                                        />
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Button
                                    className={strikeClassName}
                                    variant="light"
                                    onClick={(e) =>
                                        this.props.startHighlighter(
                                            e,
                                            'hl-strikethrough'
                                        )
                                    }
                                    onTouchEnd={(e) =>
                                        this.props.startHighlighter(
                                            e,
                                            'hl-strikethrough'
                                        )
                                    }
                                    role="button"
                                    type="button"
                                >
                                    <i
                                        className="fa fa-strikethrough"
                                        aria-hidden="true"
                                    ></i>
                                </Button>
                                <Button
                                    className={underlineClassName}
                                    variant="light"
                                    onClick={(e) =>
                                        this.props.startHighlighter(
                                            e,
                                            'hl-underline'
                                        )
                                    }
                                    onTouchEnd={(e) =>
                                        this.props.startHighlighter(
                                            e,
                                            'hl-underline'
                                        )
                                    }
                                    role="button"
                                    type="button"
                                >
                                    <i
                                        className="fa fa-underline"
                                        aria-hidden="true"
                                    ></i>
                                </Button>
                                <Button
                                    className={eraseClassName}
                                    variant="light"
                                    onClick={this.props.startEraser}
                                    role="button"
                                    type="button"
                                >
                                    <img alt="" src={icon_eraser} />
                                </Button>
                            </span>
                        )}
                        {/* notes button stays outside the span above for teachers to add notes */}
                        <Button
                            className={notesClassName}
                            variant="light"
                            onClick={this.props.startNotes}
                            role="button"
                            type="button"
                        >
                            <img alt="" src={icon_pen} />
                        </Button>
                    </div>
                </div>
                {/*
          <a className="item btn btn-default btn-yellow" href="#" role="button">
            <img alt="" src={icon_question}/>
          </a>
          <a className="item btn btn-default btn-yellow text-blue" href="#" role="button">
            <i className="fa fa-bookmark" aria-hidden="true"></i>
          </a>
          <OverlayTrigger placement="top" data-toggle="tooltip" overlay={nextPageTooltip}>
            <Button className="item" style={{cursor: 'pointer'}} variant="default" role="button" onClick={this.props.nextPage} >
              <i className="fa fa-angle-right" aria-hidden="true"></i>
            </Button>
          </OverlayTrigger>
          */}
                <div className="col-md-3 col-lg-2 text-center center-tools">
                    <StyledButton
                        className="item icon-btn btn-zoom"
                        variant="light"
                        css={theme.secondaryButtonStyle}
                        role="button"
                        type="button"
                        onClick={() => {
                            this.props.resetActiveTimeout();
                            this.props.decreaseBookZoom();
                        }}
                    >
                        <FontAwesome name="search-minus" />
                    </StyledButton>
                    <StyledButton
                        className="item icon-btn btn-zoom"
                        variant="light"
                        css={theme.secondaryButtonStyle}
                        role="button"
                        onClick={() => {
                            this.props.resetActiveTimeout();
                            this.props.increaseBookZoom();
                        }}
                    >
                        <FontAwesome name="search-plus" />
                    </StyledButton>
                </div>
                <div className="col-md-4 col-lg-5 text-right right-tools">
                    <Button
                        className="item icon-btn first-page"
                        variant="light"
                        role="button"
                        type="button"
                        onClick={this.props.firstPage}
                    >
                        <i
                            className="fa fa-angle-double-left"
                            aria-hidden="true"
                        ></i>
                    </Button>
                    <Button
                        className="item icon-btn"
                        variant="light"
                        role="button"
                        type="button"
                        onClick={this.props.prevPage}
                    >
                        <i className="fa fa-angle-left" aria-hidden="true"></i>
                    </Button>
                    <Button
                        onClick={this.openGoToPageModal}
                        variant="link"
                        className="item item-text toolbar-page-numbers"
                        role="button"
                    >
                        {this.props.currentPage}
                        {!this.props.blmMode &&
                            this.props.pagesVisible !== 1 &&
                            '-' + (this.props.currentPage + 1)}
                        /{this.props.book.pagecount}
                    </Button>
                    <Button
                        className="item icon-btn"
                        variant="light"
                        role="button"
                        type="button"
                        onClick={this.props.nextPage}
                    >
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                    </Button>
                    <Button
                        className="icon-btn item last-page"
                        variant="light"
                        role="button"
                        type="button"
                        onClick={this.props.lastPage}

                    >
                        <i
                            className="fa fa-angle-double-right"
                            aria-hidden="true"
                        ></i>
                    </Button>
                </div>
                <CommonModal
                    name="go-to-page"
                    className="common-modal"
                    bsSize="small"
                    title={`What page would you like to go to?`}
                    children={this.inputForm()}
                    modalVisable={this.state.showGoToPageModal}
                    cancelText="Cancel"
                    submitText="GO"
                    cancel={() => this.closeGoToPageModal()}
                    submit={this.submitGoToPage}
                />
            </StyledDiv>
        );
    }
}

export default Toolbar;
