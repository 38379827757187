import * as types from './actionTypes';
import UserAPI from '../api/userAPI';
import { beginAjaxCall } from './ajaxStatusActions';
import BookFS from '../api/bookFS';
import { debounce } from 'lodash';
import { toastr } from 'react-redux-toastr';
import { hashHistory } from 'react-router';
import { removeQuery } from '../vendor/utils-router';
import constants from '../constants/constants';

export function userLoginSuccess(user) {
    return { type: types.USER_LOGIN_SUCCESS, user };
}

export function userLoginFailed(error) {
    return { type: types.USER_LOGIN_FAILED, error };
}

export function userLogoutSuccess() {
    return { type: types.USER_LOGOUT_SUCCESS, user: {} };
}

export function userRegister(user) {
    return function (dispatch, getState) {
        dispatch(beginAjaxCall());
        var { gender, first, last, loginID, password, role, roleID } = user;
        return UserAPI.register(
            gender,
            first,
            last,
            loginID,
            password,
            roleID,
            role
        )
            .then((data) => {
                if (!data) {
                    // eslint-disable-next-line
                    throw 'unknown server error';
                } else {
                    dispatch(userLoginSuccess(data));
                    return data;
                }
            })
            .catch((error) => {
                dispatch(userLoginFailed(error));
                throw error;
            });
    };
}

export function userUpdateProfile(user, firstName, lastName, loginID) {
    return function (dispatch, getState) {
        dispatch(beginAjaxCall());
        return UserAPI.updateProfile(user, firstName, lastName, loginID)
            .then((data) => {
                if (!data) {
                    // eslint-disable-next-line
                    throw 'unknown server error';
                } else {
                    dispatch({ type: types.USER_UPDATE_SUCCESS, user: data });
                    return data;
                }
            })
            .catch((error) => {
                dispatch({ type: types.USER_UPDATE_FAILED, error });
                throw error;
            });
    };
}

export function addClassCode(code) {
    return function (dispatch, getState) {
        dispatch(beginAjaxCall());
        const {user} = getState();
        return UserAPI.addClassCode(user, code)
            .then((data) => {
                if (!data) {
                    // eslint-disable-next-line
                    throw 'unknown server error';
                } else {
                    if (typeof data === 'string' && data.search('error') !== -1){
                        const stringifiedData = data.replace("error", "'error'").replaceAll('"', '').replaceAll("'", '"');
                        const parsedData = JSON.parse(stringifiedData);
                        if (parsedData.error){
                            throw new Error(parsedData.error); 
                        }
                    }
                    dispatch({ type: types.USER_ADD_CLASS_CODE_SUCCESS, user: data });
                    dispatch(toggleClassCodeModal());
                    toastr.success('Success', `${data} added successfully.`, constants.toastrSuccessOptions)
                }
            })
            .catch((error) => {
                console.error('error adding classcode', error)
                dispatch({ type: types.USER_ADD_CLASS_CODE_FAILED, error });
                let message = 'Error adding class or group code.  Please try again or contact support.';
                if (error && error.message){
                    message = error.message;
                }
                toastr.error('Error', message, constants.toastrErrorOptions)
            });
    };
}

export const toggleClassCodeModal = (show) => ({
    type: types.TOGGLE_CLASS_CODE_MODAL,
    show
});

export function userUpdatePW(user, password) {
    return function (dispatch, getState) {
        dispatch(beginAjaxCall());
        return UserAPI.updateProfilePW(user, password)
            .then((data) => {
                if (!data) {
                    // eslint-disable-next-line
                    throw 'unknown server error';
                } else {
                    dispatch({
                        type: types.USER_UPDATE_PASSWORD_SUCCESS,
                        user: data
                    });
                    return data;
                }
            })
            .catch((error) => {
                dispatch({ type: types.USER_UPDATE_PASSWORD_FAILED, error });
                throw error;
            });
    };
}

export function userLogin(loginID, password, schoolID) {
    return function (dispatch, getState) {
        dispatch(beginAjaxCall());
        return UserAPI.login(loginID, password, schoolID)
            .then((data) => {
                if (!data) {
                    // eslint-disable-next-line
                    throw 'unknown server error';
                } else {
                    dispatch(userLoginSuccess(data));
                    dispatch(checkSession(data)); // check session in order to get the School
                    return data;
                }
            })
            .catch((error) => {
                dispatch(userLoginFailed(error));
                throw error;
            });
    };
}

export function confirmChangeSchoolID(schoolID) {
    return function (dispatch, getState) {
        const onOk = () => {
            if (schoolID){
                hashHistory.replace(`/?changeSchoolID=${schoolID}`);
            } else {
                hashHistory.replace(`/`);
            }
        };
        toastr.confirm(
            'You are already logged into another school/user account.  Do you want to logout?',
            {
                okText: 'Logout',
                cancelText: 'Cancel',
                onOk,
                onCancel: () => {
                    removeQuery(['schoolID']);
                    removeQuery(['SchoolID']);
                    removeQuery(['schoolid']);
                    removeQuery(['schoolId']);
                    window.location.reload();
                }
            }
        );
    };
}

export function userLogout() {
    return function (dispatch, getState) {
        dispatch(beginAjaxCall());
        return new Promise((resolve, reject) => {
            dispatch(userLogoutSuccess());
            BookFS.removeAll()
                .then(() => {
                    console.log('successfully removed all directories');
                    resolve();
                })
                .catch((err) => {
                    console.error(err);
                    resolve();
                });
        });
    };
}
export function userLogoutSessionOnly() {
    return function (dispatch, getState) {
        dispatch(beginAjaxCall());
        return new Promise((resolve, reject) => {
            dispatch(userLogoutSuccess());
        });
    };
}

/*
 * Token Login for Teachers
 */
export function tokenLogin(token) {
    return function (dispatch, getState) {
        dispatch(beginAjaxCall());
        return UserAPI.tokenLogin(token)
            .then((data) => {
                if (!data) {
                    return Promise.reject({
                        message: `token login failed, no data`
                    });
                } else {
                    dispatch(userLoginSuccess(data));
                    return data;
                }
            })
            .catch((error) => {
                dispatch(userLoginFailed(error));
                return Promise.reject(error);
            });
    };
}

/*
 * Google Login for web and iOS
 */
export function googleTokenLogin(authorizationCode, schoolID) {
    return function (dispatch, getState) {
        dispatch(beginAjaxCall());
        return UserAPI.googleTokenLogin(authorizationCode, schoolID)
            .then((data) => {
                dispatch(userLoginSuccess(data));
                return data;
            })
            .catch((error) => {
                dispatch(userLoginFailed(error));
                return Promise.reject(error);
            });
    };
}

/*
 * CheckSession has 2 purposes.  1 is to verify that the DiBS API key is valid and 2 to renew the Azure token
 */
export function checkSession(user) {
    return function (dispatch, getState) {
        // dispatch(beginAjaxCall());  // no need to block the user while we check session
        return UserAPI.checkSession(user)
            .then((data) => {
                if (!data) {
                    // eslint-disable-next-line
                    throw 'invalidApiKey';
                } else {
                    dispatch({ type: types.USER_UPDATE_SUCCESS, user: data });
                    return data;
                }
            })
            .catch((error) => {
                dispatch({ type: types.USER_UPDATE_FAILED, error });
                throw error;
            });
    };
}

/*
 * checkSessionUtil and DebouncedCheckSession handle expired Azure tokens.
 * it is debounced because when we get the files for a page we get 30+ files.
 * we only want to call this once if all 30 of those fail
 * after it renews the Azure token get the book again via an event listener in the bookView
 * the other time the Azure token fails is in the bookView component where it gets the BLM files - this is not curently handled
 * TODO turn all the BLM file getters into Actions and remove BLMAPI from bookview then handle expired Azure tokens
 */
function checkSessionUtil(user, dispatch) {
    return UserAPI.checkSession(user)
        .then((data) => {
            if (!data) {
                // eslint-disable-next-line
                throw 'unknown server error';
            } else {
                dispatch({ type: types.USER_UPDATE_SUCCESS, user: data });
                // TODO move getBookHTMLPages from the BookView into the bookActions and then call it here.
                setTimeout(() => {
                    document.dispatchEvent(new Event('renewedAzureToken'));
                }, 100);
            }
        })
        .catch((error) => {
            dispatch({ type: types.USER_UPDATE_FAILED, error });
            throw error;
        });
}
export const debouncedCheckSession = debounce(checkSessionUtil, 5000);

export function isTouchDevice() {
    if ('ontouchstart' in window || navigator.maxTouchPoints) {
        return true;
    } else {
        return false;
    }
}
