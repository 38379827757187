import JPlayer, {
    Audio,
    BrowserUnsupported,
    Gui,
    initializeOptions
} from 'react-jplayer';
import React, { Component } from 'react';
import { pause, play, setMedia, stop } from '../../actions/audioPlayerActions';

import FontAwesome from 'react-fontawesome';
import { StyledButton } from '../../constants/styledComponents';
import UserAPI from '../../api/userAPI';
import { connect } from 'react-redux';
import constants from '../../constants/constants';
import { getSpeechAudio } from '../../actions/bookActions';
import $ from 'jquery';

// const intro = require('../../../public/audio/8.mp3'); // test audio

const defaultOptions = {
    id: 'AudioPlayer',
    keyEnabled: true,
    verticalVolume: true,
    media: {
        title: 'Bubble',
        artist: 'Miaow',
        sources: {},
        free: false
    }
};

initializeOptions(defaultOptions);

class AudioPlayer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPlayingRightPage: false,
            voice: 'default',
            showPlayer: false
        };
        this.updateAudioHighlights = this.updateAudioHighlights.bind(this);
        this.unHightlightAudioText = this.unHightlightAudioText.bind(this);
        this.startAudio = this.startAudio.bind(this);
        this.stopAudio = this.stopAudio.bind(this);
        this.shouldLoadAudio = this.shouldLoadAudio.bind(this);
        this.getPageKey = this.getPageKey.bind(this);

        this.highlightedSpeechMarks = [];
        this.speechMarkIndex = 0;
        this.timeouts = [];
        this.pendingResync = false;
        this.theme = constants.themeProvider.activeTheme;
    }

    componentDidMount() {
        if (window.cordova) {
            // audio on the device uses the file system, so lets wait for it to be ready
            document.addEventListener(
                'deviceready',
                () => {
                    this.shouldLoadAudio(this.getPageKey());
                },
                false
            );
        } else {
            this.shouldLoadAudio(this.getPageKey());
        }

        // TODO listen for an event that the audio files have been downloaded
    }
    componentDidUpdate(prevProps) {
        // if (prevProps.audioPaused === true && this.props.audioPaused === false){
        //   if (this.shouldLoadAudio(this.getPageKey())){
        //     this.startAudio();
        //   } else {
        //     alert('no audio for these pages');
        //   }
        // }
        if (
            prevProps.audioPaused === false &&
            this.props.audioPaused === true
        ) {
            if (this.props.audioTime === this.props.duration) {
                console.log('reached end of audio file');
                this.handleEndOfAudio();
            } else {
                // paused in the middle of a page
                // reset the last 3 highlighted words so that we are ready to go when start playing again
                this.highlightedSpeechMarks.splice(-3, 3);
                this.recentlyPaused = true;
                // loop over all timeouts and cancel them
                this.timeouts.forEach((timeout) => {
                    clearTimeout(timeout);
                });
                // remove all highlights
                $(`[data-time-start]`).removeClass('audio-highlight-text');
            }
        }

        // if the payer is playing and there is a pendingResync then resync and start the audio
        if (prevProps.audioTime !== this.props.audioTime) {
            // let startTime = parseInt(this.props.audioTime*1000, 10) || 50;
            // console.log('time from player', startTime);
            if (this.pendingResync) {
                console.log('resyncing audio highlights');
                this.pendingResync = false;
                this.startAudio();
            }
        }

        // if the page changes, stop the audio
        if (prevProps.currentPage !== this.props.currentPage) {
            this.stopAudio();
            this.setState({ isPlayingRightPage: false }, () => {
                this.shouldLoadAudio(this.getPageKey());
            });
        }

        if (
            prevProps.leftPage &&
            this.props.leftPage &&
            prevProps.leftPage.pageReady !== this.props.leftPage.pageReady
        ) {
            this.shouldLoadAudio(this.getPageKey());
        }
        if (
            prevProps.rightPage &&
            this.props.rightPage &&
            prevProps.rightPage.pageReady !== this.props.rightPage.pageReady
        ) {
            this.shouldLoadAudio(this.getPageKey());
        }
        // if (prevProps.audioUrl !== this.props.audioUrl){
        //     this.shouldLoadAudio(this.getPageKey());
        // }
    }

    getAudioSource(pageNumber) {
        // if (this.props.book.IsEPub){
        //     return Promise.resolve(this.props.audioUrl);
        // }
        return this.props
            .getSpeechAudio(
                this.props.book,
                pageNumber,
                this.state.voice,
                this.props.AzureToken
            )
            .then((audioURL) => {
                return audioURL;
            })
            .catch((error) => {
                console.log(
                    'unable to get audio source, either there is no audio for this page, or something went wrong.',
                    error
                );
                return '';
            });
    }

    getPageKey() {
        let pageKey = '';
        const leftPageNumber = this.props.currentPage;
        const rightPageNumber = this.props.currentPage + 1;
        // set the pageKey
        if (this.state.isPlayingRightPage) {
            pageKey = `page${rightPageNumber}`;
        } else {
            pageKey = `page${leftPageNumber}`;
        }
        return pageKey;
    }

    /*
     * check if this page supports audio, if not then check the right page.  If both pages do not have audio, then show a message
     */
    startAudio() {
        // if (this.props.book.IsEPub){
        //     return;
        // }
        let startTime =
            parseInt(this.props.audioTime * 1000, 10) +
                constants.audioHighlightLeadTime ||
            constants.audioHighlightLeadTime;
        const pageKey = this.getPageKey();

        let speechMarks;
        if (this.state.isPlayingRightPage) {
            speechMarks = this.props.rightPage.speechMarks;
        } else {
            speechMarks = this.props.leftPage.speechMarks;
        }
        // get the time closest to the startTime in the speechMarksData
        let mark = speechMarks.reduce((prev, curr) => {
            if (!prev) {
                prev = { time: 0 };
            }
            return Math.abs(curr.time - startTime) <
                Math.abs(prev.time - startTime)
                ? curr
                : prev;
        });
        this.updateAudioHighlights(mark, pageKey);
    }

    /*
     * Stop the audio, reset the highlightedSpeechMarks, stop the audio playback, set the index to 0, reset
     * back to the left page audio if there is any, cancel all timeouts, remove all highlights
     */
    stopAudio() {
        this.highlightedSpeechMarks = [];
        this.props.stop(defaultOptions.id);
        this.speechMarkIndex = 0;
        this.setState({ isPlayingRightPage: false }, () => {
            this.shouldLoadAudio(this.getPageKey());
        });
        // loop over all timeouts and cancel them
        this.timeouts.forEach((timeout) => {
            clearTimeout(timeout);
        });
        // remove all highlights
        $(`[data-time-start]`).removeClass('audio-highlight-text');
    }

    /*
     * accepts the speechMark that is closest to the audioTime
     * highlight the word then call the unhighlight function
     */
    updateAudioHighlights(speechMark, pageKey) {
        if (!speechMark || !speechMark.time) {
            console.log('missing speechMark');
            return false;
        }
        let speechMarks;
        if (this.state.isPlayingRightPage) {
            speechMarks = this.props.rightPage.speechMarks;
        } else {
            speechMarks = this.props.leftPage.speechMarks;
        }

        if (this.props.audioPaused) {
            console.log('not playing audio');
            return;
        }

        // we really only need the time for the clossest word when the play button is pushed.  then it should take it from there expecting the exact time for the next word.
        let duration = 300; // how long this word will be highlighted
        let nextMark = {};

        if (speechMarks.length > this.speechMarkIndex + 1) {
            nextMark = speechMarks[this.speechMarkIndex + 1];
            duration = nextMark.time - speechMark.time;
            this.speechMarkIndex++;
        }

        // has this word been highlighted already?
        if (this.highlightedSpeechMarks.indexOf(speechMark.time) === -1) {
            //highlight it
            $(`#${pageKey} [data-time-start="${speechMark.time}"]`).addClass(
                'audio-highlight-text'
            );
            this.highlightedSpeechMarks.push(speechMark.time);
            // console.log('highlighted', speechMark.value, speechMark.time);

            // has the audio gotten too far out of sync?
            if (
                parseInt(this.props.audioTime * 1000, 10) - speechMark.time >
                    constants.audioResyncTime ||
                parseInt(this.props.audioTime * 1000, 10) - speechMark.time >
                    constants.audioResyncTime
            ) {
                console.log('audio out of sync');
                this.resyncSpeechMarks();
                return; // return so that we do not set an unhighlight timeout and potentially have two processes highlighting
            }

            // set a timeout to unhighlight this word and highlight the next word.
            this.unHightlightAudioText(
                speechMark.time,
                nextMark,
                duration,
                pageKey
            );
        } else {
            // no word was highlighted, so wait for the next timecode from the audio payer and continue highlighting words
            console.log('nothing highlighted, setting pending resync');
            this.timeouts.push(
                setTimeout(() => {
                    this.pendingResync = true;
                }, 200)
            );
        }
    }

    /*
     * remove the highlight from the text after it has been highlighted for the duration that word is spoken.
     * Then call the original function to highlight the next word.
     */
    unHightlightAudioText(markTime, nextMark, markDuration, pageKey) {
        this.timeouts.push(
            setTimeout(() => {
                // console.log('timeout', markTime, nextMark.time, markDuration);
                // $(`#${pageKey} [data-time-start="${markTime}"]`).removeClass('audio-highlight-text');
                if (nextMark.time !== 0 && !this.props.audioPaused) {
                    this.updateAudioHighlights(nextMark, pageKey);
                }
            }, markDuration)
        ); // minus 10 miliseconds to account for the time the function takes to run

        // sometimes the duration is too short for the user to see the highlight
        let highlightDuration = 0;
        if (markDuration < 100) {
            highlightDuration = 300;
        } else {
            highlightDuration = markDuration;
        }
        this.timeouts.push(
            setTimeout(() => {
                // console.log('timeout', markTime, nextMark.time, markDuration);
                $(`#${pageKey} [data-time-start="${markTime}"]`).removeClass(
                    'audio-highlight-text'
                );
            }, highlightDuration)
        );
    }

    /*
     * if audio is out of sync, then resync it.
     */
    resyncSpeechMarks() {
        // loop over all timeouts and cancel them
        this.timeouts.forEach((timeout) => {
            clearTimeout(timeout);
        });
        // remove all highlights
        $(`[data-time-start]`).removeClass('audio-highlight-text');
        this.pendingResync = true;
    }

    /*
     * Should we play the audio for the right page or stop and reset back to the left page
     */
    handleEndOfAudio() {
        this.stopAudio();
        // if (this.props.book.IsEPub){
        //     return;
        // }
        if (
            !this.state.isPlayingRightPage &&
            this.props.pagesVisible === 2 &&
            !this.props.blmMode &&
            this.props.rightPage.speechMarks &&
            this.props.rightPage.speechMarks.length > 0
        ) {
            setTimeout(() => {
                this.setState({ isPlayingRightPage: true }, () => {
                    this.getAudioSource(this.props.currentPage + 1).then(
                        (source) => {
                            this.props.setMedia('AudioPlayer', {
                                sources: { mp3: source }
                            });
                            this.props.play('AudioPlayer');
                        }
                    );
                });
            }, constants.audioDelayBetweenPages);

            // if this was the end of the right page, then reset back to the left page
        } else if (this.state.isPlayingRightPage) {
            console.log('end of right page, reset');
            this.setState({ isPlayingRightPage: false }, () => {
                this.getAudioSource(this.props.currentPage).then((source) => {
                    this.props.setMedia('AudioPlayer', {
                        sources: { mp3: source }
                    });
                });
            });
        }
    }

    /*
     * Should we load audio for the left and right page?
     * hide the speech buttons if there is no audio for the left or the right page
     *
     */
    shouldLoadAudio(pageKey) {
        let shouldLoad = false;

     
        // if they are a student, check if audio is enabled, otherwise enable audio if it is available
        if (
            (UserAPI.isStudent(this.props.user.RoleID) &&
                this.props.book.IsAudioAvailable &&
                this.props.book.IsAudioEnabled) ||
            !UserAPI.isStudent(this.props.user.RoleID)
        ) {
            // if (this.props.book.IsEPub){
            //     if (this.props.audioUrl && this.props.audioUrl.length){
            //         shouldLoad = true;
            //         this.getAudioSource(this.props.currentPage).then((source) => {
            //             this.props.setMedia('AudioPlayer', {
            //                 sources: { m4a: source }
            //             });
            //         });
            //     }
            // } else {
            // check for marks on the pages
            if (
                this.props.leftPage &&
                this.props.leftPage.speechMarks &&
                this.props.leftPage.speechMarks.length > 0
            ) {
                // we have speechMarks for the left page
                shouldLoad = true;
                this.getAudioSource(this.props.currentPage).then((source) => {
                    this.props.setMedia('AudioPlayer', {
                        sources: { mp3: source }
                    });
                });
            } else if (
                !this.state.isPlayingRightPage &&
                this.props.pagesVisible === 2 &&
                !this.props.blmMode &&
                this.props.rightPage &&
                this.props.rightPage.speechMarks &&
                this.props.rightPage.speechMarks.length > 0
            ) {
                // we have speechMarks for the right page
                shouldLoad = true;
                this.setState({ isPlayingRightPage: true }, () => {
                    this.getAudioSource(this.props.currentPage + 1).then(
                        (source) => {
                            this.props.setMedia('AudioPlayer', {
                                sources: { mp3: source }
                            });
                            // play the audio on the right page if audio is playing
                            if (!this.props.audioPaused) {
                                this.startAudio();
                            }
                        }
                    );
                });
            } else {
                // no speechMarks
                shouldLoad = false;
            }
        // }
        } else {
            // audio is not enabled
            shouldLoad = false;
        }

        if (shouldLoad === false) {
            this.setState({ showPlayer: false });
        } else {
            this.setState({ showPlayer: true });
        }
        return shouldLoad;
    }

    render() {
        if (!this.state.showPlayer) {
            return null;
        }

        let fontAwesomeTogglePlay = 'play';
        if (!this.props.audioPaused) {
            fontAwesomeTogglePlay = 'pause';
        }
        return (
            <JPlayer id={defaultOptions.id} className="jp-sleek">
                <Audio onPlay={this.startAudio} />
                <Gui>
                    <div className="jp-controls jp-icon-controls">
                        <StyledButton
                            className="jp-play"
                            css={this.theme.primaryButtonStyle}
                            onClick={this.props.play}
                        >
                            <FontAwesome name={fontAwesomeTogglePlay} />
                        </StyledButton>
                        <StyledButton
                            className="jp-stop"
                            css={this.theme.primaryButtonStyle}
                            onClick={this.stopAudio}
                        >
                            <FontAwesome name="stop" />
                        </StyledButton>
                    </div>
                    <BrowserUnsupported />
                </Gui>
            </JPlayer>
        );
    }
}

AudioPlayer.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    return {
        user: state.user,
        audioPaused: state.jPlayers.AudioPlayer.paused,
        audioTime: state.jPlayers.AudioPlayer.currentTime,
        duration: state.jPlayers.AudioPlayer.duration,
        currentPage: state.book.currentPage,
        pagesVisible: state.bookView.pagesVisible,
        speechMarks: state.book.speechMarks,
        book: state.book,
        AzureToken: state.user.AzureToken,
        leftPage: state.book.cachedPages[`page${state.book.currentPage}`],
        rightPage: state.book.cachedPages[`page${state.book.currentPage + 1}`],
        // audioUrl: state.bookView.audioUrl
    };
};

export default connect(mapStateToProps, {
    pause,
    stop,
    setMedia,
    play,
    getSpeechAudio
})(AudioPlayer);
